import { User } from 'oidc-client';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSettings } from '../../boot/Settings';
import { AuthenticationClient } from './authenticationClient';

export interface AuthenticationState {
	userInfo?: UserContext;
	contractId?: string;
}

export interface AuthenticationStateContext extends AuthenticationState {
	loginRequired: boolean;
	setLoginRequired: (loginRequired: boolean) => void;
}

const initialAuthState: AuthenticationStateContext = { loginRequired: false, setLoginRequired: () => {} };
const AuthenticationContext = React.createContext<AuthenticationStateContext>(initialAuthState);

export interface UserContext {
	firstName: string;
	lastName: string;
	userId: string;
	customerId: string;
	accessToken: string;
	expiresAt: number;
	impersonatingUser?: string;
}

function parseProfile(user: User): UserContext {
	const impersonatingUserId = user.profile['Kojamo:ImpersonatingUser:UserId'];
	const impersonatingUserFullName = user.profile['Kojamo:ImpersonatingUser:FullName'];

	return {
		customerId: user.profile.customer_id,
		firstName: user.profile.given_name!,
		lastName: user.profile.family_name!,
		userId: user.profile.sub,
		accessToken: user.access_token,
		//email: user.profile.email!, // This comes from sign-in token and doesn't update if user changes (sets) email. Use one from backend instead.
		expiresAt: user.expires_at,
		impersonatingUser: impersonatingUserId ? `${impersonatingUserFullName} (${impersonatingUserId})` : undefined,
	};
}

export function AuthenticationContextProvider(props: { children: React.ReactNode }) {
	const settings = useSettings();
	const [authState, setAuthState] = useState<Omit<AuthenticationStateContext, 'setLoginRequired'>>({
		loginRequired: initialAuthState.loginRequired,
	});
	const setLoginRequired = useCallback(
		(loginRequired: boolean) => setAuthState({ ...authState, loginRequired }),
		[authState, setAuthState],
	);

	const authClient = useMemo(
		() => (settings?.ssoAuthority ? new AuthenticationClient({ authority: settings.ssoAuthority }) : undefined),
		[settings?.ssoAuthority],
	);
	const contractIdFromQueryString = getContractIdFromQueryString();
	const contractId = useMemo(() => contractIdFromQueryString, [contractIdFromQueryString]);

	useEffect(() => {
		if (!authClient || !authState.loginRequired) {
			return;
		}

		async function login() {
			const result = await authClient!.refresh();
			setAuthState({
				...authState,
				userInfo: parseProfile(result),
				contractId,
			});
		}

		if (!authState.userInfo) {
			login();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authClient, contractId, authState.loginRequired]);

	const contextValue: AuthenticationStateContext = useMemo(
		() => ({ ...authState, setLoginRequired }),
		[authState, setLoginRequired],
	);

	return <AuthenticationContext.Provider value={contextValue}>{props.children}</AuthenticationContext.Provider>;
}

export function useAuthenticationContext(anonymous?: boolean) {
	const context = useContext(AuthenticationContext);
	const { setLoginRequired, ...authState } = context;

	useEffect(() => {
		if (!anonymous && !authState.loginRequired) {
			setLoginRequired(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anonymous, authState.loginRequired]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => authState, [context]);
}

function getContractIdFromQueryString() {
	const searchParams = new URL(window.location.href).searchParams;

	/* tslint:disable-next-line:no-string-literal */
	const contractId = searchParams.get('contractId');

	return Array.isArray(contractId) ? contractId[0] : contractId;
}
