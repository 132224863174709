import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { TextFormField } from '../../Components/forms/TextFormField';
import { FormProvider } from 'react-hook-form';
import { FormStep } from '../../Components/forms/FormStep';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { Route, Routes, useNavigate } from 'react-router';
import { FileInputField } from '../../Components/forms/FileInputField';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import { SpacingContainer } from '@kojamo/lumo-dls';
import { useCommonFacilitiesForm } from './useCommonFacilitiesForm';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';
import { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { CommonFacilitiesApiClient, CommonFacilitiesDetailsAM } from '../../api/commonFacilitiesApiClient';
import { SelectButton } from '../../Components/SelectButton';
import { SubmitStatus } from '../../Components/submitStatus';
import { FaultAreaType, getFaultReportUrl } from '../../common/faultReportForm';
import { requestShellNavigateTo } from '../../boot/shellIntegration';
import { IconLinkExternal } from '../../Components/svg/IconLinkExternal';
import { useResource } from '@kojamo/react-utils';
import { GenericServiceRequestConfirmation } from '../../Components/confirmationPage/GenericServiceRequestConfirmation';
import { CoTenantPrivacyNoticeServiceRequest } from '../../Components/CoTenantPrivacyNotices';
import { useAppLanguage } from '../../common/localization/useAppLanguage';
import { ContactFormTelemetryType } from '../../common/telemetryGA4';

export function CommonFacilitiesContact() {
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_Contact_CommonFacilities" />
			</ContactBreadCrumbs>
			<Routes>
				<Route path="/" element={<Content />} />
				<Route
					path="complete"
					element={
						<GenericServiceRequestConfirmation
							isAuthenticated={true}
							headingResourceKey="ContactForms_CommonFacilities_Confirmation_Heading"
							stepTitleResourceKey="ContactForms_CommonFacilities_Confirmation_Step_Heading"
							stepContentResourceKey="ContactForms_CommonFacilities_Confirmation_Step_Content"
						/>
					}
				/>
			</Routes>
		</>
	);
}

function Content() {
	const details = useCommonFacilitiesDetails();
	const { contractId } = useAuthenticationContext();
	if (!details || !contractId) {
		return <LoadingSpinner />;
	}

	return <Wizard details={details} contractId={contractId} />;
}

function Wizard({ details, contractId }: { details: CommonFacilitiesDetailsAM; contractId: string }) {
	const navigate = useNavigate();
	const { formMethods, onSubmit, submitStatus } = useCommonFacilitiesForm();
	const [step2Open, setStep2Open] = useState(!details.sharedFacilityReservationsInUse);
	const { language } = useAppLanguage();
	if (submitStatus === 'success') {
		navigate('complete');
	}

	return (
		<RequireValidContract>
			<ContactFormSection
				headingResourceKey={'ContactForms_CommonFacilities_Heading'}
				ingressTextNode={
					<ResourceText
						resourceKey="ContactForms_CommonFacilities_Ingress"
						className="l-font-body is-xl l-color-blue l-margin-5-b"
						parameters={[getFaultReportUrl(contractId, FaultAreaType.CommonAndOutdoorArea, language)]}
						linkOpenMode="self"
					/>
				}
				showContactInformation
			>
				<FormProvider {...formMethods}>
					<form onSubmit={onSubmit}>
						{details.sharedFacilityReservationsInUse ? (
							<Step1
								isOpen={!step2Open}
								onOpenRequested={() => setStep2Open(false)}
								onContinue={() => setStep2Open(true)}
							/>
						) : null}
						<Step2
							isOpen={step2Open}
							number={details.sharedFacilityReservationsInUse ? 2 : 1}
							submitStatus={submitStatus}
						/>
					</form>
				</FormProvider>
			</ContactFormSection>
		</RequireValidContract>
	);
}

function Step1({
	isOpen,
	onContinue,
	onOpenRequested,
}: {
	isOpen: boolean;
	onContinue: () => void;
	onOpenRequested: () => void;
}) {
	return (
		<FormStep
			number={1}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_OtherTopics_Step1_Heading" />
				</>
			}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<ResourceText
					resourceKey={'ContactForms_CommonFacilities_Step1_Ingress'}
					optional={true}
					className="l-font-body"
				/>
				<div className="button-choices">
					<SelectButton onClick={() => requestShellNavigateTo('OurHouse', 'facility-reservations')}>
						<ResourceTextInline resourceKey="ContactForms_CommonFacilities_Step1_ToFacilityReservation" />{' '}
						<IconLinkExternal />
					</SelectButton>
					<SelectButton onClick={() => onContinue()}>
						<ResourceTextInline resourceKey="ContactForms_CommonFacilities_Step1_SendQuestion" />
					</SelectButton>
				</div>
			</SpacingContainer>
		</FormStep>
	);
}

function Step2({ isOpen, number, submitStatus }: { number: number; isOpen: boolean; submitStatus: SubmitStatus }) {
	const { getResourceText } = useResource();
	return (
		<FormStep
			number={number}
			heading={<ResourceTextInline resourceKey={'ContactForms_CommonFacilities_Step2_Heading'} />}
			isOpen={isOpen}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<ResourceText
					resourceKey={'ContactForms_CommonFacilities_Step2_Ingress'}
					optional={true}
					className="l-font-body"
				/>
				<TextFormField
					name="subject"
					labelResourceKey="ContactForms_Subject_Label"
					placeholderResourceKey="ContactForms_Subject_Placeholder"
					required={getResourceText('ContactForms_RequiredField_Error')}
					maxLength={100}
				/>
				<TextAreaField
					name="message"
					labelResourceKey="ContactForms_Message_Label"
					placeholderResourceKey="ContactForms_Message_Placeholder"
					footnoteResourceKey="ContactForms_CommonFacilities_Message_Footnote"
					required={getResourceText('ContactForms_RequiredField_Error')}
					maxLength={2000}
				/>
				<FileInputField
					name="files"
					labelResourceKey="ContactForms_Attachment_Label"
					footNoteResourceKey="ContactForms_Attachment_Footnote"
					fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
				/>
				<CoTenantPrivacyNoticeServiceRequest />
			</SpacingContainer>
			<SubmitButtonAndProgress
				submitStatus={submitStatus}
				formTypeForTelemetry={ContactFormTelemetryType.CommonFacilities}
			/>
		</FormStep>
	);
}

function useCommonFacilitiesDetails() {
	const authContext = useAuthenticationContext();
	const [details, setDetails] = useState<CommonFacilitiesDetailsAM>();

	useEffect(() => {
		async function fetchData() {
			if (!authContext.contractId) {
				return;
			}
			const apiClient = new CommonFacilitiesApiClient(authContext);
			const res = await apiClient.getDetails();
			setDetails(res);
		}
		fetchData();
	}, [authContext]);

	return details;
}
