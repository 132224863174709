export const appRoutes = {
	otherTopics: 'other-topics',
	faultReport: 'fault-report',
	disturbance: 'disturbed',
	keyContact: 'keys',
	movingContact: 'moving',
	rentAndContract: 'rent-and-contract',
	sauna: 'sauna-contact',
	parking: 'parking',
	commonFacilities: 'common-facilities',
	benefits: 'benefits',
	myServiceRequests: 'my-service-requests',
};

export const appRoutesAnonymous = {
	faultReport: 'fault-report-anonymous',
};
