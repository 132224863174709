export const langEn = 'en';

export const localizedLinks = {
	sharedFacilityReservations: { fi: '/tilavaraukset', en: `/${langEn}/facility-reservations` },
	sauna: { fi: '/sauna', en: `/${langEn}/sauna` },
	services: { fi: '/palvelut', en: `/${langEn}/services` },
	ownInformation: { fi: '/omat-tiedot', en: `/${langEn}/personal-information` },
	ownServiceRequests: { fi: '/yhteydenotto/omat', en: `/${langEn}/contact/my-service-requests` },
	contact: { fi: '/yhteydenotto', en: `/${langEn}/contact` },
	parking: { fi: '/autopaikat', en: `/${langEn}/parkingspaces` },
	faultReport: { fi: '/vikailmoitus', en: `/${langEn}/fault-report` },
	maintenanceInformation: {
		fi: '/yhteystiedot/loyda-huoltopaivystys',
		en: `/${langEn}/contact-information/maintenance`,
	},
};
