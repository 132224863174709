import { ApiClientBase } from './apiClientBase';
import { PetOptions } from './serviceRequestApiClient';

export type OtherContactTopic = 'Feedback' | 'Rental' | 'Other';
export type KeyContactTopic = 'KeyPickupOrReturn' | 'NewOrLostKeyOrLockRenewal';
type ContactMessageTopic = OtherContactTopic | KeyContactTopic | 'Generic' | 'ParkingSpaces' | 'Other' | 'Reservation';

interface ContactMessage<TTopic extends ContactMessageTopic> {
	topic: TTopic;
	subject: string;
	message: string;
	files: File[] | undefined;
}

interface MasterKeyAndPetState {
	masterKeyAccessAllowed?: boolean;
	petState?: PetOptions;
}

export type OtherContactAIM = ContactMessage<OtherContactTopic>;

export type ParkingContactAIM = ContactMessage<'ParkingSpaces'>;

export type RentAndContractContactAIM = ContactMessage<'Generic'>;

export type KeyContactAIM = ContactMessage<KeyContactTopic> & MasterKeyAndPetState; // Master key and petState props are required for KeyContactTopic NewOrLostKeyOrLockRenewal

export type MovingContactAIM = ContactMessage<'Other'> & Required<MasterKeyAndPetState>;

export type SaunaContactAIM = ContactMessage<'Generic'>;

export type CommonFacilitiesContactAIM = ContactMessage<'Reservation'>;

export type BenefitsContactAIM = ContactMessage<'Generic'>;

export class ContactApiClient extends ApiClientBase {
	sendOtherContact(model: OtherContactAIM) {
		const form = this.prepareFormContent(model);
		return this.postForm(`/api/contact/other`, form);
	}

	sendParkingContact(model: ParkingContactAIM) {
		const form = this.prepareFormContent(model);
		return this.postForm(`/api/contact/parking`, form);
	}

	sendKeyContact(model: KeyContactAIM) {
		const form = this.prepareFormContent(model);
		return this.postForm(`/api/contact/keys`, form);
	}

	sendMovingContact(model: MovingContactAIM) {
		const form = this.prepareFormContent(model);
		return this.postForm(`/api/contact/moving`, form);
	}

	sendRentAndContractContact(req: RentAndContractContactAIM) {
		const form = this.prepareFormContent(req);
		return this.postForm(`/api/contact/rent-and-contract`, form);
	}

	sendSaunaContact(req: SaunaContactAIM) {
		const form = this.prepareFormContent(req);
		return this.postForm(`/api/contact/sauna`, form);
	}

	sendBenefitsContact(req: BenefitsContactAIM) {
		const form = this.prepareFormContent(req);
		return this.postForm(`/api/contact/benefits`, form);
	}

	sendCommonFacilitiesContact(req: CommonFacilitiesContactAIM) {
		const form = this.prepareFormContent(req);
		return this.postForm(`/api/contact/common-facilities`, form);
	}

	private prepareFormContent(model: ContactMessage<ContactMessageTopic> & MasterKeyAndPetState) {
		const form = new FormData();
		form.append('topic', model.topic);
		form.append('subject', model.subject);
		form.append('message', model.message);
		if ('petState' in model) {
			form.append('petState', model.petState || '');
		}
		if ('masterKeyAccessAllowed' in model) {
			form.append('masterKeyAccessAllowed', String(model.masterKeyAccessAllowed || false));
		}

		if (model.files) {
			for (const file of model.files) {
				form.append('files', file);
			}
		}
		return form;
	}
}
