import { useForm } from 'react-hook-form';
import { KeyContactFormData } from './KeyContactFormData';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { useAntiForgeryTokenContext } from '../../boot/AntiForgery';
import { useState } from 'react';
import { SubmitStatus } from '../../Components/submitStatus';
import { ContactApiClient, KeyContactAIM, KeyContactTopic } from '../../api/contactApiClient';

export function useKeyContactForm() {
	const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('initialized');
	const formMethods = useForm<KeyContactFormData>();
	const authState = useAuthenticationContext();
	const { token: antiForgeryToken } = useAntiForgeryTokenContext();
	const [selectedTopic, setSelectedTopic] = useState<KeyContactTopic>();

	const onSubmit = formMethods.handleSubmit(async (data: KeyContactFormData) => {
		setSubmitStatus('submitting');
		const apiClient = new ContactApiClient(authState, antiForgeryToken);

		const { pets, masterKey, ...rest } = data;
		const req: KeyContactAIM = {
			...rest,
			petState: pets,
			masterKeyAccessAllowed: masterKey,
		};

		apiClient
			.sendKeyContact(req)
			.then(() => {
				setSubmitStatus('success');
				setSelectedTopic(req.topic);
			})
			.catch(() => setSubmitStatus('error'));
	});

	return { formMethods, onSubmit, submitStatus, selectedTopic };
}
