import { useEffect, useState } from 'react';
import { ServiceRequestWithBusinessAreaAM, useServiceRequestApi } from '../api/serviceRequestApiClient';

export function useServiceRequest(id?: string) {
	const apiClient = useServiceRequestApi();
	const [serviceRequest, setServiceRequest] = useState<ServiceRequestWithBusinessAreaAM | undefined>();
	const [loading, setLoading] = useState(false);
	const [reloadTrigger, setReloadTrigger] = useState(0);

	const allowedFileTypesForCommentAttachments = [
		'application/pdf',
		'image/jpg',
		'image/jpeg',
		'image/gif',
		'image/png',
	];

	const fileTypeAllowsPreview = (fileType: string) =>
		['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(fileType);

	const reload = () => {
		setReloadTrigger(reloadTrigger + 1);
	};

	useEffect(() => {
		async function fetchData() {
			if (apiClient && id) {
				const result = await apiClient.get(id);
				setServiceRequest(result);
				setLoading(false);
			}
		}

		fetchData();
	}, [apiClient, id, reloadTrigger]);

	return {
		serviceRequest,
		loading,
		reload,
		allowedFileTypesForCommentAttachments,
		fileTypeAllowsPreview,
	};
}
