import { useMemo } from 'react';
import en from 'date-fns/locale/en-US';
import fi from 'date-fns/locale/fi';
import { Language } from './types';
import { useSelector } from 'react-redux';
import { UILanguage } from '../../Reducer/userReducer';
import { AppRootState } from '../../Store/appRootState';

export function useAppLanguage(): { language: Language; dateFnsOptions: { locale: typeof en | typeof fi } } {
	const language = useSelector<AppRootState, UILanguage>(({ user: { uiLanguage } }) => uiLanguage);

	const dateFnsOptions = useMemo(
		() => ({
			locale: language === 'en' ? en : fi,
		}),
		[language],
	);

	return { language, dateFnsOptions };
}
