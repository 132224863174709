import React from 'react';
import { TextBadge } from '../Icons';

export function FormStep(props: {
	children: React.ReactNode;
	heading: React.ReactNode;
	isOpen: boolean;
	number: number;
	onOpenRequested?: () => void;
}) {
	return (
		<div className={'ml-form-step' + (props.isOpen ? ' is-open' : ' is-closed')}>
			<div
				className="step-header"
				onClick={() => !props.isOpen && props.onOpenRequested && props.onOpenRequested()}
			>
				<TextBadge text={props.number} variant={props.isOpen ? 'lumo-blue-dark' : 'lumo-pink'} />
				<h3 className="l-headline is-s">{props.heading}</h3>

				<div className="icon-expand-collapse">
					<IconExpandCollapse />
				</div>
			</div>
			<div className="step-content">{props.children}</div>
		</div>
	);
}

function IconExpandCollapse() {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M-8.74228e-07 20C-3.91405e-07 8.9543 8.95431 -8.74228e-07 20 -8.74228e-07C25.3043 -8.74228e-07 30.3914 2.10714 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 -1.35705e-06 31.0457 -8.74228e-07 20ZM38.5711 20C38.5711 9.74325 30.2564 1.42853 19.9997 1.42853C9.743 1.42853 1.42828 9.74324 1.42828 20C1.42828 30.2567 9.74299 38.5714 19.9997 38.5714C30.2564 38.5714 38.5711 30.2567 38.5711 20Z"
				fill="#CF3F6C"
			/>
			<path
				d="M20.0001 27.6858C19.6679 27.684 19.3645 27.4965 19.2144 27.2L13.5001 16.4858C13.2713 16.0518 13.4376 15.5146 13.8716 15.2858C14.3055 15.0569 14.8428 15.2232 15.0716 15.6572L20.0001 24.8858L24.9287 15.7143C25.1575 15.2804 25.6948 15.1141 26.1287 15.3429C26.5627 15.5717 26.7289 16.109 26.5001 16.5429L20.7859 27.2572C20.6207 27.5316 20.3203 27.6955 20.0001 27.6858Z"
				fill="#CF3F6C"
			/>
		</svg>
	);
}
