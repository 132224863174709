import { FormGroup, FormGroupProps } from './FormGroup';
import { useFormContext } from 'react-hook-form';
import { ResourceTextInline } from '../../common/localization/ResourceText';
import { Option } from './Option';
import { useInputId } from './useInputId';

export function RadioGroup({
	options,
	...formGroupProps
}: Pick<FormGroupProps, 'name' | 'labelResourceKey' | 'required'> & {
	options: Option[];
}) {
	return (
		<FormGroup {...formGroupProps}>
			{options.map((option, index) => (
				<RadioButton
					key={index}
					name={formGroupProps.name}
					option={option}
					required={formGroupProps.required}
				/>
			))}
		</FormGroup>
	);
}

function RadioButton({ name, required, option }: Pick<FormGroupProps, 'name' | 'required'> & { option: Option }) {
	const { register } = useFormContext();
	const id = useInputId();
	return (
		<div className="l-display-flex l-align-items-center l-margin-4-b">
			<div className="l-radiobutton">
				<input id={id} type="radio" {...register(name, { required })} value={option.value} />
				<label htmlFor={id} />
			</div>
			<label className="l-margin-3-l l-font-body" htmlFor={id}>
				<ResourceTextInline resourceKey={option.labelResourceKey} />
			</label>
		</div>
	);
}
