import React from 'react';
import { useForm } from 'react-hook-form';
import { BenefitsContactAIM, ContactApiClient } from '../../api/contactApiClient';
import { useAntiForgeryTokenContext } from '../../boot/AntiForgery';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { SubmitStatus } from '../../Components/submitStatus';

export function useBenefitsForm() {
	const [submitStatus, setSubmitStatus] = React.useState<SubmitStatus>('initialized');
	const formMethods = useForm<BenefitContactFormData>();
	const authState = useAuthenticationContext();
	const { token: antiForgeryToken } = useAntiForgeryTokenContext();

	const onSubmit = formMethods.handleSubmit(async (data: BenefitContactFormData) => {
		setSubmitStatus('submitting');
		const apiClient = new ContactApiClient(authState, antiForgeryToken);

		const req: BenefitsContactAIM = { ...data, topic: 'Generic' };

		apiClient
			.sendBenefitsContact(req)
			.then(() => {
				setSubmitStatus('success');
			})
			.catch(() => setSubmitStatus('error'));
	});

	return { formMethods, onSubmit, submitStatus };
}

export interface BenefitContactFormData {
	subject: string;
	message: string;
	files: File[] | undefined;
}
