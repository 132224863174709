import { useSettings } from '../../boot/Settings';
import { useResource } from '@kojamo/react-utils';

type ResourceImageProps = ({ resourceKey: string } | { resourceId: string }) & {
	className?: string;
	preset?: string;
	alt?: string;
};

export function ResourceImage(props: ResourceImageProps) {
	const settings = useSettings();
	const { getResourceImageUrlById, getResourceImageUrlByKey } = useResource();

	if (!settings?.cdnRootUrl) {
		return null;
	}

	const pathWithoutPreset =
		'resourceId' in props
			? getResourceImageUrlById(settings.cdnRootUrl, props.resourceId)
			: getResourceImageUrlByKey(settings.cdnRootUrl, props.resourceKey);
	const defaultPath = props.preset ? pathWithoutPreset + '/' + props.preset : pathWithoutPreset;

	return <img className={props.className} src={defaultPath} alt={props.alt} />;
}
