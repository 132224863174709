import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { FormStep } from '../../Components/forms/FormStep';
import { FormProvider, useForm, useFormContext, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form';
import { DisturbanceReportForm } from './disturbanceReportForm';
import { TextFormField } from '../../Components/forms/TextFormField';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { FormGroup } from '../../Components/forms/FormGroup';
import './DisturbanceReport.scss';
import { Button, IconAdd, IconClose, IconCopy, SpacingContainer } from '@kojamo/lumo-dls';
import { useCallback, useState } from 'react';
import format from 'date-fns/format';
import { DisturbanceAIM, DisturbanceReportAIM, DisturbanceReportApiClient } from '../../api/disturbanceReportApiClient';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { useAntiForgeryTokenContext } from '../../boot/AntiForgery';
import { VisibilityContainer } from '../../Components/VisibilityContainer';
import { useResource } from '@kojamo/react-utils';
import { SuccessMessageBox } from '../../Components/confirmationPage/SuccessMessageBox';
import { OwnReportsLink } from '../../Components/confirmationPage/OwnReportsLink';
import { useScrollToTop } from '../../common/useScrollToTop';
import { SubmitStatus } from '../../Components/submitStatus';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';
import { CoTenantPrivacyNoticeServiceRequest } from '../../Components/CoTenantPrivacyNotices';

import { ContactFormTelemetryType } from '../../common/telemetryGA4';

export function DisturbanceReport() {
	const { formMethods, onSubmit, submitStatus } = useDisturbanceReportForm();
	return (
		<div className="DisturbanceReport">
			{submitStatus === 'success' ? (
				<Success />
			) : (
				<Wizard formMethods={formMethods} onSubmit={onSubmit} submitStatus={submitStatus} />
			)}
		</div>
	);
}

function Wizard({
	formMethods,
	onSubmit,
	submitStatus,
}: {
	formMethods: UseFormReturn<DisturbanceReportForm>;
	onSubmit: ReturnType<UseFormHandleSubmit<DisturbanceReportForm>>;
	submitStatus: SubmitStatus;
}) {
	return (
		<RequireValidContract requiredStartedContract>
			<ContactFormSection
				headingResourceKey={'ContactForms_DisturbanceReport_Heading'}
				ingressTextKey="ContactForms_DisturbanceReport_Ingress"
				showContactInformation
			>
				<FormProvider {...formMethods}>
					<form onSubmit={onSubmit}>
						<FormStep
							isOpen={true}
							heading={
								<ResourceTextInline resourceKey="ContactForms_DisturbanceReport_StepDisturbance_Heading" />
							}
							number={1}
						>
							<ResidentDetails />
							<DisturbanceDetails />
							<DisturbanceOccurrences />
							<ResourceText
								resourceKey="ContactForms_RequiredFields_Instruction"
								className="l-margin-4-b l-font-footnote"
							/>
							<CoTenantPrivacyNoticeServiceRequest />
							<SubmitButtonAndProgress
								submitStatus={submitStatus}
								formTypeForTelemetry={ContactFormTelemetryType.DisturbanceReport}
							/>
						</FormStep>
					</form>
				</FormProvider>
			</ContactFormSection>
		</RequireValidContract>
	);
}

function useDisturbanceReportForm() {
	const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('initialized');
	const formMethods = useForm<DisturbanceReportForm>({ defaultValues: { occurrences: [] } });
	const authState = useAuthenticationContext();
	const { token: antiForgeryToken } = useAntiForgeryTokenContext();

	const onSubmit = formMethods.handleSubmit(async (data: DisturbanceReportForm) => {
		setSubmitStatus('submitting');
		const apiClient = new DisturbanceReportApiClient(authState, antiForgeryToken);
		apiClient
			.submit(data as DisturbanceReportAIM)
			.then(() => setSubmitStatus('success'))
			.catch(() => setSubmitStatus('error'));
	});

	return { formMethods, onSubmit, submitStatus };
}

function ResidentDetails() {
	const { getResourceText } = useResource();
	return (
		<div className="l-margin-6-b">
			<h6 className="l-font-body is-m is-contrast l-color-blue l-margin-5-b">
				<ResourceText resourceKey="ContactForms_DisturbanceReport_StepDisturbance_ResidentDetails_Title" />
			</h6>
			<SpacingContainer direction="row" columnGap={5} rowGap={6} wrap>
				<TextFormField
					name="disturbingResidentName"
					labelResourceKey="ContactForms_DisturbanceReport_StepDisturbance_ResidentName_Label"
					className="flex-half-width"
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>
				<TextFormField
					name="disturbingResidentApartmentNumber"
					labelResourceKey="ContactForms_DisturbanceReport_StepDisturbance_ApartmentNumber_Label"
					className="flex-half-width"
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>
			</SpacingContainer>
		</div>
	);
}

function DisturbanceDetails() {
	return (
		<div className="l-margin-6-b">
			<h6 className="l-font-body is-m is-contrast l-color-blue l-margin-5-b">
				<ResourceText resourceKey="ContactForms_DisturbanceReport_StepDisturbance_DisturbanceDetails_Title" />
			</h6>
			<p className="l-font-body l-margin-6-b">
				<ResourceText resourceKey="ContactForms_DisturbanceReport_StepDisturbance_DisturbanceDetails_Description" />
			</p>
		</div>
	);
}

function DisturbanceOccurrences() {
	const { watch, setValue } = useFormContext<DisturbanceReportForm>();
	const occurrences = watch('occurrences');
	const addOccurrence = useCallback(
		() => setValue('occurrences', [...occurrences, getNewOccurrence()]),
		[occurrences, setValue],
	);
	const copyOccurrence = useCallback(
		(copyIndex) => {
			const toCopy = occurrences.find((_, index) => index === copyIndex);
			if (toCopy) {
				setValue('occurrences', [...occurrences, { description: toCopy.description }]);
			}
		},
		[occurrences, setValue],
	);
	const removeOccurrence = useCallback(
		(removeIndex) =>
			setValue(
				'occurrences',
				occurrences.filter((_, index) => index !== removeIndex),
			),
		[occurrences, setValue],
	);
	return (
		<div className="l-margin-6-b">
			<SpacingContainer direction="column" rowGap={6} className="l-margin-6-b">
				<DisturbanceOccurrence
					index={0}
					onCopy={() => copyOccurrence(0)}
					onRemove={1 < occurrences.length ? () => removeOccurrence(0) : undefined}
				/>
				{occurrences
					.map((_occurrence, index) => (
						<DisturbanceOccurrence
							key={index}
							index={index}
							onCopy={() => copyOccurrence(index)}
							onRemove={() => removeOccurrence(index)}
						/>
					))
					.filter((_occurrence, index) => 0 < index)}
			</SpacingContainer>
			<SpacingContainer direction="row" justify="center" className="disturbance-occurrence__add-button-container">
				<Button variant="text" type="button" onClick={addOccurrence}>
					<IconAdd />
					<ResourceText
						textType="Plain"
						resourceKey="ContactForms_DisturbanceReport_StepDisturbance_AddDisturbanceTime"
					/>
				</Button>
			</SpacingContainer>
		</div>
	);
}

function getNewOccurrence(): DisturbanceAIM {
	return { date: formatDate(new Date()), description: '', time: '' };
}

function DisturbanceOccurrence({
	index,
	onCopy,
	onRemove,
}: {
	index: number;
	onCopy: () => void;
	onRemove?: () => void;
}) {
	const { getResourceText } = useResource();
	const { register, watch } = useFormContext<DisturbanceReportForm>();

	return (
		<SpacingContainer
			className="disturbance-occurrence l-padding-5 l-padding-3-mobile"
			direction="column"
			rowGap={5}
			rowGapMobile={3}
		>
			<SpacingContainer direction="row" justify="edges">
				<h6 className="l-font-body is-m is-contrast l-color-blue">
					<ResourceText
						textType="Plain"
						resourceKey="ContactForms_DisturbanceReport_StepDisturbance_OccurrenceTitle"
					/>
					{1 <= index && <span> - {index + 1}</span>}
				</h6>
				<SpacingContainer direction="row" columnGap={5}>
					<VisibilityContainer showOnlyIn="desktop">
						<Button variant="text" isSmall type="button" onClick={onCopy}>
							<ResourceText
								textType="Plain"
								resourceKey="ContactForms_DisturbanceReport_StepDisturbance_CopyButton"
							/>
							<IconCopy />
						</Button>
					</VisibilityContainer>
					{onRemove && (
						<Button variant="text" isSmall type="button" onClick={onRemove}>
							<ResourceText
								textType="Plain"
								resourceKey="ContactForms_DisturbanceReport_StepDisturbance_DeleteButton"
							/>
							<IconClose />
						</Button>
					)}
				</SpacingContainer>
			</SpacingContainer>
			<SpacingContainer direction="row" columnGap={5} rowGap={6} rowGapMobile={3} wrap>
				<FormGroup
					name={`occurrences.${index}.date`}
					labelResourceKey="ContactForms_DisturbanceReport_StepDisturbance_DisturbanceDate_Label"
					className="flex-half-width"
					required={getResourceText('ContactForms_RequiredField_Error')}
				>
					<input
						className="form-input-text"
						type="date"
						{...register(`occurrences.${index}.date`, {
							required: getResourceText('ContactForms_RequiredField_Error'),
						})}
						value={watch(`occurrences.${index}.date`)}
					/>
				</FormGroup>
				<TextFormField
					name={`occurrences.${index}.time`}
					labelResourceKey="ContactForms_DisturbanceReport_StepDisturbance_DisturbanceTime_Label"
					className="flex-half-width"
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>
			</SpacingContainer>
			<TextAreaField
				name={`occurrences.${index}.description`}
				labelResourceKey="ContactForms_DisturbanceReport_StepDisturbance_DisturbanceDescription_Label"
				required={getResourceText('ContactForms_RequiredField_Error')}
			/>
			<VisibilityContainer showOnlyIn="mobile">
				<SpacingContainer direction="row" justify="center">
					<Button variant="text" isSmall className="l-padding-0" type="button" onClick={onCopy}>
						<ResourceText
							textType="Plain"
							resourceKey="ContactForms_DisturbanceReport_StepDisturbance_CopyButton"
						/>
						<IconCopy />
					</Button>
				</SpacingContainer>
			</VisibilityContainer>
		</SpacingContainer>
	);
}

function formatDate(date: Date) {
	return format(date, 'yyyy-MM-dd');
}

function Success() {
	useScrollToTop(true);
	return (
		<ContactFormSection headingResourceKey={'ContactForms_DisturbanceReport_Success_Heading'}>
			<SuccessMessageBox titleResourceKey="ContactForms_DisturbanceReport_Success_MessageTitle">
				<p className="l-font-body is-s l-color-grey l-margin-0">
					<ResourceText resourceKey="ContactForms_DisturbanceReport_Success_MessageResponseTime" />
				</p>
				<p className="l-font-body l-margin-0">
					<ResourceText resourceKey="ContactForms_DisturbanceReport_Success_MessageBody" />
				</p>
				<OwnReportsLink />
			</SuccessMessageBox>
		</ContactFormSection>
	);
}
