import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { FormStep } from '../../Components/forms/FormStep';
import { ReactElement, useEffect, useState } from 'react';
import { SelectButton } from '../../Components/SelectButton';
import { IconLinkExternal } from '../../Components/svg/IconLinkExternal';
import { useSettings } from '../../boot/Settings';
import { TextFormField } from '../../Components/forms/TextFormField';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { FileInputField } from '../../Components/forms/FileInputField';
import { SpacingContainer } from '@kojamo/lumo-dls';
import { FormProvider } from 'react-hook-form';
import { useParkingForm } from './useParkingForm';
import { useResource } from '@kojamo/react-utils';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { SubmitStatus } from '../../Components/submitStatus';
import { useScrollToTop } from '../../common/useScrollToTop';
import { SuccessMessageBox } from '../../Components/confirmationPage/SuccessMessageBox';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';
import { useParkingSpacesAvailability } from '../../common/useParkingSpacesAvailability';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { CoTenantPrivacyNoticeCustomerService } from '../../Components/CoTenantPrivacyNotices';
import { localizedLinks } from '../../common/localization/localizedLinks';
import { useAppLanguage } from '../../common/localization/useAppLanguage';
import { ContactFormTelemetryType } from '../../common/telemetryGA4';

enum ParkingFormSections {
	Step1 = 'Step1',
	Step2 = 'Step2',
}

export function Parking() {
	const { formMethods, onSubmit, submitStatus } = useParkingForm();
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_Parking_Title" />
			</ContactBreadCrumbs>
			<RequireValidContract>
				<ParkingSpaceAvailabilityAndManagement>
					{submitStatus !== 'success' ? (
						<FormProvider {...formMethods}>
							<form onSubmit={onSubmit}>
								<ParkingWizard submitStatus={submitStatus} />
							</form>
						</FormProvider>
					) : (
						<Success />
					)}
				</ParkingSpaceAvailabilityAndManagement>
			</RequireValidContract>
		</>
	);
}

function ParkingSpaceAvailabilityAndManagement({ children }: { children: ReactElement }) {
	const { language } = useAppLanguage();
	const parkingSpacesAvailability = useParkingSpacesAvailability();
	const settings = useSettings();

	if (!parkingSpacesAvailability) {
		return <LoadingSpinner variant="fullPage" />;
	}

	const { hasInternalParking, hasExternalParking } = parkingSpacesAvailability;

	if (hasInternalParking) {
		return children;
	} else if (hasExternalParking) {
		return (
			<div className="l-font-body l-margin-6-t">
				{parkingSpacesAvailability.externalParkingSpaceManagementDescription}
			</div>
		);
	} else {
		return (
			<div className="l-font-body l-margin-6-t">
				<ResourceText
					resourceKey="ContactForms_Parking_NotAvailable"
					parameters={[`${settings?.shellBaseUrl}${localizedLinks.contact[language]}`]}
				/>
			</div>
		);
	}
}

function ParkingWizard({ submitStatus }: { submitStatus: SubmitStatus }) {
	const [isQuestion, setIsQuestion] = useState(false);
	const [openSection, setOpenSection] = useState(ParkingFormSections.Step1);
	useEffect(() => {
		if (openSection === ParkingFormSections.Step2) {
			setIsQuestion(true);
		}
	}, [openSection]);

	return (
		<ContactFormSection
			headingResourceKey="ContactForms_Parking_Heading"
			ingressTextKey="ContactForms_Parking_Ingress"
			showContactInformation
		>
			<Step1
				isOpen={openSection === ParkingFormSections.Step1}
				isQuestion={isQuestion}
				onQuestionClick={() => setOpenSection(ParkingFormSections.Step2)}
				onOpenRequested={() => setOpenSection(ParkingFormSections.Step1)}
			/>
			{isQuestion ? (
				<Step2
					isOpen={openSection === ParkingFormSections.Step2}
					onOpenRequested={() => setOpenSection(ParkingFormSections.Step2)}
					submitStatus={submitStatus}
				/>
			) : null}
		</ContactFormSection>
	);
}

function Step1({
	isOpen,
	isQuestion,
	onQuestionClick,
	onOpenRequested,
}: {
	isOpen: boolean;
	isQuestion: boolean;
	onQuestionClick: () => void;
	onOpenRequested: () => void;
}) {
	const settings = useSettings();
	const { language } = useAppLanguage();
	function navigateToParkingReservation() {
		window.open(`${settings?.shellBaseUrl}${localizedLinks.parking[language]}`, '_blank');
	}

	return (
		<FormStep
			number={1}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_Parking_Step1_Heading" />
					{!isOpen && isQuestion && (
						<>
							: <ResourceTextInline resourceKey="ContactForms_Parking_Step1_ButtonQuestion" />
						</>
					)}
				</>
			}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<div className="button-choices">
				<SelectButton
					className="l-display-flex l-align-items-center l-justify-center l-column-gap-1"
					onClick={navigateToParkingReservation}
				>
					<ResourceTextInline resourceKey="ContactForms_Parking_Step1_ButtonReserveParking" />
					<IconLinkExternal />
				</SelectButton>
				<SelectButton onClick={onQuestionClick}>
					<ResourceTextInline resourceKey="ContactForms_Parking_Step1_ButtonQuestion" />
				</SelectButton>
			</div>
		</FormStep>
	);
}

function Step2({
	isOpen,
	onOpenRequested,
	submitStatus,
}: {
	isOpen: boolean;
	onOpenRequested: () => void;
	submitStatus: SubmitStatus;
}) {
	const { getResourceText } = useResource();
	const settings = useSettings();
	const { language } = useAppLanguage();
	return (
		<FormStep
			number={2}
			heading={<ResourceTextInline resourceKey="ContactForms_Parking_Step2_Heading" />}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<ResourceText
					resourceKey={'ContactForms_Parking_Step2_Instructions'}
					parameters={[
						`${settings?.shellBaseUrl}${localizedLinks.parking[language]}`,
						`${settings?.shellBaseUrl}${localizedLinks.faultReport[language]}`,
					]}
					optional={true}
					className="l-font-body"
				/>
				<TextFormField
					name="subject"
					labelResourceKey="ContactForms_Subject_Label"
					placeholderResourceKey="ContactForms_Subject_Placeholder"
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>
				<TextAreaField
					name="message"
					labelResourceKey="ContactForms_Message_Label"
					placeholderResourceKey="ContactForms_Message_Placeholder"
					footnoteResourceKey="ContactForms_RentAndContract_Message_Footnote"
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>
				<FileInputField
					name="files"
					labelResourceKey="ContactForms_Attachment_Label"
					footNoteResourceKey="ContactForms_Attachment_Footnote"
					fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
				/>
				<CoTenantPrivacyNoticeCustomerService />
			</SpacingContainer>
			<SubmitButtonAndProgress
				submitStatus={submitStatus}
				formTypeForTelemetry={ContactFormTelemetryType.Parking}
			/>
		</FormStep>
	);
}

function Success() {
	useScrollToTop(true);
	return (
		<ContactFormSection headingResourceKey={'ContactForms_Parking_Success_PageTitle'}>
			<SuccessMessageBox titleResourceKey="ContactForms_Parking_Success_MessageTitle">
				<p className="l-font-body l-margin-0-t">
					<ResourceText resourceKey="ContactForms_Parking_Success_MessageBody" />
				</p>
			</SuccessMessageBox>
		</ContactFormSection>
	);
}
