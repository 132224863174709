import { getType } from 'typesafe-actions';
import { takeLatest, put } from 'redux-saga/effects';
import { appStateActions } from '../Actions/appStateActions';

function* watchAppInitSaga() {
	yield put(appStateActions.setLanguage.request());
}

export function* appInitSaga() {
	yield takeLatest(getType(appStateActions.init), watchAppInitSaga);
}
