import { useFormContext, useWatch } from 'react-hook-form';
import { useResource } from '@kojamo/react-utils';
import { FormGroup, FormGroupProps } from './FormGroup';
import { ResourceText } from '../../common/localization/ResourceText';

export function TextAreaField({
	placeholderResourceKey,
	footnoteResourceKey,
	maxLength,
	...formGroupProps
}: Pick<FormGroupProps, 'name' | 'labelResourceKey' | 'required' | 'className'> & {
	placeholderResourceKey?: string;
	footnoteResourceKey?: string;
	maxLength?: number;
}) {
	const { getResourceText } = useResource();
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const value = useWatch({ name: formGroupProps.name });
	const error = errors[formGroupProps.name];
	return (
		<FormGroup {...formGroupProps}>
			<textarea
				className="form-input-textarea"
				{...register(formGroupProps.name, { maxLength, required: formGroupProps.required })}
				placeholder={placeholderResourceKey ? getResourceText(placeholderResourceKey) : undefined}
			/>
			{footnoteResourceKey && (
				<ResourceText resourceKey={footnoteResourceKey} className="l-font-footnote l-margin-2-t" />
			)}
			{maxLength && (
				<p
					className={
						'l-font-footnote l-margin-2-t' + (error && error.type !== 'required' ? ' l-font-error' : '')
					}
				>
					{(value && value.length) || 0}/{maxLength}
				</p>
			)}
		</FormGroup>
	);
}
