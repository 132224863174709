import { AuthenticationState, useAuthenticationContext } from '../common/authentication/AuthenticationContext';
import { ApiClientBase } from './apiClientBase';
import { FaultAreaType, ServiceRequestTargetScope } from '../common/faultReportForm';
import { useMemo } from 'react';
import { useAntiForgeryTokenContext } from '../boot/AntiForgery';

class ServiceRequestApiClient extends ApiClientBase {
	private anonymous?: boolean;

	constructor(context?: AuthenticationState, csrfToken?: string, anonymous?: boolean) {
		super(context, csrfToken);
		this.anonymous = anonymous;
	}

	async get(id: string) {
		return await this.fetchJson<ServiceRequestWithBusinessAreaAM>(`api/service-requests/${id}`);
	}

	async getServiceRequests() {
		return await this.fetchJson<ServiceRequestAM[]>(`api/service-requests`);
	}

	async createDraft(model: ServiceRequestAIM | ServiceRequestAnonymousAIM) {
		let id;

		if (this.anonymous && isServiceRequestAnonymous(model)) {
			id = await this.postJson<ServiceRequestAnonymousAIM, string>(`/api/service-requests/anonymous`, model);
		} else if (!isServiceRequestAnonymous(model)) {
			id = await this.postJson<ServiceRequestAIM, string>(`/api/service-requests`, model);
		} else {
			throw Error('createDraft: invalid model');
		}
		return id;
	}

	async downloadAttachmentBlob(id: string, attachmentId: string) {
		const apiUrl = `api/service-requests/${id}/attachment/${attachmentId}`;
		return this.fetchBlob(apiUrl);
	}

	async downloadCommentAttachmentBlob(id: string, attachmentId: string, commentId: Number) {
		const apiUrl = `api/service-requests/${id}/comments/${commentId}/attachment/${attachmentId}`;
		return this.fetchBlob(apiUrl);
	}

	addAttachment(id: string, file: File) {
		return this.fetchString(
			`/api/service-requests/${
				this.anonymous ? 'anonymous/' : ''
			}${id}/attachments?originalFilename=${encodeURIComponent(file.name)}`,
			'POST',
			{},
			file,
		);
	}

	addCommentAttachment(id: string, commentId: Number, file: File) {
		return this.fetchString(
			`/api/service-requests/${id}/comments/${commentId}/attachments?originalFilename=${encodeURIComponent(
				file.name,
			)}`,
			'POST',
			{},
			file,
		);
	}

	addComment(serviceRequestId: string, comment: string, containsAttachments: boolean) {
		return this.postJson<{ comment: string; containsAttachments: boolean }, ServiceRequestCommentAM>(
			`/api/service-requests/${serviceRequestId}/comments`,
			{
				comment,
				containsAttachments,
			},
		);
	}

	submit(id: string) {
		return this.post(`/api/service-requests/${this.anonymous ? 'anonymous/' : ''}${id}/submit`);
	}
}

export function useServiceRequestApi(anonymous?: boolean) {
	const authState = useAuthenticationContext(anonymous);
	const antiForgeryTokenContext = useAntiForgeryTokenContext(anonymous);

	return useMemo(() => {
		if (anonymous) {
			return new ServiceRequestApiClient(undefined, antiForgeryTokenContext.token, anonymous);
		}

		if (
			authState.contractId &&
			authState.userInfo &&
			antiForgeryTokenContext.token &&
			!antiForgeryTokenContext.isAnonymous
		) {
			return new ServiceRequestApiClient(authState, antiForgeryTokenContext.token, anonymous);
		}
	}, [authState, antiForgeryTokenContext.token, antiForgeryTokenContext.isAnonymous, anonymous]);
}

export enum PetOptions {
	NoPets = 'NoPets',
	PetsWithAccessOk = 'PetsWithAccessOk',
	PetsWithNoAccess = 'PetsWithNoAccess',
}

export enum ContactRequestType {
	ServiceRequest = 'ServiceRequest',
}

export enum StatusType {
	Draft = 'Draft',
	New = 'New',
	Received = 'Received',
	Done = 'Done',
	Other = 'Other',
}

export enum BusinessArea {
	CapitalRegion = 'CapitalRegion',
	RestOfFinland = 'RestOfFinland',
}

export enum ServiceRequestAMDestinationService {
	Tampuuri = 'Tampuuri',
	CustomerService = 'CustomerService',
}

export interface ServiceRequestAIM {
	targetScope: ServiceRequestTargetScope;
	masterKeyAccessAllowed?: boolean;
	petState?: PetOptions;
	title: string;
	description: string;
	contractId: string;
	contactRequestType: ContactRequestType;
	status?: StatusType;
}

export interface ServiceRequestAM {
	id: string;
	tampuuriId?: string;
	status: StatusType;
	targetScope: ServiceRequestTargetScope | FaultAreaType;
	contactType: ServiceRequestContactType;
	masterKeyAccessAllowed?: boolean;
	petState?: PetOptions;
	title: string;
	description: string;
	contractId: string;
	contactRequestType: ContactRequestType;
	statusChanges: ServiceRequestStatusChangeAM[];
	creationTime: Date;
	attachments: IAttachment[];
	reporter: Reporter;
	comments: ServiceRequestCommentAM[];
	destinationService: ServiceRequestAMDestinationService;
}

export interface ServiceRequestWithBusinessAreaAM {
	id: string;
	tampuuriId?: string;
	status: StatusType;
	targetScope: ServiceRequestTargetScope | FaultAreaType;
	contactType: ServiceRequestContactType;
	masterKeyAccessAllowed?: boolean;
	petState?: PetOptions;
	title: string;
	description: string;
	contractId: string;
	contactRequestType: ContactRequestType;
	statusChanges: ServiceRequestStatusChangeAM[];
	creationTime: Date;
	attachments: IAttachment[];
	reporter: Reporter;
	comments: ServiceRequestCommentAM[];
	destinationService: ServiceRequestAMDestinationService;
	businessArea: BusinessArea;
}

export interface IAttachment {
	id: string;
	fileName: string;
	tampuuriCommentId?: Number;
	contentType: string;
}

interface Reporter {
	email: string;
	name: string;
	phone: string;
}

export enum ServiceRequestContactType {
	ServiceRequest = 'ServiceRequest',
	DisturbanceReport = 'DisturbanceReport',
	RealEstateManagerContact = 'RealEstateManagerContact',
	KeyManagementContact = 'KeyManagementContact',
	MovingOtherTopic = 'MovingOtherTopic',
	CommonFacilities = 'CommonFacilities',
	KeysGeneric = 'KeysGeneric',
	Question = 'Question',
	Rent = 'Rent',
	RentOrExchange = 'RentOrExchange',
	Parking = 'Parking',
	Benefits = 'Benefits',
	Sauna = 'Sauna',
}

export function getServiceRequestContactTypeKey(type: ServiceRequestContactType) {
	switch (type) {
		case ServiceRequestContactType.CommonFacilities:
			return 'ServiceRequest_ContactType_CommonFacilities';
		case ServiceRequestContactType.DisturbanceReport:
			return 'ServiceRequest_ContactType_DisturbanceReport';
		case ServiceRequestContactType.RealEstateManagerContact:
			return 'ServiceRequest_ContactType_RealEstateManagerContact';
		case ServiceRequestContactType.KeyManagementContact:
			return 'ServiceRequest_ContactType_KeyManagementContact';
		case ServiceRequestContactType.MovingOtherTopic:
			return 'ServiceRequest_ContactType_MovingOtherTopic';
		case ServiceRequestContactType.ServiceRequest:
			return 'ServiceRequest_ContactType_ServiceRequest';
		case ServiceRequestContactType.KeysGeneric:
			return 'ServiceRequest_ContactType_KeysGeneric';
		case ServiceRequestContactType.Question:
			return 'ServiceRequest_ContactType_Question';
		case ServiceRequestContactType.Rent:
			return 'ServiceRequest_ContactType_Rent';
		case ServiceRequestContactType.RentOrExchange:
			return 'ServiceRequest_ContactType_RentOrExchange';
		case ServiceRequestContactType.Parking:
			return 'ServiceRequest_ContactType_Parking';
		case ServiceRequestContactType.Benefits:
			return 'ServiceRequest_ContactType_Benefits';
		case ServiceRequestContactType.Sauna:
			return 'ServiceRequest_ContactType_Sauna';
		default:
			return 'ServiceRequest_ContactType_Unknown';
	}
}

export interface ServiceRequestStatusChangeAM {
	id: string;
	changeTime: Date;
	newStatus: StatusType;
	communicationSubject: string;
	communicationContent: string;
}

export enum ServiceRequestCommentAMSpecifier {
	Unknown = 'Unknown',
	Resident = 'Resident',
	Staff = 'Staff',
}

export enum ServiceRequestCommentAMType {
	Internal = 'Internal',
	Public = 'Public',
}
export interface ServiceRequestCommentAM {
	tampuuriId: Number;
	comment: string;
	createdAt: Date;
	creator: string;
	specifier?: ServiceRequestCommentAMSpecifier;
	type: ServiceRequestCommentAMType;
	creatorSpecifier: string;
	readAt?: Date;
}

export interface ServiceRequestAnonymousInputs {
	name: string;
	email: string;
	phone: string;
}

export interface ServiceRequestAnonymousAIM
	extends Omit<ServiceRequestAIM, 'contractId'>,
		ServiceRequestAnonymousInputs {
	apartmentId: string;
	businessEntityId?: string;
}

function isServiceRequestAnonymous(model: any): model is ServiceRequestAnonymousAIM {
	return !model.contractId && Boolean(model.apartmentId);
}

export function getStatusTypeKey(statusType: StatusType, destinationService: ServiceRequestAMDestinationService) {
	if (destinationService === ServiceRequestAMDestinationService.CustomerService) {
		return 'ServiceRequestStatus_SentToCustomerService';
	}

	switch (statusType) {
		case StatusType.Done:
			return 'ServiceRequestStatus_Done';
		case StatusType.Draft:
			return 'ServiceRequestStatus_Draft';
		case StatusType.New:
			return 'ServiceRequestStatus_New';
		case StatusType.Received:
			return 'ServiceRequestStatus_Received';
		case StatusType.Other:
			return 'ServiceRequestStatus_Other';
		default:
			throw Error(`getStatusTypeKey: Invalid statusType: ${statusType}`);
	}
}

export function getStatusTypeClassName(statusType: StatusType) {
	switch (statusType) {
		case StatusType.Done:
			return 'l-background-color-success';
		case StatusType.Draft:
			return 'l-background-color-yellow';
		case StatusType.New:
			return 'l-background-color-blue is-dark';
		case StatusType.Received:
			return 'l-background-color-pending';
		case StatusType.Other:
			return 'l-background-color-grey';
		default:
			throw Error(`getStatusTypeClassName: Invalid statusType: ${statusType}`);
	}
}
