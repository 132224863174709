import { ReactNode } from 'react';
import { ResourceTextInline } from '../../common/localization/ResourceText';
import { SpacingContainer } from '@kojamo/lumo-dls';

export function SuccessMessageBox({ titleResourceKey, children }: { titleResourceKey: string; children: ReactNode }) {
	return (
		<div className="ml-form-step">
			<SpacingContainer direction="column" rowGap={5}>
				<div className="l-color-success l-display-flex l-align-items-center">
					<IconSuccess />
					<ResourceTextInline
						resourceKey={titleResourceKey}
						className="l-headline is-m l-margin-0 l-margin-5-l"
					/>
				</div>
				{children}
			</SpacingContainer>
		</div>
	);
}

function IconSuccess() {
	return (
		<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.1696 15.0938L18.3817 28.8817C18.0155 29.2478 17.422 29.2478 17.0558 28.8817L11.1429 22.9688L12.4688 21.6429L17.7188 26.8929L30.8438 13.7679L32.1696 15.0938Z"
				fill="#78BF40"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 21C0 9.40202 9.40202 0 21 0C26.5695 0 31.911 2.21249 35.8492 6.15076C39.7875 10.089 42 15.4305 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21ZM40.5 21C40.5 10.2304 31.7696 1.5 21 1.5C10.2304 1.5 1.5 10.2304 1.5 21C1.5 31.7696 10.2304 40.5 21 40.5C31.7696 40.5 40.5 31.7696 40.5 21Z"
				fill="#78BF40"
			/>
		</svg>
	);
}
