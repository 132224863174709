import { createContext, useContext, useEffect, useState } from 'react';
import { SettingsAM } from '../../api/settingsApiClient';
import { UserContactInfoApiClient, UserContactInformation } from '../../api/userContactInfoApiClient';
import { useSettings } from '../../boot/Settings';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';

const UserContactInformationProviderContext = createContext<UserContactInformation | undefined>(undefined);

export function UserContactInformationProvider(props: { children: React.ReactNode }) {
	const [contactInformation, setContactInformation] = useState<UserContactInformation | undefined>(undefined);
	const authContext = useAuthenticationContext();

	useEffect(() => {
		async function fetchData(): Promise<void> {
			const apiClient = new UserContactInfoApiClient(authContext);
			const response = await apiClient.fetchUserContactInfo();
			setContactInformation(response);
		}

		if (authContext.userInfo) {
			fetchData();
		}
	}, [authContext]);

	return (
		<UserContactInformationProviderContext.Provider value={contactInformation}>
			{props.children}
		</UserContactInformationProviderContext.Provider>
	);
}

export function useContactInformation(): {
	context: UserContactInformation | undefined;
	settings: SettingsAM | undefined;
	isValid: boolean;
} {
	const [isValid, setIsValid] = useState(false);
	const context = useContext(UserContactInformationProviderContext);
	const settings = useSettings();

	useEffect(() => {
		setIsValid(
			Boolean(context?.name) &&
				Boolean(context?.contractAddress?.street) &&
				Boolean(context?.phoneNumber) &&
				Boolean(context?.email),
		);
	}, [context]);

	return { context, settings, isValid };
}
