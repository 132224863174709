import { ApiClientBase } from './apiClientBase';

export interface BusinessPartnerAM {
	name: string;
	address: BusinessPartnerAddressAM;
	email: string;
	telephone: string;
}

export interface BusinessPartnerAddressAM {
	street: string;
	zipCode: string;
	postOffice: string;
}

export interface BusinessEntityPartnersAM {
	maintenance?: BusinessPartnerAM;
	keyManagement?: BusinessPartnerAM;
}

export class PartnerApiClient extends ApiClientBase {
	getBusinessEntityPartners(businessEntityId?: string) {
		return this.fetchJson<BusinessEntityPartnersAM>(
			`api/partners${businessEntityId ? `/business-entity/${businessEntityId}` : ''}`,
		);
	}
}
