import { useEffect, useState } from 'react';
import { ParkingSpaceApiClient, ParkingSpaceAvailabilityAM } from '../api/parkingSpaceApiClient';
import { useAuthenticationContext } from './authentication/AuthenticationContext';

export function useParkingSpacesAvailability() {
	const authState = useAuthenticationContext();
	const [parkingSpaceAvailability, setParkingSpaceAvailability] = useState<ParkingSpaceAvailabilityAM | undefined>();

	useEffect(() => {
		async function fetchData() {
			if (!authState.userInfo || !authState.contractId) {
				return;
			}

			const apiClient = new ParkingSpaceApiClient(authState);
			const response = await apiClient.getParkingSpaceAvailability();
			setParkingSpaceAvailability(response);
		}

		fetchData();
	}, [authState]);

	return parkingSpaceAvailability;
}
