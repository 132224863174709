import { requestShellNavigateTo } from '../boot/shellIntegration';
import { ContactBanner } from './ContactBanner';

export function ApartmentChangerBanner() {
	return (
		<ContactBanner
			textResourceKey="Contact_OtherTopics_Rental_Banner_Text"
			buttonTextResourceKey="Contact_OtherTopics_Rental_Banner_Button"
			imageResourceKey="Contact_OtherTopics_Rental_Banner"
			onButtonClick={() => requestShellNavigateTo('OurHouse', 'exchange-flow')}
		/>
	);
}
