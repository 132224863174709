import { AuthenticationState } from '../common/authentication/AuthenticationContext';
import { ApiClientBase } from './apiClientBase';

export class DisturbanceReportApiClient extends ApiClientBase {
	// params change to mandatory
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(context: AuthenticationState, csrfToken?: string) {
		super(context, csrfToken);
	}

	async submit(model: DisturbanceReportAIM) {
		return await this.postJson<DisturbanceReportAIM, string>('/api/disturbance-reports', model);
	}
}

export interface DisturbanceReportAIM {
	disturbingResidentName: string;
	disturbingResidentApartmentNumber: string;
	occurrences: DisturbanceAIM[];
}

export interface DisturbanceAIM {
	description: string;
	date: string;
	time: string;
}
