export function TextBadge(props: { variant: 'lumo-blue-dark' | 'lumo-pink'; text: string | number }) {
	const color = props.variant === 'lumo-blue-dark' ? '#002663' : '#CF3F6C';
	return (
		<div className="icon-step-num">
			<svg width="52" height="52" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg" version="1.1">
				<rect
					x="1.5"
					y="1.5"
					width="49"
					height="49"
					rx="24.5"
					stroke={color}
					fill="transparent"
					strokeWidth="3"
				/>

				<text
					textAnchor="middle"
					x="50%"
					y="50%"
					dy=".35em"
					fontFamily="sans-serif"
					fontSize="20px"
					fill={color}
					className="l-font-body is-xl is-contrast"
				>
					{props.text}
				</text>
			</svg>
		</div>
	);
}
