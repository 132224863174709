import { ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../ContactFormSection';
import { useSettings } from '../../boot/Settings';
import { localizedLinks } from '../../common/localization/localizedLinks';
import { useAppLanguage } from '../../common/localization/useAppLanguage';

export function ContractNotYetActive() {
	const { language } = useAppLanguage();
	const settings = useSettings();
	return (
		<ContactFormSection
			headingResourceKey="ContactView_ContractNotActive_Heading_Main"
			ingressTextKey="ContactView_ContractNotActive_MainContent"
		>
			<p className="l-font-body is-xl l-color-blue">
				<ResourceTextInline resourceKey="ContactView_ContractNotActive_QuestionForm_InfoText" />
				<a
					className="l-margin-2-l"
					target="_parent"
					href={`${settings?.shellBaseUrl}${localizedLinks.contact[language]}}`}
				>
					<ResourceTextInline resourceKey="ContactView_ContractNotActive_QuestionForm_Link" />
				</a>
			</p>
		</ContactFormSection>
	);
}
