import {
	ContactRequestType,
	ServiceRequestAnonymousAIM,
	ServiceRequestAnonymousInputs,
} from '../../api/serviceRequestApiClient';
import {
	FaultReportFormData,
	VisibleSection as VisibleSectionBase,
	getVisibleSections as getVisibleSectionsBase,
	isOwnApartmentFaultType,
} from '../../common/faultReportForm';

export type FaultReportAnonymousFormData = ServiceRequestAnonymousInputs & FaultReportFormData;

export function faultReportAnonymousFormDataToAPI(
	{ fault, subject, message, masterKey, pets, ...anonymousFormParts }: FaultReportAnonymousFormData,
	apartmentId: string,
	businessEntityId: string,
): ServiceRequestAnonymousAIM {
	if (!fault) {
		throw Error('faultReportFormDataToAPI: no fault type specified');
	}
	const serviceRequest = {
		targetScope: fault,
		title: subject,
		description: message,
		contactRequestType: ContactRequestType.ServiceRequest,
		...anonymousFormParts,
		apartmentId,
		businessEntityId,
	};
	return isOwnApartmentFaultType(fault)
		? { ...serviceRequest, masterKeyAccessAllowed: masterKey, petState: pets }
		: serviceRequest;
}

export type VisibleSection = 'ContactDetails' | 'FaultArea' | VisibleSectionBase;

export function getVisibleSections(section?: VisibleSection): VisibleSection[] {
	switch (section) {
		case 'ContactDetails':
			return [];
		default:
			return ['FaultArea', ...getVisibleSectionsBase(section as VisibleSectionBase)];
	}
}
