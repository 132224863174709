import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { FormProvider, useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import {
	FaultReportAnonymousFormData,
	faultReportAnonymousFormDataToAPI,
	getVisibleSections,
	VisibleSection,
} from './FaultReportAnonymousFormData';
import { FaultAreaType, OwnApartmentFaultType, ServiceRequestTargetScope } from '../../common/faultReportForm';
import { useServiceRequestApi } from '../../api/serviceRequestApiClient';
import {
	AdditionalInformationAndConfirmation,
	CommonAndOutdoorAreaFault,
	FaultArea,
	FaultReportConfirmation,
	OwnApartmentFault,
	OwnApartmentFaultAndServiceWork,
} from '../FaultReport/FaultReport';
import { useSearchParams } from 'react-router-dom';
import { FormStep } from '../../Components/forms/FormStep';
import { TextFormField } from '../../Components/forms/TextFormField';
import { BreadCrumbs } from '../../Components/BreadCrumbs';
import { useSettings } from '../../boot/Settings';
import { Button, SpacingContainer } from '@kojamo/lumo-dls';
import { useResource } from '@kojamo/react-utils';
import { redirectParentTo } from '../../common/redirect';
import { useAppLanguage } from '../../common/localization/useAppLanguage';
import { localizedLinks } from '../../common/localization/localizedLinks';

export function FaultReportAnonymous() {
	const { language } = useAppLanguage();
	const settings = useSettings();
	const maintenanceInformationHref = `${settings?.shellBaseUrl}${localizedLinks.maintenanceInformation[language]}`;
	return (
		<>
			<BreadCrumbs>
				<a href={maintenanceInformationHref} target="_parent">
					<ResourceTextInline resourceKey="Breadcrumbs_FaultReport_Root" />
				</a>
				<ResourceTextInline resourceKey="Breadcrumbs_FaultReport_Title" />
			</BreadCrumbs>
			<FaultReportAnonymousContent maintenanceInformationHref={maintenanceInformationHref} />
		</>
	);
}

export function FaultReportAnonymousContent({ maintenanceInformationHref }: { maintenanceInformationHref: string }) {
	const { formMethods, onSubmit, fault, setFault, reportId, apartmentId, isSubmitting, error } = useFaultReport();
	const { openSection, setOpenSection, visibleSections } = useFaultReportSections(fault, setFault);

	useEffect(() => {
		if (!apartmentId) {
			redirectParentTo(maintenanceInformationHref);
		}
	}, [apartmentId, maintenanceInformationHref]);

	return !reportId ? (
		<Wizard
			openSection={openSection}
			setOpenSection={setOpenSection}
			visibleSections={visibleSections}
			fault={fault}
			formMethods={formMethods}
			onSubmit={onSubmit}
			isSubmitting={isSubmitting}
			error={error}
		/>
	) : (
		<FaultReportConfirmation isAuthenticated={false} />
	);
}

function useFaultReport() {
	const [searchParams] = useSearchParams();
	const apartmentId = searchParams.get('apartmentId');
	const businessEntityId = searchParams.get('businessEntityId');
	const [reportId, setReportId] = useState<string | undefined>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<Error | undefined>();
	const apiClient = useServiceRequestApi(true);
	const formMethods = useForm<FaultReportAnonymousFormData>();
	const { watch, setValue } = formMethods;
	const fault = watch('fault');
	const setFault = useCallback((fault?: ServiceRequestTargetScope) => setValue('fault', fault), [setValue]);

	const onSubmit = formMethods.handleSubmit(async (data: FaultReportAnonymousFormData) => {
		if (!apartmentId) {
			throw Error('onSubmit: expected apartmentId to be defined');
		}
		if (!businessEntityId) {
			throw Error('onSubmit: expected businessEntityId to be defined');
		}

		setIsSubmitting(true);
		try {
			const id = await apiClient?.createDraft(
				faultReportAnonymousFormDataToAPI(data, apartmentId, businessEntityId),
			);

			if (!id) {
				return;
			}

			if (data.files?.length) {
				for (let i = 0; i < data.files.length; i++) {
					const file = data.files[i];
					if (file) {
						await apiClient?.addAttachment(id, file);
					}
				}
			}
			await apiClient?.submit(id);
			setReportId(id);
		} catch (e) {
			if (e instanceof Error) {
				setError(e);
			} else {
				setError(new Error('Submitting service request failed'));
			}
		} finally {
			setIsSubmitting(false);
		}
	});

	return { formMethods, onSubmit, fault, setFault, reportId, apartmentId, isSubmitting, error };
}

function useFaultReportSections(
	fault: ServiceRequestTargetScope | undefined,
	setFault: (fault?: ServiceRequestTargetScope) => void,
) {
	const [openSection, setOpenSection] = useState<VisibleSection>('ContactDetails');
	const [visibleSections, setVisibleSections] = useState<VisibleSection[]>([]);

	useEffect(() => {
		if (openSection) {
			const newVisibleSections = getVisibleSections(openSection);
			if (newVisibleSections.length && !visibleSections.includes(openSection) && openSection !== 'Confirmation') {
				setVisibleSections(newVisibleSections);
				setFault(undefined);
			} else if (
				openSection === OwnApartmentFaultType.FaultAndServiceWork &&
				fault === OwnApartmentFaultType.ApartmentNameChange
			) {
				setFault(undefined);
			}
		}
	}, [openSection, visibleSections, fault, setFault]);

	return { openSection, setOpenSection, visibleSections };
}

function Wizard({
	formMethods,
	onSubmit,
	fault,
	openSection,
	setOpenSection,
	visibleSections,
	isSubmitting,
	error,
}: {
	formMethods: UseFormReturn<FaultReportAnonymousFormData>;
	onSubmit: FormEventHandler<HTMLFormElement>;
	fault?: ServiceRequestTargetScope;
	openSection?: VisibleSection;
	setOpenSection: (openSection: VisibleSection) => void;
	visibleSections: VisibleSection[];
	isSubmitting: boolean;
	error: Error | undefined;
}) {
	return (
		<ContactFormSection
			headingResourceKey="ContactForms_FaultReport_Heading"
			ingressTextKey="ContactForms_FaultReport_Ingress"
		>
			<FormProvider {...formMethods}>
				<form onSubmit={onSubmit}>
					<ContactDetails
						isOpen={openSection === 'ContactDetails'}
						onOpenRequested={() => setOpenSection('ContactDetails')}
						setOpenSection={setOpenSection}
					/>
					{visibleSections.includes('FaultArea') && (
						<FaultArea
							stepNumber={2}
							isOpen={openSection === 'FaultArea'}
							onOpenRequested={() => setOpenSection('FaultArea')}
							faultArea={2 < visibleSections.length ? (visibleSections[1] as FaultAreaType) : undefined}
							setOpenSection={setOpenSection}
						/>
					)}
					{visibleSections.includes(FaultAreaType.RentalObject) && (
						<OwnApartmentFault
							stepNumber={3}
							isOpen={openSection === FaultAreaType.RentalObject}
							onOpenRequested={() => setOpenSection(FaultAreaType.RentalObject)}
							setOpenSection={setOpenSection}
						/>
					)}
					{visibleSections.includes(OwnApartmentFaultType.FaultAndServiceWork) &&
						fault !== OwnApartmentFaultType.ApartmentNameChange && (
							<OwnApartmentFaultAndServiceWork
								stepNumber={4}
								isOpen={openSection === OwnApartmentFaultType.FaultAndServiceWork}
								onOpenRequested={() => setOpenSection(OwnApartmentFaultType.FaultAndServiceWork)}
								setOpenSection={setOpenSection}
							/>
						)}
					{visibleSections.includes(FaultAreaType.CommonAndOutdoorArea) && (
						<CommonAndOutdoorAreaFault
							stepNumber={3}
							isOpen={openSection === FaultAreaType.CommonAndOutdoorArea}
							onOpenRequested={() => setOpenSection(FaultAreaType.CommonAndOutdoorArea)}
							setOpenSection={setOpenSection}
						/>
					)}
					{fault ? (
						<AdditionalInformationAndConfirmation
							stepNumber={visibleSections.length + 1}
							isOpen={openSection === 'Confirmation'}
							onOpenRequested={() => setOpenSection('Confirmation')}
							isSubmitting={isSubmitting}
							error={error}
							isAuthenticated={false}
						/>
					) : null}
				</form>
			</FormProvider>
		</ContactFormSection>
	);
}

export function ContactDetails({
	isOpen,
	onOpenRequested,
	setOpenSection,
}: {
	isOpen: boolean;
	onOpenRequested: () => void;
	setOpenSection: (openSection: VisibleSection) => void;
}) {
	const { getResourceText } = useResource();
	const requiredText = getResourceText('ContactForms_RequiredField_Error');
	const { trigger } = useFormContext();
	return (
		<FormStep
			number={1}
			heading={<ResourceTextInline resourceKey="ContactForms_FaultReport_StepContactDetails_Heading" />}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<TextFormField
					name="name"
					labelResourceKey="ContactForms_FaultReport_StepContactDetails_Name_Label"
					required={requiredText}
				/>
				<TextFormField
					name="phone"
					labelResourceKey="ContactForms_FaultReport_StepContactDetails_Phone_Label"
					required={requiredText}
				/>
				<TextFormField
					name="email"
					labelResourceKey="ContactForms_FaultReport_StepContactDetails_Email_Label"
					required={requiredText}
				/>
			</SpacingContainer>
			<ResourceText
				resourceKey="ContactForms_RequiredFields_Instruction"
				className="l-margin-4-t l-font-footnote"
			/>
			<Button
				className="l-margin-5-t"
				onClick={async () => {
					const valid = await trigger(['name', 'phone', 'email']);
					if (valid) {
						setOpenSection('FaultArea');
					}
				}}
				type="button"
			>
				<ResourceText resourceKey="ContactForms_FaultReport_Next_Label" textType="Plain" />
			</Button>
		</FormStep>
	);
}
