import MarkdownIt from 'markdown-it';
import markdownItLinkAttributes from 'markdown-it-link-attributes';

const markdownItProps = { breaks: true };
const mdTargetRegular = new MarkdownIt(markdownItProps);
const mdTargetTop = new MarkdownIt(markdownItProps);
const mdTargetBlank = new MarkdownIt(markdownItProps);
const mdTargetRegularHtml = new MarkdownIt({ ...markdownItProps, html: true });
const mdTargetTopHtml = new MarkdownIt({ ...markdownItProps, html: true });
const mdTargetBlankHtml = new MarkdownIt({ ...markdownItProps, html: true });

mdTargetTop.use(markdownItLinkAttributes, {
	attrs: {
		target: '_top',
	},
});

mdTargetTopHtml.use(markdownItLinkAttributes, {
	attrs: {
		target: '_top',
	},
});

mdTargetBlank.use(markdownItLinkAttributes, {
	attrs: {
		target: '_blank',
	},
});

mdTargetBlankHtml.use(markdownItLinkAttributes, {
	attrs: {
		target: '_blank',
	},
});

export type MarkdownContentProps = {
	text: string;
	inline?: boolean;
	tagName?: keyof JSX.IntrinsicElements;
	className?: string;
	linksTargetBlank?: boolean;
	allowhtml?: boolean;
	/**
	 *
	 */
	linkOpenMode?: 'self' | 'top' | 'blank';
};

function getMdInstance(linkTarget?: 'self' | 'top' | 'blank', allowHtml?: boolean) {
	switch (linkTarget) {
		case 'self':
			return allowHtml ? mdTargetRegularHtml : mdTargetRegular;
		case 'blank':
			return allowHtml ? mdTargetBlankHtml : mdTargetBlank;
		case 'top':
		default:
			return allowHtml ? mdTargetTopHtml : mdTargetTop;
	}
}

export function MarkdownContent(props: MarkdownContentProps) {
	try {
		if (props.text == null) {
			return null;
		}
		const mdInstance = getMdInstance(props.linkOpenMode, props.allowhtml);
		const content = props.inline ? mdInstance.renderInline(props.text) : mdInstance.render(props.text);
		const html = { __html: content };

		const Container = props.tagName || (props.inline ? 'span' : 'div');
		return <Container dangerouslySetInnerHTML={html} className={props.className} />;
	} catch (error) {
		throw error;
	}
}
