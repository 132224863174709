import { FormProvider } from 'react-hook-form';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSettings } from '../../boot/Settings';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import { CustomerServiceMessageConfirmation } from '../../Components/confirmationPage/CustomerServiceMessageConfirmation';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { CoTenantPrivacyNoticeCustomerService } from '../../Components/CoTenantPrivacyNotices';
import { FileInputField } from '../../Components/forms/FileInputField';
import { FormStep } from '../../Components/forms/FormStep';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { TextFormField } from '../../Components/forms/TextFormField';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { SpacingContainer } from '@kojamo/lumo-dls';
import { useBenefitsForm } from './useBenefitsForm';
import { localizedLinks } from '../../common/localization/localizedLinks';
import { useAppLanguage } from '../../common/localization/useAppLanguage';
import { ContactFormTelemetryType } from '../../common/telemetryGA4';

export function BenefitsContact() {
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_Contact_Benefits" />
			</ContactBreadCrumbs>
			<Routes>
				<Route path="" element={<Content />} />
				<Route
					path="complete"
					element={
						<CustomerServiceMessageConfirmation
							headingResourceKey="ContactForms_Benefits_Confirmation_Heading"
							stepTitleResourceKey="ContactForms_Benefits_Confirmation_Step_Heading"
							stepContentResourceKey="ContactForms_Benefits_Confirmation_Step_Content"
						/>
					}
				/>
			</Routes>
		</>
	);
}

function Content() {
	const { contractId } = useAuthenticationContext();

	if (!contractId) {
		return <LoadingSpinner />;
	}

	return <Wizard />;
}

function Wizard() {
	const navigate = useNavigate();
	const settings = useSettings();
	const { formMethods, onSubmit, submitStatus } = useBenefitsForm();
	const { language } = useAppLanguage();
	const subjectMaxLength = 100;
	const messageMaxLength = 2000;

	if (submitStatus === 'success') {
		navigate('complete');
	}

	return (
		<ContactFormSection
			headingResourceKey={'ContactForms_Benefits_Heading'}
			ingressTextNode={
				<ResourceText
					resourceKey="ContactForms_Benefits_Ingress"
					className="l-font-body is-xl l-color-blue l-margin-5-b"
					linkOpenMode="top"
					parameters={[`${settings?.shellBaseUrl}${localizedLinks.services[language]}`]}
				/>
			}
			showContactInformation
		>
			<FormProvider {...formMethods}>
				<form onSubmit={onSubmit}>
					<FormStep
						number={1}
						heading={<ResourceTextInline resourceKey={'ContactForms_Benefits_Heading_Step1'} />}
						isOpen={true}
					>
						<SpacingContainer direction="column" rowGap={6}>
							<ResourceText
								resourceKey={'ContactForms_Benefits_Ingress_Step1'}
								optional={true}
								className="l-font-body"
							/>
							<TextFormField
								name="subject"
								labelResourceKey="ContactForms_Subject_Label"
								placeholderResourceKey="ContactForms_Subject_Placeholder"
								maxLength={subjectMaxLength}
							/>
							<TextAreaField
								name="message"
								labelResourceKey="ContactForms_Message_Label"
								placeholderResourceKey="ContactForms_Message_Placeholder"
								footnoteResourceKey="ContactForms_Benefits_Message_Footnote"
								maxLength={messageMaxLength}
							/>
							<FileInputField
								name="files"
								labelResourceKey="ContactForms_Attachment_Label"
								footNoteResourceKey="ContactForms_Attachment_Footnote"
								fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
							/>
							<CoTenantPrivacyNoticeCustomerService />
						</SpacingContainer>
						<SubmitButtonAndProgress
							submitStatus={submitStatus}
							formTypeForTelemetry={ContactFormTelemetryType.Benefits}
						/>
					</FormStep>
				</form>
			</FormProvider>
		</ContactFormSection>
	);
}
