import React from 'react';
import { useSettings } from '../boot/Settings';
import { AuthenticationClient } from '../common/authentication/authenticationClient';

export function OidcCallback(props: { silent: boolean }) {
	const settings = useSettings();
	const [authenticationStarted, setAuthenticationStarted] = React.useState<boolean>(false);

	const refreshAuthenticationState = async (authority: string) => {
		if (!authority || authenticationStarted) {
			return;
		}

		setAuthenticationStarted(true);

		const authenticationClient = new AuthenticationClient({ authority });

		// this page is loaded in an iframe and calling receive here notifies the parent frame
		if (props.silent) {
			await authenticationClient.receiveSilent();
			return;
		}

		// If silent auth fails, we should redirect parent window to SSO. Shell should take care of that though.
	};

	React.useEffect(() => {
		if (!settings?.ssoAuthority) {
			return;
		}

		refreshAuthenticationState(settings.ssoAuthority);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings?.ssoAuthority]);

	return <p>Käsitellään kirjautumista</p>;
}
