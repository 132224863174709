import React from 'react';
import { LoadingSpinner } from '../LoadingSpinner';
import { ContractNoLongerActive } from './ContractNoLongerActive';
import { ContractNotYetActive } from './ContractNotYetActive';
import addMonths from 'date-fns/addMonths';
import { useContractContext } from './ContractContextProvider';

interface Props {
	/**
	 * Require that contract has started. Default is that access is allowed if start date is in future
	 */
	requiredStartedContract?: boolean;
	/**
	 * Allow access after contract has ended. Enter a number in months. Default is to not show after contract has ended.
	 */
	showAfterContractEndingMonths?: number;
	children: React.ReactElement;
}

export function RequireValidContract({ children, requiredStartedContract, showAfterContractEndingMonths }: Props) {
	const context = useContractContext();

	if (!context.contractState) {
		return <LoadingSpinner />;
	}

	const now = new Date();
	if (requiredStartedContract && now < context.contractState.startDate) {
		return <ContractNotYetActive />;
	} else if (context.contractState.endDate && context.contractState.endDate < now) {
		if (
			!showAfterContractEndingMonths ||
			addMonths(context.contractState.endDate, showAfterContractEndingMonths) < now
		) {
			return <ContractNoLongerActive />;
		}
	}

	return children;
}
