import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { useEffect, useState } from 'react';
import { SaunaApiClient, SaunaDetailsAM } from '../../api/saunaApiClient';

export function useSaunaDetails() {
	const authContext = useAuthenticationContext();
	const [saunaDetails, setSaunaDetails] = useState<SaunaDetailsAM>();

	useEffect(() => {
		async function fetchData() {
			if (!authContext.contractId) {
				return;
			}
			const apiClient = new SaunaApiClient(authContext);
			const res = await apiClient.getSaunaDetails();
			setSaunaDetails(res);
		}

		fetchData();
	}, [authContext]);

	return saunaDetails;
}
