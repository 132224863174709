import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';

import { TextAreaField } from '../../Components/forms/TextAreaField';
import { TextFormField } from '../../Components/forms/TextFormField';
import { FormProvider } from 'react-hook-form';
import { FormStep } from '../../Components/forms/FormStep';

import { useNavigate } from 'react-router';

import { FileInputField } from '../../Components/forms/FileInputField';

import { SpacingContainer } from '@kojamo/lumo-dls';
import { useSaunaForm } from './useSaunaForm';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';

import { SaunaDetailsAM, SaunaReservationProviderType } from '../../api/saunaApiClient';

import { CommonAndOutdoorAreaFaultType, getFaultReportUrl } from '../../common/faultReportForm';
import { CoTenantPrivacyNoticeCustomerService } from '../../Components/CoTenantPrivacyNotices';
import { useAppLanguage } from '../../common/localization/useAppLanguage';

import SaunaWizard from './SaunaWizard';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { ContactFormTelemetryType } from '../../common/telemetryGA4';

function SaunaContactForm({ saunaDetails, contractId }: { saunaDetails: SaunaDetailsAM; contractId: string }) {
	const navigate = useNavigate();
	const { formMethods, onSubmit, submitStatus } = useSaunaForm();
	const { language } = useAppLanguage();

	const validProviderTypes = [
		SaunaReservationProviderType.OnlineSaunaReservation,
		SaunaReservationProviderType.OnetimeSaunaReservation_Electrolux,
		SaunaReservationProviderType.SharedFacilityReservations,
	];

	const shouldRenderWizard = validProviderTypes.includes(saunaDetails.saunaReservationProviderType);

	if (submitStatus === 'success') {
		navigate('complete');
	}

	return (
		<RequireValidContract>
			<ContactFormSection
				headingResourceKey={'ContactForms_Sauna_Heading'}
				ingressTextNode={
					<ResourceText
						resourceKey="ContactForms_Sauna_Ingress"
						className="l-font-body is-xl l-color-blue l-margin-5-b"
						linkOpenMode="self"
						parameters={[
							getFaultReportUrl(
								contractId,
								CommonAndOutdoorAreaFaultType.CommonAreaServiceNeed,
								language,
							),
						]}
					/>
				}
				showContactInformation
			>
				<FormProvider {...formMethods}>
					<form onSubmit={onSubmit}>
						{shouldRenderWizard ? (
							<SaunaWizard
								saunaDetails={saunaDetails}
								contractId={contractId}
								submitStatus={submitStatus}
							/>
						) : (
							<FormStep
								number={1}
								heading={<ResourceTextInline resourceKey={'ContactForms_Sauna_Heading_Step1'} />}
								isOpen={true}
							>
								<SpacingContainer direction="column" rowGap={6}>
									<ResourceText
										resourceKey={'ContactForms_Sauna_Ingress_Step1'}
										optional={true}
										className="l-font-body"
										linkOpenMode="self"
										parameters={[
											getFaultReportUrl(
												contractId,
												CommonAndOutdoorAreaFaultType.CommonAreaServiceNeed,
												language,
											),
										]}
									/>
									<TextFormField
										name="subject"
										labelResourceKey="ContactForms_Subject_Label"
										placeholderResourceKey="ContactForms_Subject_Placeholder"
									/>
									<TextAreaField
										name="message"
										labelResourceKey="ContactForms_Message_Label"
										placeholderResourceKey="ContactForms_Message_Placeholder"
										footnoteResourceKey="ContactForms_Sauna_Message_Footnote"
									/>
									<FileInputField
										name="files"
										labelResourceKey="ContactForms_Attachment_Label"
										footNoteResourceKey="ContactForms_Attachment_Footnote"
										fileTypes={[
											'application/pdf',
											'image/jpg',
											'image/jpeg',
											'image/gif',
											'image/png',
										]}
									/>
									<CoTenantPrivacyNoticeCustomerService />
								</SpacingContainer>
								<SubmitButtonAndProgress
									submitStatus={submitStatus}
									formTypeForTelemetry={ContactFormTelemetryType.Sauna}
								/>
							</FormStep>
						)}
					</form>
				</FormProvider>
			</ContactFormSection>
		</RequireValidContract>
	);
}

export default SaunaContactForm;
