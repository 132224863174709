import React from 'react';
import { useForm } from 'react-hook-form';
import { OtherTopicsFormData } from './OtherTopicsFormData';
import { ContactApiClient } from '../../api/contactApiClient';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { SubmitStatus } from '../../Components/submitStatus';
import { useAntiForgeryTokenContext } from '../../boot/AntiForgery';

export function useOtherTopicsForm() {
	const [submitStatus, setSubmitStatus] = React.useState<SubmitStatus>('initialized');
	const formMethods = useForm<OtherTopicsFormData>();
	const authState = useAuthenticationContext();
	const { token: antiForgeryToken } = useAntiForgeryTokenContext();

	const onSubmit = formMethods.handleSubmit(async (data: OtherTopicsFormData) => {
		setSubmitStatus('submitting');
		const apiClient = new ContactApiClient(authState, antiForgeryToken);

		apiClient
			.sendOtherContact(data)
			.then(() => {
				setSubmitStatus('success');
			})
			.catch(() => setSubmitStatus('error'));
	});

	return { formMethods, onSubmit, submitStatus };
}
