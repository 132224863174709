import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { Route, Routes } from 'react-router';
import { CustomerServiceMessageConfirmation } from '../../Components/confirmationPage/CustomerServiceMessageConfirmation';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import SaunaContactForm from './SaunaContactForm';
import { useSaunaDetails } from './useSaunaDetails';

export function SaunaContact() {
	const saunaDetails = useSaunaDetails();
	const { contractId } = useAuthenticationContext();

	if (!saunaDetails || !contractId) {
		return <LoadingSpinner />;
	}

	if (!saunaDetails.hasCommonSauna) {
		return <ResourceText resourceKey="ContactForms_Sauna_NoSauna" />;
	}
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_Contact_Sauna" />
			</ContactBreadCrumbs>
			<Routes>
				<Route path="/" element={<SaunaContactForm saunaDetails={saunaDetails} contractId={contractId} />} />
				<Route
					path="complete"
					element={
						<CustomerServiceMessageConfirmation
							headingResourceKey="ContactForms_Sauna_Confirmation_Heading"
							stepTitleResourceKey="ContactForms_Sauna_Confirmation_Step_Heading"
							stepContentResourceKey="ContactForms_Sauna_Confirmation_Step_Content"
						/>
					}
				/>
			</Routes>
		</>
	);
}
