import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { TextFormField } from '../../Components/forms/TextFormField';
import { FormProvider } from 'react-hook-form';
import { FormStep } from '../../Components/forms/FormStep';
import { Route, Routes, useNavigate } from 'react-router';
import { CustomerServiceMessageConfirmation } from '../../Components/confirmationPage/CustomerServiceMessageConfirmation';
import { FileInputField } from '../../Components/forms/FileInputField';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import { SpacingContainer } from '@kojamo/lumo-dls';
import { useRentAndContractForm } from './useRentAndContractForm';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';
import { CoTenantPrivacyNoticeCustomerService } from '../../Components/CoTenantPrivacyNotices';

import { ContactFormTelemetryType } from '../../common/telemetryGA4';

export function RentAndContract() {
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_Contact_RentAndContract" />
			</ContactBreadCrumbs>
			<RentAndContractContent />
		</>
	);
}

export function RentAndContractContent() {
	return (
		<Routes>
			<Route path="/" element={<Wizard />} />
			<Route
				path="complete"
				element={
					<CustomerServiceMessageConfirmation
						headingResourceKey="ContactForms_RentAndContract_Confirmation_Heading"
						stepTitleResourceKey="ContactForms_RentAndContract_Confirmation_Step_Heading"
						stepContentResourceKey="ContactForms_RentAndContract_Confirmation_Step_Content"
					/>
				}
			/>
		</Routes>
	);
}

function Wizard() {
	const navigate = useNavigate();
	const { formMethods, onSubmit, submitStatus } = useRentAndContractForm();

	if (submitStatus === 'success') {
		navigate('/rent-and-contract/complete');
	}

	return (
		<RequireValidContract showAfterContractEndingMonths={6}>
			<ContactFormSection
				headingResourceKey={'ContactForms_RentAndContract_Heading'}
				ingressTextKey="ContactForms_RentAndContract_Ingress"
				showContactInformation
			>
				<FormProvider {...formMethods}>
					<form onSubmit={onSubmit}>
						<FormStep
							number={1}
							heading={<ResourceTextInline resourceKey={'ContactForms_RentAndContract_Heading_Step1'} />}
							isOpen={true}
						>
							<SpacingContainer direction="column" rowGap={6}>
								<ResourceText
									resourceKey={'ContactForms_RentAndContract_Ingress_Step1'}
									optional={true}
									className="l-font-body"
								/>
								<TextFormField
									name="subject"
									labelResourceKey="ContactForms_Subject_Label"
									placeholderResourceKey="ContactForms_Subject_Placeholder"
								/>
								<TextAreaField
									name="message"
									labelResourceKey="ContactForms_Message_Label"
									placeholderResourceKey="ContactForms_Message_Placeholder"
									footnoteResourceKey="ContactForms_RentAndContract_Message_Footnote"
								/>
								<FileInputField
									name="files"
									labelResourceKey="ContactForms_Attachment_Label"
									footNoteResourceKey="ContactForms_Attachment_Footnote"
									fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
								/>
								<CoTenantPrivacyNoticeCustomerService />
							</SpacingContainer>
							<SubmitButtonAndProgress
								submitStatus={submitStatus}
								formTypeForTelemetry={ContactFormTelemetryType.RentAndContract}
							/>
						</FormStep>
					</form>
				</FormProvider>
			</ContactFormSection>
		</RequireValidContract>
	);
}
