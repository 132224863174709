import { ResourceText } from '../common/localization/ResourceText';

export function CoTenantPrivacyNoticeServiceRequest() {
	return (
		<ResourceText
			resourceKey="ContactForms_CoTenantPrivacyNoticeServiceRequest"
			className="l-font-body is-xl l-color-blue is-dark"
		/>
	);
}

export function CoTenantPrivacyNoticeCustomerService() {
	return (
		<ResourceText
			resourceKey="ContactForms_CoTenantPrivacyNoticeCustomerService"
			className="l-font-body is-xl l-color-blue is-dark"
		/>
	);
}
