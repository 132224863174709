import React, { useEffect, useMemo, useState } from 'react';
import { Environment, ResourceContextProvider } from '@kojamo/react-utils';
import { Loader } from '@kojamo/lumo-dls';
import { useAppLanguage } from './useAppLanguage';
import { ResourceApiClient } from '../../api/resourcesApiClient';

export interface ILanguageState {
	currentLanguage: string;
	resourceTexts?: ResourceTexts;
}

type ResourceTexts = { [key: string]: string };

const processEnv = process.env.NODE_ENV;

const environment =
	processEnv === 'development'
		? Environment.Development
		: processEnv === 'test'
		? Environment.Testing
		: Environment.Production;

export function ResourceTextProvider({ children }: { children: React.ReactNode }) {
	const { language } = useAppLanguage();

	const [resources, setResources] = useState<ResourceTexts | undefined>(undefined);

	const resourceApiClient = useMemo(() => new ResourceApiClient(), []);

	useEffect(() => {
		let valid = true;
		const fetchResources = async () => {
			const response = await resourceApiClient.fetchResources(language);
			if (valid) {
				setResources(response);
			}
		};
		fetchResources();

		return () => {
			valid = false;
		};
	}, [resourceApiClient, language]);

	const resourceState = useMemo(
		() => ({
			currentLanguage: language,
			resourceTexts: resources,
		}),
		[resources, language],
	);

	return resources ? (
		<ResourceContextProvider
			environment={environment}
			logError={(error, context) => console.log(error, context)}
			resourceState={resourceState}
		>
			{children}
		</ResourceContextProvider>
	) : (
		<Loader />
	);
}
