import { useState, useEffect } from 'react';
import { useContractContext } from '../Components/contractValidity/ContractContextProvider';

type DisabledState = {
	isDisabled: boolean;
	ribbonResourceKey: string;
};

export function useContractStateOverlay(requiresStartedContract?: boolean, allowEndedContract?: boolean) {
	const { contractState } = useContractContext();
	const [disabledState, setDisabledState] = useState<DisabledState>({ isDisabled: false, ribbonResourceKey: '' });

	useEffect(() => {
		if (contractState && allowEndedContract !== true && contractState.hasEnded) {
			setDisabledState({
				isDisabled: true,
				ribbonResourceKey: 'ContactForms_WayFinder_RibbonContractHasEnded',
			});
		} else if (contractState && requiresStartedContract && !contractState.hasStarted) {
			setDisabledState({
				isDisabled: true,
				ribbonResourceKey: 'ContactForms_WayFinder_RibbonContractNotStarted',
			});
		} else {
			setDisabledState({ isDisabled: false, ribbonResourceKey: '' });
		}
	}, [contractState, allowEndedContract, requiresStartedContract]);

	return disabledState;
}
