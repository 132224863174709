import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { UILanguage } from '../Reducer/userReducer';

export const appStateActions = {
	init: createAction('APP/INIT')(),
	setLanguage: createAsyncAction(
		'appState/SET_LANGUAGE_REQUEST',
		'appState/SET_LANGUAGE_SUCCESS',
		'appState/SET_LANGUAGE_FAILURE',
	)<undefined, UILanguage, Error>(),
};

export type AppStateActionTypes = ActionType<typeof appStateActions>;
