export function IconClose({ color }: { color?: string }) {
	const iconColor = color || '#222222';
	return (
		<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6029 5.97986L8.94 8.49986L11.6029 11.0199C11.7114 11.1484 11.7166 11.3341 11.6154 11.4684C11.496 11.6279 11.2703 11.6593 11.112 11.5399L8.5 9.07129L5.88857 11.5399C5.75486 11.641 5.568 11.6353 5.44 11.5273C5.288 11.3987 5.26914 11.1719 5.39714 11.0199L8.06 8.49986L5.39714 5.97986C5.28914 5.85129 5.28343 5.66558 5.38457 5.53129C5.504 5.37243 5.72971 5.34043 5.88857 5.45986L8.5 7.92843L11.112 5.45986C11.1291 5.43929 11.1486 5.42043 11.1703 5.40443C11.3291 5.28501 11.5549 5.31701 11.6743 5.47586C11.7937 5.63472 11.7617 5.86043 11.6029 5.97986ZM8.5 15.9284C4.39771 15.9284 1.07143 12.6027 1.07143 8.49986C1.07143 4.39701 4.39771 1.07129 8.5 1.07129C12.6029 1.07129 15.9286 4.39701 15.9286 8.49986C15.9286 12.6027 12.6029 15.9284 8.5 15.9284ZM8.5 0.499863C4.08171 0.499863 0.5 4.08158 0.5 8.49986C0.5 12.9187 4.08171 16.4999 8.5 16.4999C12.9189 16.4999 16.5 12.9187 16.5 8.49986C16.5 4.08158 12.9189 0.499863 8.5 0.499863Z"
				fill="#222222"
			/>
			<mask
				id="mask0_0_1222"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="17"
				height="17"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.6029 5.97986L8.94 8.49986L11.6029 11.0199C11.7114 11.1484 11.7166 11.3341 11.6154 11.4684C11.496 11.6279 11.2703 11.6593 11.112 11.5399L8.5 9.07129L5.88857 11.5399C5.75486 11.641 5.568 11.6353 5.44 11.5273C5.288 11.3987 5.26914 11.1719 5.39714 11.0199L8.06 8.49986L5.39714 5.97986C5.28914 5.85129 5.28343 5.66558 5.38457 5.53129C5.504 5.37243 5.72971 5.34043 5.88857 5.45986L8.5 7.92843L11.112 5.45986C11.1291 5.43929 11.1486 5.42043 11.1703 5.40443C11.3291 5.28501 11.5549 5.31701 11.6743 5.47586C11.7937 5.63472 11.7617 5.86043 11.6029 5.97986ZM8.5 15.9284C4.39771 15.9284 1.07143 12.6027 1.07143 8.49986C1.07143 4.39701 4.39771 1.07129 8.5 1.07129C12.6029 1.07129 15.9286 4.39701 15.9286 8.49986C15.9286 12.6027 12.6029 15.9284 8.5 15.9284ZM8.5 0.499863C4.08171 0.499863 0.5 4.08158 0.5 8.49986C0.5 12.9187 4.08171 16.4999 8.5 16.4999C12.9189 16.4999 16.5 12.9187 16.5 8.49986C16.5 4.08158 12.9189 0.499863 8.5 0.499863Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_0_1222)">
				<rect width="16" height="16" transform="translate(0.5 0.5)" fill={iconColor} />
				<rect x="0.5" y="0.5" width="16" height="16" fill={iconColor} />
			</g>
		</svg>
	);
}
