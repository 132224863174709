import { useFormContext, useWatch } from 'react-hook-form';
import { useResource } from '@kojamo/react-utils';
import { FormGroup, FormGroupProps } from './FormGroup';

export function TextFormField({
	placeholderResourceKey,
	maxLength,
	...formGroupProps
}: Pick<FormGroupProps, 'name' | 'labelResourceKey' | 'required' | 'className'> & {
	placeholderResourceKey?: string;
	maxLength?: number;
}) {
	const { getResourceText } = useResource();
	const {
		register,
		formState: { errors },
	} = useFormContext();

	const error = errors[formGroupProps.name];
	const value = useWatch({ name: formGroupProps.name });
	return (
		<FormGroup {...formGroupProps}>
			<input
				className="form-input-text"
				type="text"
				{...register(formGroupProps.name, { required: formGroupProps.required, maxLength: maxLength })}
				placeholder={placeholderResourceKey && getResourceText(placeholderResourceKey)}
			/>
			{/* Show validation errors for the field */}
			{maxLength && (
				<p
					className={`l-font-footnote l-margin-2-t${
						error && error.type !== 'required' ? ' l-font-error' : ''
					}`}
				>
					{value?.length || 0}/{maxLength}
				</p>
			)}
		</FormGroup>
	);
}
