import { useForm } from 'react-hook-form';
import { MovingContactFormData } from './movingContactFormData';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { useAntiForgeryTokenContext } from '../../boot/AntiForgery';
import { useState } from 'react';
import { SubmitStatus } from '../../Components/submitStatus';
import { ContactApiClient, MovingContactAIM } from '../../api/contactApiClient';

export function useMovingContactForm() {
	const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('initialized');
	const formMethods = useForm<MovingContactFormData>();
	const authState = useAuthenticationContext();
	const { token: antiForgeryToken } = useAntiForgeryTokenContext();

	const onSubmit = formMethods.handleSubmit(async (data: MovingContactFormData) => {
		setSubmitStatus('submitting');
		const apiClient = new ContactApiClient(authState, antiForgeryToken);

		const { pets, masterKey, ...rest } = data;
		const req: MovingContactAIM = {
			...rest,
			petState: pets,
			masterKeyAccessAllowed: masterKey,
		};

		apiClient
			.sendMovingContact(req)
			.then(() => setSubmitStatus('success'))
			.catch(() => setSubmitStatus('error'));
	});

	return { formMethods, onSubmit, submitStatus };
}
