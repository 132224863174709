import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { appStateActions, AppStateActionTypes } from '../Actions/appStateActions';

export type UILanguage = 'fi' | 'en';

export interface UserState {
	uiLanguage: UILanguage;
}

const initialState: UserState = {
	uiLanguage: 'fi',
};

export const userReducer: Reducer<UserState, AppStateActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		case getType(appStateActions.setLanguage.success): {
			return {
				...state,
				uiLanguage: action.payload,
			};
		}
	}
	return state;
};
