import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { ContractValidityAM, ContractApiClient } from '../../api/contractApiClient';
import { AuthenticationState, useAuthenticationContext } from '../../common/authentication/AuthenticationContext';

type ContractState = ContractValidityAM & { contractId: string; hasStarted: boolean; hasEnded: boolean };

interface ContractStateContext {
	contractState?: ContractState;
}

const ContractContext = createContext<ContractStateContext>({});

/**
 * Check contract validity and only render children if contract is in allowed state.
 * Also acts as context provider for child components. Use `useContractContext()` to get contract state
 */
export function ContractContextProvider(props: { children: ReactNode }) {
	const authState = useAuthenticationContext();
	const validity = useContractValidity(authState);

	const contextValue: ContractStateContext = useMemo(() => {
		return {
			contractState: validity
				? {
						...validity,
						hasStarted: validity.startDate <= new Date(),
						hasEnded: validity.endDate ? validity.endDate < new Date() : false,
				  }
				: undefined,
		};
	}, [validity]);

	return <ContractContext.Provider value={contextValue}>{props.children}</ContractContext.Provider>;
}

export function useContractContext(): Readonly<Required<ContractStateContext>> {
	const { contractState } = useContext(ContractContext);

	// RequireValidContract does not render children if contract state has not been fetched, so this should always have a value
	return { contractState: contractState as ContractState };
}

function useContractValidity(authState: AuthenticationState) {
	const [contractState, setContractState] = useState<ContractValidityAM & { contractId: string }>();

	useEffect(() => {
		async function fetchData() {
			if (!authState.userInfo || !authState.contractId) {
				return;
			}

			const apiClient = new ContractApiClient(authState);
			const response = await apiClient.getContractValidity(authState.contractId);
			setContractState({ ...response, contractId: authState.contractId });
		}

		fetchData();
	}, [authState]);

	return contractState;
}
