import { Button, Dialog, IconClose, SpacingContainer, TextArea } from '@kojamo/lumo-dls';
import { Control, FieldError, useForm, UseFormRegister, UseFormSetValue, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useServiceRequestApi } from '../../api/serviceRequestApiClient';
import { useResource } from '@kojamo/react-utils';
import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import '@kojamo/lumo-dls/css/lumo-dls-components.css';
import './AddMessageDialog.scss';
import { useState } from 'react';
import React from 'react';
import { useServiceRequest } from '../useServiceRequest';

export interface ServiceRequestCommentFormData {
	comment: string;
	files: File[] | undefined;
}

export function AddMessageDialog({
	onClose,
	afterSubmit,
	open,
}: {
	onClose: () => void;
	afterSubmit: () => void;
	open: boolean;
}) {
	const {
		handleSubmit,
		register,
		control,
		reset,
		formState: { errors },
		setValue,
	} = useForm<ServiceRequestCommentFormData>();
	const apiClient = useServiceRequestApi();
	const { id } = useParams();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = handleSubmit(async (data: ServiceRequestCommentFormData) => {
		setIsSubmitting(true);
		if (!id) {
			throw new Error('ServiceRequestId missing.');
		}

		const comment = await apiClient?.addComment(id, data.comment, !!data.files);

		if (!comment) {
			throw new Error("Api didn't return the created comment.");
		}

		if (data.files) {
			for (const file of data.files) {
				await apiClient?.addCommentAttachment(id, comment!.tampuuriId, file);
			}
		}

		onClose();
		afterSubmit();
		reset();
		setIsSubmitting(false);
	});

	return (
		<Dialog
			className="add-message-dialog"
			paperClassName="add-message-dialog__paper l-padding-4-t l-padding-6-x l-padding-6-b"
			open={open}
		>
			<SpacingContainer direction="column" align="start">
				<DialogTitle
					onClose={() => {
						onClose();
						reset();
					}}
				/>
				<form onSubmit={onSubmit}>
					<SpacingContainer direction="column" align="start" rowGap={5} width="parent" className="">
						<ResourceText resourceKey="ServiceRequests_Comments_Dialog_Description" />
						<CommentTextArea register={register} control={control} error={errors.comment} maxLength={900} />

						<CommentAttachmentFileInput setValue={setValue} />

						<SpacingContainer
							direction="row"
							directionMobile="column"
							align="end"
							alignMobile="stretch"
							justify="end"
							justifyMobile="center"
							columnGap={2}
							rowGap={5}
							width="parent"
						>
							<Button type="button" variant="secondary" onClick={onClose}>
								<ResourceTextInline resourceKey="ServiceRequests_Comments_Dialog_CancelButton" />
							</Button>
							<Button type="submit" disabled={isSubmitting}>
								<ResourceTextInline resourceKey="ServiceRequests_Comments_Dialog_SubmitButton" />
							</Button>
						</SpacingContainer>
					</SpacingContainer>
				</form>
			</SpacingContainer>
		</Dialog>
	);
}

function DialogTitle({ onClose }: { onClose: () => void }) {
	return (
		<SpacingContainer justify="edges" width="parent" className="">
			<h2 className="l-headline is-s">
				<ResourceText resourceKey={'ServiceRequests_Comments_Dialog_Title'} />
			</h2>
			<CloseButton onClose={onClose} />
		</SpacingContainer>
	);
}

function CloseButton({ onClose }: { onClose: () => void }) {
	return (
		<Button variant="icon" onClick={onClose}>
			<IconClose />
		</Button>
	);
}

function CommentTextArea({
	register,
	control,
	error,
	maxLength,
}: {
	register: UseFormRegister<ServiceRequestCommentFormData>;
	control: Control<ServiceRequestCommentFormData, any>;
	error: FieldError | undefined;
	maxLength: number;
}) {
	const { getResourceText } = useResource();
	const commentValue = useWatch({
		control,
		name: 'comment',
	});

	return (
		<TextArea
			label={getResourceText('ServiceRequests_Comments_Dialog_TextArea_Label')}
			footnote={<CommentTextAreaFootnote commentValue={commentValue} maxLength={maxLength} />}
			{...register('comment', { maxLength, required: true })}
			placeholder={getResourceText('ServiceRequests_Comments_Dialog_TextArea_Placeholder')}
			rows={7}
		/>
	);
}

function CommentTextAreaFootnote({ commentValue, maxLength }: { commentValue?: string; maxLength: number }) {
	const { getResourceText } = useResource();
	return (
		<SpacingContainer justify="edges" columnGap={4}>
			<span>{getResourceText('ServiceRequests_Comments_Dialog_TextArea_Footnote')}</span>
			<span
				className={`add-message-dialog__footnote-char-count l-text-right ${
					(commentValue?.length ?? 0) > maxLength ? 'l-color-error' : ''
				}`}
			>
				{commentValue?.length ?? 0}/{maxLength}
			</span>
		</SpacingContainer>
	);
}

function CommentAttachmentFileInput({ setValue }: { setValue: UseFormSetValue<ServiceRequestCommentFormData> }) {
	const [showFileTypeError, setShowFileTypeError] = useState<boolean>(false);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

	const { allowedFileTypesForCommentAttachments } = useServiceRequest();

	const isValidFileType = (file: File) => {
		return allowedFileTypesForCommentAttachments.indexOf(file.type) >= 0;
	};

	const changeFiles = (files: File[]) => {
		setSelectedFiles(files);
		setValue('files', files);
	};

	const onRemove = (file: File) => {
		changeFiles(selectedFiles.filter((f) => f !== file));
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) {
			setShowFileTypeError(false);
		} else {
			let isValid = true;
			const validFiles = [];
			for (let i = 0; i < e.target.files.length; i++) {
				if (!isValidFileType(e.target.files[i])) {
					isValid = false;
					break;
				}
				validFiles.push(e.target.files[i]);
			}
			setShowFileTypeError(!isValid);
			if (isValid) {
				const newFiles = selectedFiles.concat(validFiles);
				changeFiles(newFiles);
			}
		}
	};

	return (
		<SpacingContainer direction="column" align="start" alignMobile="stretch" width="parent">
			<FileInputField
				name={'files'}
				buttonResourceKey={'ServiceRequests_Comments_Dialog_AddFilesLabel'}
				labelResourceKey={'ServiceRequests_Comments_Dialog_AddFilesDescription'}
				onChange={onChange}
				buttonVariant="secondary"
			/>
			{showFileTypeError && (
				<ResourceText
					resourceKey="ServiceRequests_Comments_Dialog_InvalidFileType"
					className="l-font-error l-margin-2-t"
				/>
			)}
			{selectedFiles.length ? (
				<div className="selected-files l-padding-1-t l-padding-3-b">
					{selectedFiles.map((selectedFile) => (
						<SpacingContainer
							direction="row"
							align="center"
							className="l-padding-1-y l-font-body is-s is-emphasis"
							key={selectedFile.name}
							justify="edges"
						>
							<span className="add-message-dialog__filename">{selectedFile.name}</span>
							<Button onClick={() => onRemove(selectedFile)} variant="text">
								<IconClose color={'#cf3f6c'} />
								<ResourceTextInline resourceKey="FileInputPicker_Remove" />
							</Button>
						</SpacingContainer>
					))}
				</div>
			) : null}
		</SpacingContainer>
	);
}

function FileInputField({
	name,
	footNoteResourceKey,
	buttonResourceKey,
	labelResourceKey,
	onChange,
	buttonVariant = 'primary',
}: {
	name: string;
	footNoteResourceKey?: string;
	buttonResourceKey: string;
	labelResourceKey: string;
	onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
	buttonVariant?: 'primary' | 'secondary'; // TODO: list all variants, fix when moving to DLS
}) {
	const fileInputRef = React.useRef<HTMLInputElement | null>(null);

	return (
		<>
			<span className="l-font-body is-s is-emphasis l-padding-2-b">
				<ResourceTextInline resourceKey={labelResourceKey} />
			</span>
			<label className={`l-button is-${buttonVariant}`} htmlFor={name + '_picker'}>
				<ResourceTextInline resourceKey={buttonResourceKey} />
			</label>
			<input
				type="file"
				id={name + '_picker'}
				multiple
				ref={(e) => {
					fileInputRef.current = e;
				}}
				onChange={onChange}
				style={{ display: 'none' }}
			/>

			{footNoteResourceKey && (
				<ResourceText resourceKey={footNoteResourceKey} className="l-font-footnote l-margin-2-t" />
			)}
		</>
	);
}
