import React from 'react';
import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { SelectButton } from '../../Components/SelectButton';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { TextFormField } from '../../Components/forms/TextFormField';
import { FormProvider, useFormContext, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form';
import { MovingContactFormData } from './movingContactFormData';
import { FormStep } from '../../Components/forms/FormStep';
import { FileInputField } from '../../Components/forms/FileInputField';
import { useResource } from '@kojamo/react-utils';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import { SpacingContainer } from '@kojamo/lumo-dls';
import { GenericServiceRequestConfirmation } from '../../Components/confirmationPage/GenericServiceRequestConfirmation';
import { useMovingContactForm } from './useMovingContactForm';
import { SubmitStatus } from '../../Components/submitStatus';
import { MovingContactAIM } from '../../api/contactApiClient';
import { Checkbox } from '../../Components/forms/Checkbox';
import { RadioGroup } from '../../Components/forms/RadioGroup';
import { getFaultReportUrl, getPetOptions, OwnApartmentFaultType } from '../../common/faultReportForm';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';
import { useNavigate } from 'react-router-dom';
import { useContractContext } from '../../Components/contractValidity/ContractContextProvider';
import { CoTenantPrivacyNoticeServiceRequest } from '../../Components/CoTenantPrivacyNotices';
import { appRoutes } from '../../routes';
import { useAppLanguage } from '../../common/localization/useAppLanguage';
import { ContactFormTelemetryType } from '../../common/telemetryGA4';

export function MovingContact() {
	const { formMethods, onSubmit, submitStatus } = useMovingContactForm();
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_Contact_MovingContact" />
			</ContactBreadCrumbs>
			{submitStatus === 'success' ? (
				<GenericServiceRequestConfirmation
					isAuthenticated
					headingResourceKey="ContactForms_MovingContact_Success_Heading"
					stepTitleResourceKey="ContactForms_MovingContact_Success_ReportSent"
					stepContentResourceKey="ContactForms_MovingContact_Success_ToBeProcessed"
				/>
			) : (
				<Wizard formMethods={formMethods} onSubmit={onSubmit} submitStatus={submitStatus} />
			)}
		</>
	);
}

function Wizard({
	formMethods,
	onSubmit,
	submitStatus,
}: {
	formMethods: UseFormReturn<MovingContactFormData>;
	onSubmit: ReturnType<UseFormHandleSubmit<MovingContactFormData>>;
	submitStatus: SubmitStatus;
}) {
	const [selectedTopic, setSelectedTopic] = React.useState<MovingContactAIM['topic']>();

	return (
		<RequireValidContract showAfterContractEndingMonths={6}>
			<ContactFormSection
				headingResourceKey={'ContactForms_MovingContact_Heading'}
				ingressTextKey="ContactForms_MovingContact_Ingress"
				showContactInformation
			>
				<FormProvider {...formMethods}>
					<form onSubmit={onSubmit}>
						<Step1
							onContinue={(t) => setSelectedTopic(t)}
							isOpen={!selectedTopic}
							selectedTopic={selectedTopic}
							onOpenRequested={() => setSelectedTopic(undefined)}
						/>
						{/* If topic was already selected (once), keep rendering form so that field inputs are not lost when changing topic */}
						{selectedTopic && (
							<Step2
								isOpen={!!selectedTopic}
								onOpenRequested={() => {}}
								selectedTopic={selectedTopic}
								submitStatus={submitStatus}
							/>
						)}
					</form>
				</FormProvider>
			</ContactFormSection>
		</RequireValidContract>
	);
}

function Step1(props: {
	isOpen: boolean;
	onOpenRequested: () => void;
	selectedTopic?: MovingContactAIM['topic'];
	onContinue: (selection: MovingContactAIM['topic']) => void;
}) {
	const { setValue } = useFormContext();
	const navigate = useNavigate();
	const { contractState } = useContractContext();
	const { language } = useAppLanguage();
	const onTopicSelected = (topic: MovingContactAIM['topic']) => {
		setValue('topic', topic);
		props.onContinue(topic);
	};

	const onKeyContactClick = () => {
		window.postMessage({ msg: 'navigated_to', url: 'yhteydenotto/avaimet' });
		navigate(`/${appRoutes.keyContact}?contractId=${contractState.contractId}`);
	};

	const onNameChangeContactClick = () => {
		window.postMessage({ msg: 'navigated_to', url: 'vikailmoitus' });
		navigate(getFaultReportUrl(contractState.contractId, OwnApartmentFaultType.ApartmentNameChange, language));
	};

	return (
		<FormStep
			number={1}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_MovingContact_Step1_Heading" />
					{!props.isOpen && props.selectedTopic && (
						<>
							:{' '}
							<ResourceTextInline
								resourceKey={'ContactForms_MovingContact_Step1_' + props.selectedTopic}
							/>
						</>
					)}
				</>
			}
			isOpen={props.isOpen}
			onOpenRequested={props.onOpenRequested}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<ResourceText
					resourceKey={'ContactForms_MovingContact_Ingress_Step1'}
					optional={true}
					className="l-font-body"
				/>
				<div className="button-choices">
					{!contractState.hasEnded && (
						<>
							<SelectButton onClick={onNameChangeContactClick}>
								<ResourceTextInline resourceKey="ContactForms_MovingContact_Step1_NameChange" />
							</SelectButton>

							<SelectButton onClick={onKeyContactClick}>
								<ResourceTextInline resourceKey="ContactForms_MovingContact_Step1_Keys" />
							</SelectButton>
						</>
					)}
					<SelectButton onClick={() => onTopicSelected('Other')}>
						<ResourceTextInline resourceKey="ContactForms_MovingContact_Step1_Other" />
					</SelectButton>
				</div>
			</SpacingContainer>
		</FormStep>
	);
}

function Step2(props: {
	isOpen: boolean;
	onOpenRequested: () => void;
	selectedTopic: MovingContactAIM['topic'];
	submitStatus: SubmitStatus;
}) {
	const { getResourceText } = useResource();
	const requiredText = getResourceText('ContactForms_RequiredField_Error');
	return (
		<FormStep
			number={2}
			heading={<ResourceTextInline resourceKey={'ContactForms_MovingContact_Heading_' + props.selectedTopic} />}
			isOpen={props.isOpen}
			onOpenRequested={props.onOpenRequested}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<ResourceText
					resourceKey={'ContactForms_MovingContact_Ingress_' + props.selectedTopic}
					optional={true}
				/>
				<Checkbox
					name="masterKey"
					labelResourceKey="ContactForms_MasterKey_Label"
					optionResourceKey="ContactForms_MasterKey_Option"
				/>
				<RadioGroup
					name="pets"
					labelResourceKey="ContactForms_Pets_Label"
					options={getPetOptions()}
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>

				<TextFormField
					name="subject"
					labelResourceKey="ContactForms_Subject_Label"
					placeholderResourceKey="ContactForms_Subject_Placeholder"
					required={requiredText}
					maxLength={100}
				/>
				<TextAreaField
					name="message"
					labelResourceKey="ContactForms_Message_Label"
					placeholderResourceKey="ContactForms_Message_Placeholder"
					footnoteResourceKey="ContactForms_MovingContact_Message_Footnote"
					required={requiredText}
					maxLength={2000}
				/>
				<FileInputField
					name="files"
					labelResourceKey="ContactForms_Attachment_Label"
					footNoteResourceKey="ContactForms_Attachment_Footnote"
					fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
				/>
				<CoTenantPrivacyNoticeServiceRequest />
			</SpacingContainer>
			<SubmitButtonAndProgress
				submitStatus={props.submitStatus}
				formTypeForTelemetry={ContactFormTelemetryType.MovingContact}
			/>
		</FormStep>
	);
}
