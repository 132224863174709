import {
	allCommonAndOutdoorAreaFaultTypes,
	allFaultAreaTypes,
	allOwnApartmentFaultAndServiceWorkTypes,
	CommonAndOutdoorAreaFaultType,
	FaultAreaType,
	FaultReportFormData,
	getCommonAndOutdoorAreaFaultTypeResourceKey,
	getFaultAreaTypeResourceKey,
	getFaultToReportDescriptionResourceKey,
	getFaultToReportResourceKey,
	getOwnApartmentFaultAndServiceWorkTypeResourceKey,
	getOwnApartmentFaultTypeResourceKey,
	getPetOptions,
	isOwnApartmentFaultAndServiceWorkType,
	OwnApartmentFaultAndServiceWorkType,
	OwnApartmentFaultType,
	ServiceRequestTargetScope,
	VisibleSection,
} from '../../common/faultReportForm';
import { FormStep } from '../../Components/forms/FormStep';
import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { SelectButton } from '../../Components/SelectButton';
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { useResource } from '@kojamo/react-utils';
import { Checkbox } from '../../Components/forms/Checkbox';
import { RadioGroup } from '../../Components/forms/RadioGroup';
import { TextFormField } from '../../Components/forms/TextFormField';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { FileInputField } from '../../Components/forms/FileInputField';
import { SpacingContainer } from '@kojamo/lumo-dls';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { GenericServiceRequestConfirmation } from '../../Components/confirmationPage/GenericServiceRequestConfirmation';

import { ContactFormTelemetryType } from '../../common/telemetryGA4';

export function FaultArea({
	stepNumber,
	isOpen,
	onOpenRequested,
	setOpenSection,
	faultArea,
}: {
	stepNumber: number;
	isOpen: boolean;
	onOpenRequested: () => void;
	setOpenSection: (openSection: FaultAreaType) => void;
	faultArea?: FaultAreaType;
}) {
	return (
		<FormStep
			number={stepNumber}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_FaultReport_StepFaultArea_Heading" />
					{!isOpen && faultArea && (
						<>
							: <ResourceTextInline resourceKey={getFaultAreaTypeResourceKey(faultArea)} />
						</>
					)}
				</>
			}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<div className="button-choices">
				{allFaultAreaTypes.map((area, index) => (
					<SelectButton key={index} onClick={() => setOpenSection(area)}>
						<ResourceTextInline resourceKey={getFaultAreaTypeResourceKey(area)} />
					</SelectButton>
				))}
			</div>
		</FormStep>
	);
}

export function OwnApartmentFault({
	stepNumber,
	isOpen,
	onOpenRequested,
	setOpenSection,
	initialValue,
}: {
	stepNumber: number;
	isOpen: boolean;
	onOpenRequested: () => void;
	setOpenSection: (openSection: VisibleSection) => void;
	initialValue?: OwnApartmentFaultType;
}) {
	const { setValue } = useFormContext<FaultReportFormData>();
	const [headingValue, setHeadingValue] = useState<OwnApartmentFaultType | undefined>(initialValue);

	return (
		<FormStep
			number={stepNumber}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_FaultReport_StepFault_Heading" />
					{!isOpen && headingValue && (
						<>
							: <ResourceTextInline resourceKey={getOwnApartmentFaultTypeResourceKey(headingValue)} />
						</>
					)}
				</>
			}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<div className="button-choices">
				<SelectButton
					onClick={() => {
						setHeadingValue(OwnApartmentFaultType.FaultAndServiceWork);
						setOpenSection(OwnApartmentFaultType.FaultAndServiceWork);
					}}
				>
					<ResourceTextInline
						resourceKey={getOwnApartmentFaultTypeResourceKey(OwnApartmentFaultType.FaultAndServiceWork)}
					/>
				</SelectButton>
				<SelectButton
					onClick={() => {
						setHeadingValue(OwnApartmentFaultType.ApartmentNameChange);
						setValue('fault', OwnApartmentFaultType.ApartmentNameChange);
						setOpenSection('Confirmation');
					}}
				>
					<ResourceTextInline
						resourceKey={getOwnApartmentFaultTypeResourceKey(OwnApartmentFaultType.ApartmentNameChange)}
					/>
				</SelectButton>
			</div>
		</FormStep>
	);
}

export function OwnApartmentFaultAndServiceWork({
	stepNumber,
	isOpen,
	onOpenRequested,
	setOpenSection,
	initialValue,
}: {
	stepNumber: number;
	isOpen: boolean;
	onOpenRequested: () => void;
	setOpenSection: (openSection: VisibleSection) => void;
	initialValue?: OwnApartmentFaultAndServiceWorkType;
}) {
	const { setValue } = useFormContext<FaultReportFormData>();
	const [headingValue, setHeadingValue] = useState<OwnApartmentFaultAndServiceWorkType | undefined>(initialValue);
	const selectValue = useCallback(
		(workType: OwnApartmentFaultAndServiceWorkType) => {
			setValue('fault', workType);
			setHeadingValue(workType);
			setOpenSection('Confirmation');
		},
		[setValue, setHeadingValue, setOpenSection],
	);

	return (
		<FormStep
			number={stepNumber}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_FaultReport_StepOwnApartmentFaultAndServiceWork_Heading" />
					{!isOpen && headingValue && (
						<>
							:{' '}
							<ResourceTextInline
								resourceKey={getOwnApartmentFaultAndServiceWorkTypeResourceKey(headingValue)}
							/>
						</>
					)}
				</>
			}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<div className="button-choices">
				{allOwnApartmentFaultAndServiceWorkTypes.map((workType, index) => (
					<SelectButton key={index} onClick={() => selectValue(workType)}>
						<ResourceTextInline resourceKey={getOwnApartmentFaultAndServiceWorkTypeResourceKey(workType)} />
					</SelectButton>
				))}
			</div>
		</FormStep>
	);
}

export function CommonAndOutdoorAreaFault({
	stepNumber,
	isOpen,
	onOpenRequested,
	setOpenSection,
	initialValue,
}: {
	stepNumber: number;
	isOpen: boolean;
	onOpenRequested: () => void;
	setOpenSection: (openSection: VisibleSection) => void;
	initialValue?: CommonAndOutdoorAreaFaultType;
}) {
	const { setValue } = useFormContext<FaultReportFormData>();
	const [headingValue, setHeadingValue] = useState<CommonAndOutdoorAreaFaultType | undefined>(initialValue);
	const selectValue = useCallback(
		(type: CommonAndOutdoorAreaFaultType) => {
			setValue('fault', type);
			setHeadingValue(type);
			setOpenSection('Confirmation');
		},
		[setValue, setHeadingValue, setOpenSection],
	);

	return (
		<FormStep
			number={stepNumber}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_FaultReport_StepFault_Heading" />
					{!isOpen && headingValue && (
						<>
							:{' '}
							<ResourceTextInline
								resourceKey={getCommonAndOutdoorAreaFaultTypeResourceKey(headingValue)}
							/>
						</>
					)}
				</>
			}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<div className="button-choices">
				{allCommonAndOutdoorAreaFaultTypes.map((type, index) => (
					<SelectButton key={index} onClick={() => selectValue(type)}>
						<ResourceTextInline resourceKey={getCommonAndOutdoorAreaFaultTypeResourceKey(type)} />
					</SelectButton>
				))}
			</div>
		</FormStep>
	);
}

export function AdditionalInformationAndConfirmation({
	stepNumber,
	isOpen,
	onOpenRequested,
	isSubmitting,
	error,
	isAuthenticated,
}: {
	stepNumber: number;
	isOpen: boolean;
	onOpenRequested: () => void;
	isSubmitting: boolean;
	error: Error | undefined;
	isAuthenticated: boolean;
}) {
	const { getResourceText } = useResource();
	const { getValues } = useFormContext<FaultReportFormData>();
	const fault = getValues('fault') as ServiceRequestTargetScope;
	const showOwnApartmentRelatedFields = isOwnApartmentFaultAndServiceWorkType(fault);

	return (
		<FormStep
			number={stepNumber}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_FaultReport_StepAdditionalInformationAndConfirmation_Heading" />
				</>
			}
			isOpen={isOpen}
			onOpenRequested={onOpenRequested}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<div>
					<h4 className="l-font-body is-m is-contrast">
						<ResourceTextInline resourceKey={getFaultToReportResourceKey(fault)} />
					</h4>
					<ResourceText
						resourceKey={getFaultToReportDescriptionResourceKey(fault)}
						linkOpenMode="blank"
						className="l-font-body"
					/>
				</div>
				{showOwnApartmentRelatedFields && (
					<>
						<Checkbox
							name="masterKey"
							labelResourceKey="ContactForms_MasterKey_Label"
							optionResourceKey="ContactForms_MasterKey_Option"
						/>
						<RadioGroup
							name="pets"
							labelResourceKey="ContactForms_Pets_Label"
							options={getPetOptions()}
							required={getResourceText('ContactForms_RequiredField_Error')}
						/>
					</>
				)}
				<TextFormField
					name="subject"
					labelResourceKey="ContactForms_Subject_Label"
					placeholderResourceKey="ContactForms_Subject_Placeholder"
					required={getResourceText('ContactForms_RequiredField_Error')}
					maxLength={100}
				/>
				<TextAreaField
					name="message"
					labelResourceKey="ContactForms_Message_Label"
					placeholderResourceKey="ContactForms_Message_Placeholder"
					footnoteResourceKey="ContactForms_FaultReport_StepAdditionalInformationAndConfirmation_Message_Footnote"
					maxLength={2000}
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>
				<FileInputField
					name="files"
					labelResourceKey="ContactForms_Attachment_Label"
					footNoteResourceKey="ContactForms_Attachment_Footnote"
					fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
				/>
			</SpacingContainer>
			<ResourceText
				resourceKey="ContactForms_RequiredFields_Instruction"
				className="l-margin-4-t l-font-footnote"
			/>
			{isAuthenticated ? (
				<ResourceText
					resourceKey="ContactForms_FaultReport_StepAdditionalInformationAndConfirmation_CoTenantInforming"
					className="l-font-body is-xl l-color-blue is-dark l-margin-4-t"
				/>
			) : null}
			<SubmitButtonAndProgress
				isSubmitting={isSubmitting}
				error={error}
				formTypeForTelemetry={ContactFormTelemetryType.ServiceRequest}
			/>
		</FormStep>
	);
}

export function FaultReportConfirmation({ isAuthenticated }: { isAuthenticated: boolean }) {
	return (
		<GenericServiceRequestConfirmation
			isAuthenticated={isAuthenticated}
			headingResourceKey="ContactForms_FaultReport_Success_Heading"
			stepTitleResourceKey="ContactForms_FaultReport_Success_ReportSent"
			stepContentResourceKey="ContactForms_FaultReport_Success_ToBeProcessed"
		/>
	);
}
