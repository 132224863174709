import { ReactNode } from 'react';

export function VisibilityContainer({
	showOnlyIn,
	children,
}: {
	showOnlyIn: 'desktop' | 'mobile';
	children: ReactNode;
}) {
	return <div className={`l-visible-${showOnlyIn}-only`}>{children}</div>;
}
