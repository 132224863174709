import { ApiClientBase } from './apiClientBase';

export type ParkingSpaceAvailabilityAM = ExternalParkingSpaceAvailableAM | ExternalParkingSpaceUnavailableAM;

interface ParkingSpaceAvailabilityBaseAM {
	hasInternalParking: boolean;
}

interface ExternalParkingSpaceAvailableAM extends ParkingSpaceAvailabilityBaseAM {
	hasExternalParking: true;
	externalParkingSpaceManagementDescription: string;
}

interface ExternalParkingSpaceUnavailableAM extends ParkingSpaceAvailabilityBaseAM {
	hasExternalParking: false;
}

export class ParkingSpaceApiClient extends ApiClientBase {
	async getParkingSpaceAvailability() {
		// contractId param is added automatically
		return this.fetchJson<ParkingSpaceAvailabilityAM>(`api/parking-spaces/availability`);
	}
}
