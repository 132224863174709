export function IconLinkExternal() {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.2299 9.155C22.2426 9.16495 22.25 9.18016 22.25 9.19625C22.25 9.21234 22.2426 9.22755 22.2299 9.2375L17.9469 12.6425V11C17.9492 10.9848 17.9446 10.9695 17.9344 10.9579C17.9242 10.9464 17.9095 10.9398 17.8941 10.94C16.827 10.9856 15.7795 11.2405 14.8122 11.69C14.5524 11.8042 14.3002 11.9345 14.0568 12.08C12.7317 12.7232 11.7008 13.8405 11.1713 15.2075C11.1713 15.2365 11.1477 15.26 11.1185 15.26C11.0893 15.26 11.0656 15.2365 11.0656 15.2075C11.1128 13.3687 11.6809 11.5804 12.7047 10.0475C13.0261 9.60757 13.4045 9.21166 13.8302 8.87C14.0992 8.64863 14.3903 8.45512 14.6989 8.2925C15.693 7.78346 16.7983 7.52585 17.9167 7.5425C17.9445 7.53923 17.9663 7.51753 17.9696 7.49V5.75L22.2299 9.155Z"
				fill="#CF3F6C"
			/>
			<path
				d="M17.8412 14.75V18.5H5.75536V7.25H13.6414L13.8604 7.115C14.4964 6.82755 15.1664 6.62092 15.8546 6.5H5V19.25H18.5965V14L17.8412 14.75Z"
				fill="#CF3F6C"
			/>
		</svg>
	);
}
