import { Link, useParams } from 'react-router-dom';
import FileSaver from 'file-saver';
import { useServiceRequest } from '../useServiceRequest';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { ServiceRequestBase } from '../ServiceRequests/ServiceRequestBase';
import {
	BusinessArea,
	IAttachment,
	ServiceRequestCommentAM,
	ServiceRequestCommentAMSpecifier,
	ServiceRequestContactType,
	ServiceRequestStatusChangeAM,
	ServiceRequestWithBusinessAreaAM,
	StatusType,
	useServiceRequestApi,
} from '../../api/serviceRequestApiClient';
import { ResourceTextInline } from '../../common/localization/ResourceText';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { Button, DownloadButton, IconCustomerService, IconProfile, SpacingContainer } from '@kojamo/lumo-dls';
import format from 'date-fns/format';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { BreadCrumbs } from '../../Components/BreadCrumbs';
import { useSettings } from '../../boot/Settings';
import { appRoutes } from '../../routes';
import './ServiceRequest.scss';
import { AddMessageDialog } from './AddMessageDialog';
import {
	FaultAreaType,
	OwnApartmentFaultAndServiceWorkType,
	CommonAndOutdoorAreaFaultType,
} from '../../common/faultReportForm';
import { useAppLanguage } from '../../common/localization/useAppLanguage';
import { localizedLinks } from '../../common/localization/localizedLinks';

export function ServiceRequest() {
	const { id } = useParams();
	const { loading, serviceRequest, reload } = useServiceRequest(id);

	return !loading && serviceRequest ? (
		<ServiceRequestContent serviceRequest={serviceRequest} reload={reload} />
	) : (
		<LoadingSpinner />
	);
}

enum MessageType {
	Inbound = 'Inbound',
	Outbound = 'Outbound',
}
interface ServiceRequestMessage {
	type: MessageType;
	timestamp: Date;
	title: ReactNode;
	content: ReactNode;
	attachments?: IAttachment[];
}

function ServiceRequestContent({
	serviceRequest,
	reload,
}: {
	serviceRequest: ServiceRequestWithBusinessAreaAM;
	reload: () => void;
}) {
	const { dateFnsOptions, language } = useAppLanguage();
	const settings = useSettings();
	const maintenanceInformationHref = `${settings?.shellBaseUrl}${localizedLinks.maintenanceInformation[language]}`;

	const { title, description, reporter, attachments, statusChanges, comments, ...rest } = serviceRequest;

	const statusChangeMessages =
		statusChanges.length > 0
			? statusChanges.map(statusChangeToMessage)
			: [getDefaultStatusChangeMessage(rest.creationTime, rest.tampuuriId)];

	const commentMessages =
		comments?.map((comment) =>
			commentToMessage(
				comment,
				attachments.filter((attachment) => attachment.tampuuriCommentId === comment.tampuuriId),
			),
		) ?? [];

	return (
		<>
			<BreadCrumbs>
				<a href={maintenanceInformationHref} target="_parent">
					<ResourceTextInline resourceKey="Breadcrumbs_FaultReport_Root" />
				</a>
				<Link to={`/${appRoutes.myServiceRequests}`}>
					<ResourceTextInline resourceKey="Breadcrumbs_ServiceRequests_Title" />
				</Link>
				<span>{format(rest.creationTime, 'Pp', dateFnsOptions)}</span>
			</BreadCrumbs>
			<SpacingContainer direction="column" rowGap={5} className="l-margin-5-y">
				<ServiceRequestBase {...rest} detailsFirst={true}>
					<div>
						<h5 className="l-font-body is-m is-contrast l-color-blue is-dark">{title}</h5>
						<p className="description l-font-body l-margin-3-t is-fullwidth">{description}</p>
					</div>
					<div>
						<h5 className="l-font-body is-m is-contrast l-color-blue is-dark">
							<ResourceTextInline resourceKey="ServiceRequests_Sender" />
						</h5>
						<div className="l-margin-3-t">
							<p className="l-font-body l-margin-1-t">{reporter.name}</p>
							<p className="l-font-body l-margin-1-t">{reporter.email}</p>
							<p className="l-font-body l-margin-1-t">{reporter.phone}</p>
						</div>
					</div>
					<Attachments
						attachments={attachments.filter((a) => !a.tampuuriCommentId)}
						serviceRequestId={rest.id}
					/>
				</ServiceRequestBase>
				<MessageArea
					messages={[...statusChangeMessages, ...commentMessages]}
					serviceRequest={serviceRequest}
					afterSubmit={() => {
						reload();
					}}
				/>
			</SpacingContainer>
		</>
	);
}

function Attachments({ attachments, serviceRequestId }: { attachments: IAttachment[]; serviceRequestId: string }) {
	const { contractId } = useAuthenticationContext(false);
	return (
		<div>
			{attachments.map((attachment, index) => (
				<DownloadableAttachment
					key={index}
					{...attachment}
					serviceRequestId={serviceRequestId}
					contractId={contractId}
				/>
			))}
		</div>
	);
}

function statusChangeToMessage(statusChange: ServiceRequestStatusChangeAM): ServiceRequestMessage {
	return {
		timestamp: statusChange.changeTime,
		title: <StatusChangeTitle statusChange={statusChange.newStatus} />,
		content:
			statusChange.communicationContent && statusChange.communicationContent.length > 0 ? (
				statusChange.communicationContent
			) : (
				<StatusChangeContent statusChange={statusChange.newStatus} />
			),
		type: MessageType.Inbound,
	};
}

function commentToMessage(comment: ServiceRequestCommentAM, attachments: IAttachment[]): ServiceRequestMessage {
	return {
		timestamp: comment.createdAt,
		title: null,
		content: comment.comment,
		type:
			comment.specifier === ServiceRequestCommentAMSpecifier.Resident
				? MessageType.Outbound
				: MessageType.Inbound,
		attachments: attachments,
	};
}

function DownloadableAttachment({
	id,
	fileName,
	serviceRequestId,
	tampuuriCommentId,
}: IAttachment & { serviceRequestId: string; contractId?: string }) {
	const [name, extension] = fileName.split('.');
	const serviceRequestApi = useServiceRequestApi();

	async function saveAsAttachment() {
		if (!serviceRequestApi) {
			throw new Error('ServiceRequest: service request API is undefined.');
		}

		const attachmentBlob = !tampuuriCommentId
			? await serviceRequestApi.downloadAttachmentBlob(serviceRequestId, id)
			: await serviceRequestApi.downloadCommentAttachmentBlob(serviceRequestId, id, tampuuriCommentId!);

		if (!attachmentBlob) {
			throw new Error('ServiceRequest: could not fetch attachment.');
		}

		FileSaver.saveAs(attachmentBlob, fileName);
	}

	return <DownloadButton fileName={name} extension={extension} onClick={saveAsAttachment} />;
}

function MessageArea({
	messages,
	afterSubmit,
	serviceRequest,
}: {
	messages: ServiceRequestMessage[];
	afterSubmit: () => void;
	serviceRequest: ServiceRequestWithBusinessAreaAM;
}) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const shouldShowAddButton =
		formTypeAllowsAddingComments(serviceRequest) &&
		[StatusType.New, StatusType.Received].includes(serviceRequest.status) &&
		serviceRequest.tampuuriId;

	return (
		<>
			{messages
				.sort((messageA, messageB) => messageA.timestamp.getTime() - messageB.timestamp.getTime())
				.map((message, index) => (
					<Message
						key={index}
						message={message}
						serviceRequestId={serviceRequest.id}
						isLatestMessage={index === messages.length - 1}
					/>
				))}
			<SpacingContainer
				className="ServiceRequestStatusChange"
				columnGap={5}
				columnGapMobile={2}
				align="end"
				justify="end"
			>
				{shouldShowAddButton && (
					<>
						<Button onClick={() => setDialogOpen(true)}>
							<ResourceTextInline resourceKey={'ServiceRequests_Comments_AddComment'} />
						</Button>

						<OutboundMessageIcon />
					</>
				)}
			</SpacingContainer>
			<AddMessageDialog open={dialogOpen} onClose={() => setDialogOpen(false)} afterSubmit={afterSubmit} />
		</>
	);
}

function formTypeAllowsAddingComments(serviceRequest: ServiceRequestWithBusinessAreaAM) {
	const { targetScope, contactType, businessArea } = serviceRequest;
	// 1. Vikailmoitus - koskee asuntoa - Viat ja huoltotyöt - Rikkinäinen/viallinen laite
	if (
		targetScope === OwnApartmentFaultAndServiceWorkType.ApartmentBrokenDevice &&
		contactType === ServiceRequestContactType.ServiceRequest
	) {
		return true;
	}

	// 2. Vikailmoitus - koskee asuntoa - Viat ja huoltotyöt - Lämpötila ja sisäilma (vain tulosalue 2 osalta)
	if (
		targetScope === OwnApartmentFaultAndServiceWorkType.ApartmentTemperatureAndIndoorAir &&
		contactType === ServiceRequestContactType.ServiceRequest &&
		businessArea === BusinessArea.RestOfFinland
	) {
		return true;
	}

	// 3. Vikailmoitus - koskee yhteis- tai ulkotiloja - Siivous
	if (
		targetScope === CommonAndOutdoorAreaFaultType.CommonFacilitiesCleaning &&
		contactType === ServiceRequestContactType.ServiceRequest
	) {
		return true;
	}

	// 4. Vikailmoitus - koskee yhteis- tai ulkotiloja - Piha-alueen ylläpito
	if (
		targetScope === CommonAndOutdoorAreaFaultType.YardMaintenance &&
		contactType === ServiceRequestContactType.ServiceRequest
	) {
		return true;
	}

	// 5. Häiriöilmoitus
	if (targetScope === FaultAreaType.RentalObject && contactType === ServiceRequestContactType.DisturbanceReport) {
		return true;
	}

	// 6. Talon isännöinti
	if (targetScope === FaultAreaType.RentalObject && contactType === ServiceRequestContactType.CommonFacilities) {
		return true;
	}

	// 7. Sisään- tai ulosmuutto - Muuta muuttoon liittyvää asiaa
	if (targetScope === FaultAreaType.RentalObject && contactType === ServiceRequestContactType.MovingOtherTopic) {
		return true;
	}

	// 8. Asiakkaan puolesta luotu ilmoitus
	if (contactType === ServiceRequestContactType.RealEstateManagerContact) {
		return true;
	}

	return false;
}

function getDefaultStatusChangeMessage(createdAt: Date, tampuuriId?: string): ServiceRequestMessage {
	return {
		timestamp: createdAt,
		title: (
			<ResourceTextInline
				resourceKey={
					tampuuriId
						? 'ServiceRequests_StatusChange_TampuuriDefaultStatus_Title'
						: 'ServiceRequests_StatusChange_CustomerService_Title'
				}
			/>
		),
		content: (
			<ResourceTextInline
				resourceKey={
					tampuuriId
						? 'ServiceRequests_StatusChange_TampuuriDefaultStatus_Content'
						: 'ServiceRequests_StatusChange_CustomerService_Content'
				}
			/>
		),
		type: MessageType.Inbound,
	};
}

function Message({
	message,
	serviceRequestId,
	isLatestMessage,
}: {
	message: ServiceRequestMessage;
	serviceRequestId: string;
	isLatestMessage: boolean;
}) {
	const { fileTypeAllowsPreview } = useServiceRequest(serviceRequestId);
	const latestMessageRef = useRef<null | HTMLDivElement>(null);
	const { dateFnsOptions } = useAppLanguage();
	useEffect(() => {
		if (isLatestMessage && latestMessageRef && latestMessageRef.current) {
			const y = latestMessageRef.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y,
				behavior: 'smooth',
			});
		}
	});

	return (
		<div ref={isLatestMessage ? latestMessageRef : null}>
			<SpacingContainer className="ServiceRequestStatusChange" columnGap={5} columnGapMobile={2} align="start">
				{message.type === MessageType.Inbound && <InboundMessageIcon />}
				<SpacingContainer
					className="content l-padding-5-t l-padding-5-r l-padding-7-b l-padding-7-l l-padding-3-mobile l-background-color-white"
					direction="column"
					rowGap={4}
					rowGapMobile={3}
				>
					<SpacingContainer>
						<p className="l-margin-0 l-font-body l-color-grey is-dark">
							{format(message.timestamp, 'Pp', dateFnsOptions)}
						</p>
					</SpacingContainer>
					<h5 className="l-margin-0 l-font-body is-l is-contrast l-color-blue is-dark">{message.title}</h5>
					<p className="l-margin-0 l-font-body is-m content-text is-fullwidth">{message.content}</p>
					{message.attachments?.map((attachment) =>
						!fileTypeAllowsPreview(attachment.contentType) ? (
							<DownloadableAttachment
								key={attachment.id}
								{...attachment}
								serviceRequestId={serviceRequestId}
							/>
						) : (
							<AttachmentPreview
								key={attachment.id}
								attachment={attachment}
								serviceRequestId={serviceRequestId}
							/>
						),
					)}
				</SpacingContainer>
				{message.type === MessageType.Outbound && <OutboundMessageIcon />}
			</SpacingContainer>
		</div>
	);
}

export function AttachmentPreview({
	attachment,
	serviceRequestId,
}: {
	attachment: IAttachment;
	serviceRequestId: string;
}) {
	const settings = useSettings();

	if (!settings?.cdnRootUrl) {
		return null;
	}
	const attachmentUrl = `${settings.cdnRootUrl}/images/service-requests/${serviceRequestId}/${attachment.tampuuriCommentId}/${attachment.id}`;

	return (
		<a href={attachmentUrl} target="_blank" rel="noreferrer">
			<img src={attachmentUrl} alt={attachment.fileName} className="service-request-attachment-preview" />
		</a>
	);
}

function StatusChangeTitle({ statusChange }: { statusChange: StatusType }) {
	switch (statusChange) {
		case StatusType.Received:
			return <ResourceTextInline resourceKey="ServiceRequests_StatusChange_Received_Title" />;
		case StatusType.Done:
			return <ResourceTextInline resourceKey="ServiceRequests_StatusChange_Done_Title" />;
		default:
			return null;
	}
}

function StatusChangeContent({ statusChange }: { statusChange: StatusType }) {
	switch (statusChange) {
		case StatusType.Received:
			return <ResourceTextInline resourceKey="ServiceRequests_StatusChange_Received_Content" />;
		case StatusType.Done:
			return <ResourceTextInline resourceKey="ServiceRequests_StatusChange_Done_Content" />;
		default:
			return null;
	}
}

function InboundMessageIcon() {
	return (
		<div className="icon l-padding-3 l-background-color-white">
			<IconCustomerService />
		</div>
	);
}
function OutboundMessageIcon() {
	return (
		<div className="icon l-padding-3 l-background-color-white">
			<IconProfile className="l-svg-fill-blue" />
		</div>
	);
}
