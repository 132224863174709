import React from 'react';
import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { SelectButton } from '../../Components/SelectButton';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { TextFormField } from '../../Components/forms/TextFormField';
import { FormProvider, useFormContext, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form';
import { KeyContactFormData } from './KeyContactFormData';
import { FormStep } from '../../Components/forms/FormStep';
import { CustomerServiceMessageConfirmation } from '../../Components/confirmationPage/CustomerServiceMessageConfirmation';
import { FileInputField } from '../../Components/forms/FileInputField';
import { useResource } from '@kojamo/react-utils';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import { SpacingContainer } from '@kojamo/lumo-dls';
import { GenericServiceRequestConfirmation } from '../../Components/confirmationPage/GenericServiceRequestConfirmation';
import { useKeyContactForm } from './useKeyContactForm';
import { SubmitStatus } from '../../Components/submitStatus';
import { KeyContactTopic } from '../../api/contactApiClient';
import { Checkbox } from '../../Components/forms/Checkbox';
import { RadioGroup } from '../../Components/forms/RadioGroup';
import { getPetOptions } from '../../common/faultReportForm';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';
import {
	CoTenantPrivacyNoticeCustomerService,
	CoTenantPrivacyNoticeServiceRequest,
} from '../../Components/CoTenantPrivacyNotices';

import { ContactFormTelemetryType } from '../../common/telemetryGA4';

export function KeyContact() {
	const { formMethods, onSubmit, submitStatus, selectedTopic } = useKeyContactForm();
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_Contact_KeyContact" />
			</ContactBreadCrumbs>
			{submitStatus === 'success' ? (
				<Success topic={selectedTopic!} />
			) : (
				<Wizard formMethods={formMethods} onSubmit={onSubmit} submitStatus={submitStatus} />
			)}
		</>
	);
}

function Wizard({
	formMethods,
	onSubmit,
	submitStatus,
}: {
	formMethods: UseFormReturn<KeyContactFormData>;
	onSubmit: ReturnType<UseFormHandleSubmit<KeyContactFormData>>;
	submitStatus: SubmitStatus;
}) {
	const [selectedTopic, setSelectedTopic] = React.useState<KeyContactTopic>();

	return (
		<RequireValidContract>
			<ContactFormSection
				headingResourceKey={'ContactForms_KeyContact_Heading'}
				ingressTextKey="ContactForms_KeyContact_Ingress"
				showContactInformation
			>
				<FormProvider {...formMethods}>
					<form onSubmit={onSubmit}>
						<Step1
							onContinue={(t) => setSelectedTopic(t)}
							isOpen={!selectedTopic}
							selectedTopic={selectedTopic}
							onOpenRequested={() => setSelectedTopic(undefined)}
						/>
						{/* If topic was already selected (once), keep rendering form so that field inputs are not lost when changing topic */}
						{selectedTopic && (
							<Step2
								isOpen={!!selectedTopic}
								onOpenRequested={() => {}}
								selectedTopic={selectedTopic}
								submitStatus={submitStatus}
							/>
						)}
					</form>
				</FormProvider>
			</ContactFormSection>
		</RequireValidContract>
	);
}

function Step1(props: {
	isOpen: boolean;
	onOpenRequested: () => void;
	selectedTopic: KeyContactTopic | undefined;
	onContinue: (selection: KeyContactTopic) => void;
}) {
	const { setValue } = useFormContext();

	const onTopicSelected = (t: KeyContactTopic) => {
		setValue('topic', t);
		props.onContinue(t);
	};
	return (
		<FormStep
			number={1}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_KeyContact_Step1_Heading" />
					{!props.isOpen && props.selectedTopic && (
						<>
							:{' '}
							<ResourceTextInline resourceKey={'ContactForms_KeyContact_Step1_' + props.selectedTopic} />
						</>
					)}
				</>
			}
			isOpen={props.isOpen}
			onOpenRequested={props.onOpenRequested}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<ResourceText
					resourceKey={'ContactForms_KeyContact_Ingress_Step1'}
					optional={true}
					className="l-font-body"
				/>
				<div className="button-choices">
					<SelectButton onClick={() => onTopicSelected('KeyPickupOrReturn')}>
						<ResourceTextInline resourceKey="ContactForms_KeyContact_Step1_KeyPickupOrReturn" />
					</SelectButton>
					<SelectButton onClick={() => onTopicSelected('NewOrLostKeyOrLockRenewal')}>
						<ResourceTextInline resourceKey="ContactForms_KeyContact_Step1_NewOrLostKeyOrLockRenewal" />
					</SelectButton>
				</div>
			</SpacingContainer>
		</FormStep>
	);
}

function Step2(props: {
	isOpen: boolean;
	onOpenRequested: () => void;
	selectedTopic: KeyContactTopic;
	submitStatus: SubmitStatus;
}) {
	const { getResourceText } = useResource();
	const requiredText = getResourceText('ContactForms_RequiredField_Error');
	return (
		<FormStep
			number={2}
			heading={<ResourceTextInline resourceKey={'ContactForms_KeyContact_Heading_' + props.selectedTopic} />}
			isOpen={props.isOpen}
			onOpenRequested={props.onOpenRequested}
		>
			<SpacingContainer direction="column" rowGap={6}>
				<ResourceText
					resourceKey={'ContactForms_KeyContact_Ingress_' + props.selectedTopic}
					optional={true}
					className="l-font-body"
				/>
				{props.selectedTopic === 'NewOrLostKeyOrLockRenewal' && (
					<>
						<Checkbox
							name="masterKey"
							labelResourceKey="ContactForms_MasterKey_Label"
							optionResourceKey="ContactForms_MasterKey_Option"
						/>
						<RadioGroup
							name="pets"
							labelResourceKey="ContactForms_Pets_Label"
							options={getPetOptions()}
							required={getResourceText('ContactForms_RequiredField_Error')}
						/>
					</>
				)}

				<TextFormField
					name="subject"
					labelResourceKey="ContactForms_Subject_Label"
					placeholderResourceKey="ContactForms_Message_Placeholder"
					required={requiredText}
					maxLength={100}
				/>
				<TextAreaField
					name="message"
					labelResourceKey="ContactForms_Message_Label"
					placeholderResourceKey="ContactForms_Message_Placeholder"
					footnoteResourceKey="ContactForms_KeyContact_Message_Footnote"
					required={requiredText}
					maxLength={2000}
				/>
				<FileInputField
					name="files"
					labelResourceKey="ContactForms_Attachment_Label"
					footNoteResourceKey="ContactForms_Attachment_Footnote"
					fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
				/>
				{props.selectedTopic === 'NewOrLostKeyOrLockRenewal' ? (
					<CoTenantPrivacyNoticeServiceRequest />
				) : (
					<CoTenantPrivacyNoticeCustomerService />
				)}
			</SpacingContainer>
			<SubmitButtonAndProgress
				submitStatus={props.submitStatus}
				formTypeForTelemetry={ContactFormTelemetryType.KeyContact}
			/>
		</FormStep>
	);
}

function Success({ topic }: { topic: KeyContactTopic }) {
	return topic === 'KeyPickupOrReturn' ? (
		<CustomerServiceMessageConfirmation
			headingResourceKey="ContactForms_KeyContact_Confirmation_CustomerService_Heading"
			stepTitleResourceKey="ContactForms_KeyContact_Confirmation_CustomerService_Step_Heading"
			stepContentResourceKey="ContactForms_KeyContact_Confirmation_CustomerService_Step_Content"
		/>
	) : (
		<GenericServiceRequestConfirmation
			isAuthenticated
			headingResourceKey="ContactForms_KeyContact_Confirmation_Heading"
			stepTitleResourceKey="ContactForms_KeyContact_Confirmation_Step_Heading"
			stepContentResourceKey="ContactForms_KeyContact_Confirmation_Step_Content"
		/>
	);
}
