import { AuthenticationState } from '../common/authentication/AuthenticationContext';
import { ApiClientBase } from './apiClientBase';

export class CommonFacilitiesApiClient extends ApiClientBase {
	// params change to mandatory
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(context: AuthenticationState) {
		super(context);
	}

	async getDetails() {
		// Contract ID is added automatically by base class
		return await this.fetchJson<CommonFacilitiesDetailsAM>(`api/common-facilities`);
	}
}

export interface CommonFacilitiesDetailsAM {
	sharedFacilityReservationsInUse: boolean;
}
