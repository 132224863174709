import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { SettingsApiClient } from '../api/settingsApiClient';
import { useAuthenticationContext } from '../common/authentication/AuthenticationContext';

interface AntiForgeryTokenContext {
	token?: string;
	isAnonymous: boolean;
	setIsAnonymous: (anonymous: boolean) => void;
}

const initialState = {
	token: undefined,
	isAnonymous: true,
	setIsAnonymous: () => {},
};

const AntiForgeryTokenProviderContext = createContext<AntiForgeryTokenContext>(initialState);

export function AntiForgeryTokenProvider(props: { children: React.ReactNode }) {
	const [token, setToken] = useState<string | undefined>(initialState.token);
	const [isAnonymous, setIsAnonymous] = useState(initialState.isAnonymous);
	const authContext = useAuthenticationContext(isAnonymous);

	// Anti-forgery token should be re-fetched if user logs in
	useEffect(() => {
		async function fetchData() {
			const apiClient = new SettingsApiClient(authContext);
			const response = await apiClient.fetchAntiForgeryToken();
			setToken(response);
			setIsAnonymous(!authContext.loginRequired);
		}

		fetchData();
	}, [authContext]);

	const value = useMemo(
		() => ({
			token,
			isAnonymous,
			setIsAnonymous: (newVal: boolean) => {
				if (newVal !== isAnonymous) {
					setToken(undefined);
					setIsAnonymous(newVal);
				}
			},
		}),
		[token, isAnonymous, setToken, setIsAnonymous],
	);

	return (
		<AntiForgeryTokenProviderContext.Provider value={value}>
			{props.children}
		</AntiForgeryTokenProviderContext.Provider>
	);
}

export function useAntiForgeryTokenContext(anonymous?: boolean) {
	const { setIsAnonymous, ...rest } = useContext(AntiForgeryTokenProviderContext);

	useEffect(() => {
		setIsAnonymous(Boolean(anonymous));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anonymous]);

	return rest;
}
