import { FormGroup, FormGroupProps } from './FormGroup';
import { useFormContext } from 'react-hook-form';
import { ResourceTextInline } from '../../common/localization/ResourceText';
import { useInputId } from './useInputId';

export function Checkbox(props: Pick<FormGroupProps, 'name' | 'labelResourceKey'> & { optionResourceKey: string }) {
	const { register } = useFormContext();
	const id = useInputId();

	return (
		<FormGroup name={props.name} labelResourceKey={props.labelResourceKey}>
			<div className="l-display-flex l-align-items-center">
				<div className="l-checkbox">
					<input id={id} type="checkbox" {...register(props.name)} />
					<label htmlFor={id} />
				</div>
				<label className="l-margin-3-l l-font-body" htmlFor={id}>
					<ResourceTextInline resourceKey={props.optionResourceKey} />
				</label>
			</div>
		</FormGroup>
	);
}
