// Adapted from https://usehooks.com/useScript/

import { useState, useEffect } from 'react';

type ScriptStatus = 'loading' | 'idle' | 'ready' | 'error';

export function useScript(src: string | undefined) {
	const [status, setStatus] = useState<ScriptStatus>(src ? 'loading' : 'idle');

	function createScriptElement(source: string) {
		// Create script
		const script = document.createElement('script') as HTMLScriptElement;
		script.src = source;
		script.async = true;
		script.setAttribute('data-status', 'loading');
		// Add script to document body
		document.body.appendChild(script);
		// Store status in attribute on script
		// This can be read by other instances of this hook
		const setAttributeFromEvent = (event: Event) => {
			script!.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
		};
		script.addEventListener('load', setAttributeFromEvent);
		script.addEventListener('error', setAttributeFromEvent);
		return script;
	}

	useEffect(
		() => {
			if (!src) {
				setStatus('idle');
				return;
			}
			// Fetch existing script element by src
			// It may have been added by another instance of this hook
			let script = document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);
			if (!script) {
				script = createScriptElement(src);
			} else {
				// Grab existing script status from attribute and set to state.
				const scriptStatus = script!.getAttribute('data-status') as ScriptStatus;
				setStatus(scriptStatus);
			}
			// Script event handler to update status in state
			// Note: Even if the script already exists we still need to add
			// event handlers to update the state for *this* hook instance.
			const setStateFromEvent = (event: Event) => {
				setStatus(event.type === 'load' ? 'ready' : 'error');
			};
			script.addEventListener('load', setStateFromEvent);
			script.addEventListener('error', setStateFromEvent);
			return () => {
				if (script) {
					script.removeEventListener('load', setStateFromEvent);
					script.removeEventListener('error', setStateFromEvent);
				}
			};
		},
		[src], // Only re-run effect if script src changes
	);
	return status;
}
