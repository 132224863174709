import { useServiceRequests } from '../useServiceRequests';
import { BreadCrumbs } from '../../Components/BreadCrumbs';
import { ResourceTextInline } from '../../common/localization/ResourceText';
import { useSettings } from '../../boot/Settings';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { SpacingContainer, Button } from '@kojamo/lumo-dls';
import { ServiceRequestAM } from '../../api/serviceRequestApiClient';
import { useState } from 'react';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { useNavigate } from 'react-router';
import { ServiceRequestBase } from './ServiceRequestBase';
import { appRoutes } from '../../routes';
import { localizedLinks } from '../../common/localization/localizedLinks';
import { useAppLanguage } from '../../common/localization/useAppLanguage';

const NUMBER_OF_SERVICE_REQUESTS_TO_SHOW_INITIALLY = 4;

export function ServiceRequests() {
	const { language } = useAppLanguage();
	const settings = useSettings();
	const maintenanceInformationHref = `${settings?.shellBaseUrl}${localizedLinks.maintenanceInformation[language]}`;
	return (
		<div className="ServiceRequests">
			<BreadCrumbs>
				<a href={maintenanceInformationHref} target="_parent">
					<ResourceTextInline resourceKey="Breadcrumbs_FaultReport_Root" />
				</a>
				<ResourceTextInline resourceKey="Breadcrumbs_ServiceRequests_Title" />
			</BreadCrumbs>
			<ContactFormSection headingResourceKey="ServiceRequests_Heading">
				<ServiceRequestList />
			</ContactFormSection>
		</div>
	);
}

function ServiceRequestList() {
	const [showAll, setShowAll] = useState(false);
	const { allServiceRequests, loading } = useServiceRequests();
	const filteredServiceRequests = allServiceRequests
		.sort((a, b) => (a.creationTime < b.creationTime ? 1 : -1))
		.filter((_, index) => showAll || index < NUMBER_OF_SERVICE_REQUESTS_TO_SHOW_INITIALLY);
	return loading ? (
		<LoadingSpinner />
	) : (
		<div>
			<SpacingContainer direction="column" rowGap={5}>
				{0 < filteredServiceRequests.length ? (
					filteredServiceRequests.map((serviceRequest, index) => (
						<ServiceRequestListItem {...serviceRequest} key={index} />
					))
				) : (
					<p className="l-font-body is-l">
						<ResourceTextInline resourceKey="ServiceRequests_EmptyList" />
					</p>
				)}
			</SpacingContainer>
			{!showAll && filteredServiceRequests.length !== allServiceRequests.length && (
				<SpacingContainer direction="row" justify="center" className="l-margin-6-t">
					<Button variant="primary" onClick={() => setShowAll(true)}>
						<ResourceTextInline resourceKey="ServiceRequests_List_ShowMore" />
					</Button>
				</SpacingContainer>
			)}
		</div>
	);
}

function ServiceRequestListItem({ id, title, ...rest }: ServiceRequestAM) {
	const navigate = useNavigate();
	return (
		<div onClick={() => navigate(`/${appRoutes.myServiceRequests}/${id}`)}>
			<ServiceRequestBase {...rest}>
				<div className="l-font-body">{title}</div>
			</ServiceRequestBase>
		</div>
	);
}
