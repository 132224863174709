import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AntiForgeryTokenProvider } from './boot/AntiForgery';
import { SettingsProvider, useSettings } from './boot/Settings';

import { useShellIntegration } from './boot/shellIntegration';
import { useLink } from './boot/useLink';
import { useScript } from './boot/useScript';
import { AuthenticationContextProvider } from './common/authentication/AuthenticationContext';
import { ResourceTextProvider } from './common/localization/ResourceContext';
import { Router } from './Router';
import './scss/App.scss';
import { HeadScripts } from './Components/HeadScripts/HeadScripts';
import { useDispatch } from 'react-redux';
import { appStateActions } from './Actions/appStateActions';

function App() {
	return (
		<SettingsProvider>
			<AuthenticationContextProvider>
				<AntiForgeryTokenProvider>
					<AppContent />
				</AntiForgeryTokenProvider>
			</AuthenticationContextProvider>
		</SettingsProvider>
	);
}

export default App;

function AppContent() {
	const settings = useSettings();
	const shellInfo = useShellIntegration(settings);
	const footerScriptStatus = useScript(shellInfo.footerScriptSrc);
	const styleLinkStatus = useLink(shellInfo.styleUrl, 'stylesheet');
	const dispatch = useDispatch();
	useEffect(() => {
		if (footerScriptStatus === 'ready') {
			if (window.reportScrollPositionToParent) {
				window.reportScrollPositionToParent();
			}
		}
	}, [footerScriptStatus]);

	useEffect(() => {
		dispatch(appStateActions.init());
	}, [dispatch]);

	return (
		<div className="my-lumo-content">
			<Helmet>
				{!shellInfo.styleUrl && shellInfo.inlineStyles && (
					<style type="text/css">{shellInfo.inlineStyles}</style>
				)}
			</Helmet>
			<HeadScripts />
			<ResourceTextProvider>
				<Router stylesLoaded={!!shellInfo.inlineStyles || styleLinkStatus === 'ready'} />
			</ResourceTextProvider>
			<div className="my-lumo-footer-container" />
		</div>
	);
}
