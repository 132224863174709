import { SpacingContainer } from '@kojamo/lumo-dls';
import format from 'date-fns/format';
import { ResourceTextInline } from '../../common/localization/ResourceText';
import {
	getServiceRequestContactTypeKey,
	getStatusTypeClassName,
	getStatusTypeKey,
	ServiceRequestAM,
	ServiceRequestAMDestinationService,
	ServiceRequestContactType,
} from '../../api/serviceRequestApiClient';
import {
	getFaultAreaTypeResourceKey,
	getFaultToReportResourceKey,
	isFaultAreaType,
} from '../../common/faultReportForm';
import { ReactNode } from 'react';
import './ServiceRequestBase.scss';
import { useAppLanguage } from '../../common/localization/useAppLanguage';

type ServiceRequestBaseProps = Pick<ServiceRequestAM, 'targetScope' | 'contactType'> & {
	children?: ReactNode;
} & ServiceRequestListItemDetailsProps;

export function ServiceRequestBase({
	targetScope,
	contactType,
	children,
	detailsFirst,
	...rest
}: ServiceRequestBaseProps) {
	return (
		<SpacingContainer
			direction="column"
			rowGap={4}
			className="ServiceRequestBase l-padding-5-t l-padding-5-r l-padding-6-b l-padding-6-l"
		>
			{detailsFirst && <ServiceRequestListItemDetails {...rest} detailsFirst={detailsFirst} />}
			<div className="l-headline l-color-pink">
				<ResourceTextInline resourceKey={getServiceRequestContactTypeKey(contactType)} />
				{contactType === ServiceRequestContactType.ServiceRequest && (
					<span>
						{' '}
						/{' '}
						<ResourceTextInline
							resourceKey={
								isFaultAreaType(targetScope)
									? getFaultAreaTypeResourceKey(targetScope)
									: getFaultToReportResourceKey(targetScope)
							}
						/>
					</span>
				)}
			</div>
			{children}
			{!detailsFirst && <ServiceRequestListItemDetails {...rest} />}
		</SpacingContainer>
	);
}

type ServiceRequestListItemDetailsProps = Pick<
	ServiceRequestAM,
	'creationTime' | 'tampuuriId' | 'status' | 'destinationService'
> & {
	detailsFirst?: boolean;
};

function ServiceRequestListItemDetails({
	creationTime,
	tampuuriId,
	status,
	destinationService,
	detailsFirst,
}: ServiceRequestListItemDetailsProps) {
	const textClassName = 'l-font-body l-color-grey is-dark';
	const { dateFnsOptions } = useAppLanguage();
	return (
		<SpacingContainer direction="row" justify="edges" align={detailsFirst ? 'start' : 'end'}>
			<SpacingContainer direction="row" directionMobile="column-reverse" columnGap={4} rowGap={1}>
				<span className={textClassName}>{format(creationTime, 'Pp', dateFnsOptions)}</span>
				{tampuuriId && (
					<span className={textClassName}>
						<ResourceTextInline
							resourceKey="ServiceRequests_ListItem_TicketNumber"
							parameters={[tampuuriId]}
						/>
					</span>
				)}
			</SpacingContainer>
			<SpacingContainer direction="row" align="center" columnGap={2}>
				<span className={`${textClassName} l-text-right`}>
					<ResourceTextInline resourceKey={getStatusTypeKey(status, destinationService)} />
				</span>
				{destinationService !== ServiceRequestAMDestinationService.CustomerService && (
					<StatusIcon status={status} />
				)}
			</SpacingContainer>
		</SpacingContainer>
	);
}

function StatusIcon({ status }: Pick<ServiceRequestAM, 'status'>) {
	return <div className={`status ${getStatusTypeClassName(status)}`} />;
}
