import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';

import { TextAreaField } from '../../Components/forms/TextAreaField';
import { TextFormField } from '../../Components/forms/TextFormField';

import { FormStep } from '../../Components/forms/FormStep';

import { FileInputField } from '../../Components/forms/FileInputField';

import { SpacingContainer } from '@kojamo/lumo-dls';

import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';

import { SaunaDetailsAM, SaunaReservationProviderType } from '../../api/saunaApiClient';
import { useState } from 'react';

import { CommonAndOutdoorAreaFaultType, getFaultReportUrl } from '../../common/faultReportForm';
import { CoTenantPrivacyNoticeCustomerService } from '../../Components/CoTenantPrivacyNotices';
import { useAppLanguage } from '../../common/localization/useAppLanguage';
import { SelectButton } from '../../Components/SelectButton';
import { IconLinkExternal } from '../../Components/svg/IconLinkExternal';

import { SubmitStatus } from '../../Components/submitStatus';
import { useSettings } from '../../boot/Settings';
import { localizedLinks } from '../../common/localization/localizedLinks';
import { ContactFormTelemetryType } from '../../common/telemetryGA4';

enum SaunaFormSteps {
	Step1 = 'Step1',
	Step2 = 'Step2',
}

function SaunaWizard({
	saunaDetails,
	contractId,
	submitStatus,
}: {
	saunaDetails: SaunaDetailsAM;
	contractId: string;
	submitStatus: SubmitStatus;
}) {
	const [openSection, setOpenSection] = useState(SaunaFormSteps.Step1);
	const isQuestion = openSection === SaunaFormSteps.Step2;
	const settings = useSettings();
	const { language } = useAppLanguage();
	function navigateToSauna() {
		switch (saunaDetails.saunaReservationProviderType) {
			case SaunaReservationProviderType.OnlineSaunaReservation:
			case SaunaReservationProviderType.OnetimeSaunaReservation_Electrolux:
				window.open(`${settings?.shellBaseUrl}${localizedLinks.sauna[language]}`, '_parent');
				break;
			case SaunaReservationProviderType.SharedFacilityReservations:
				window.open(
					`${settings?.shellBaseUrl}${localizedLinks.sharedFacilityReservations[language]}`,
					'_parent',
				);
				break;
			default:
				break;
		}
	}

	return (
		<>
			<FormStep
				number={1}
				heading={<ResourceTextInline resourceKey={'ContactForms_Sauna_Heading_Step1'} />}
				isOpen={openSection === SaunaFormSteps.Step1}
			>
				<SpacingContainer direction="column" rowGap={6}>
					<div className="button-choices">
						<SelectButton
							className="l-display-flex l-align-items-center l-justify-center l-column-gap-1"
							onClick={navigateToSauna}
						>
							<ResourceTextInline resourceKey="ContactForms_Sauna_Ingress_ButtonReserveSauna" />
							<IconLinkExternal />
						</SelectButton>
						<SelectButton onClick={() => setOpenSection(SaunaFormSteps.Step2)}>
							<ResourceTextInline resourceKey="ContactForms_Sauna_Ingress_ButtonSaunaQuestion" />
						</SelectButton>
					</div>
				</SpacingContainer>
			</FormStep>
			{isQuestion && (
				<FormStep
					number={2}
					heading={<ResourceTextInline resourceKey={'ContactForms_Sauna_Heading_Step1'} />}
					isOpen={openSection === SaunaFormSteps.Step2}
				>
					<SpacingContainer direction="column" rowGap={6}>
						<ResourceText
							resourceKey={'ContactForms_Sauna_Ingress_Step1'}
							optional={true}
							className="l-font-body"
							linkOpenMode="self"
							parameters={[
								getFaultReportUrl(
									contractId,
									CommonAndOutdoorAreaFaultType.CommonAreaServiceNeed,
									language,
								),
							]}
						/>
						<TextFormField
							name="subject"
							labelResourceKey="ContactForms_Subject_Label"
							placeholderResourceKey="ContactForms_Subject_Placeholder"
						/>
						<TextAreaField
							name="message"
							labelResourceKey="ContactForms_Message_Label"
							placeholderResourceKey="ContactForms_Message_Placeholder"
							footnoteResourceKey="ContactForms_Sauna_Message_Footnote"
						/>
						<FileInputField
							name="files"
							labelResourceKey="ContactForms_Attachment_Label"
							footNoteResourceKey="ContactForms_Attachment_Footnote"
							fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
						/>
						<CoTenantPrivacyNoticeCustomerService />
					</SpacingContainer>
					<SubmitButtonAndProgress
						submitStatus={submitStatus}
						formTypeForTelemetry={ContactFormTelemetryType.Sauna}
					/>
				</FormStep>
			)}
		</>
	);
}

export default SaunaWizard;
