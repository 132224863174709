export class ApiCallError extends Error {
	constructor(message: string, public statusCode: number) {
		super(message);
		/* Need to set object prototype manually for typescript so that type guards work
		with this type. By default it is only detected as Error type and not as ApiMaintenanceError.
		https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
		 */
		Object.setPrototypeOf(this, ApiCallError.prototype);
	}
}
