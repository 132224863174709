import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ResourceTextInline } from '../common/localization/ResourceText';

export function ContactBreadCrumbs({ children }: { children: ReactNode | ReactNode[] }) {
	return (
		<BreadCrumbs>
			<Link to="/">
				<ResourceTextInline resourceKey="Breadcrumbs_Contact_Root" />
			</Link>
			{children}
		</BreadCrumbs>
	);
}

export function BreadCrumbs({ children }: { children: ReactNode[] }) {
	if (!children.length) {
		return null;
	}
	return (
		<ul className="ml-breadcrumbs">
			{children.map((child, index) => (
				<BreadCrumb key={index} index={index}>
					{child}
				</BreadCrumb>
			))}
		</ul>
	);
}

function BreadCrumb({ index, children }: { index: number; children: ReactNode }) {
	return (
		<>
			{index > 0 && (
				<li key={`divider-${index}`}>
					<BreadCrumbDividerIcon />
				</li>
			)}
			<li>{children}</li>
		</>
	);
}

function BreadCrumbDividerIcon() {
	return (
		<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 10.9092C0 5.11019 4.70101 0.40918 10.5 0.40918C13.2848 0.40918 15.9555 1.51543 17.9246 3.48456C19.8938 5.45369 21 8.12441 21 10.9092C21 16.7082 16.299 21.4092 10.5 21.4092C4.70101 21.4092 0 16.7082 0 10.9092ZM20.25 10.9092C20.25 5.5244 15.8848 1.15918 10.5 1.15918C5.11522 1.15918 0.75 5.5244 0.75 10.9092C0.75 16.294 5.11522 20.6592 10.5 20.6592C15.8848 20.6592 20.25 16.294 20.25 10.9092Z"
				fill="#222222"
			/>
			<path
				d="M8.43733 14.3742C8.2279 14.3626 8.05218 14.2122 8.00835 14.0071C7.96452 13.802 8.06344 13.5929 8.24983 13.4967L13.0648 10.9092L8.24983 8.3217C8.10245 8.24399 8.00773 8.09357 8.00134 7.92709C7.99495 7.76061 8.07786 7.60337 8.21884 7.51459C8.35982 7.42581 8.53745 7.41899 8.68483 7.4967L14.3098 10.4967C14.4685 10.5728 14.5695 10.7332 14.5695 10.9092C14.5695 11.0852 14.4685 11.2456 14.3098 11.3217L8.68483 14.3217C8.60919 14.3629 8.52318 14.3812 8.43733 14.3742Z"
				fill="#222222"
			/>
		</svg>
	);
}
