import { BrowserRouter } from 'react-router-dom';
import { Route, Routes, useLocation } from 'react-router';
import { OidcCallback } from './Pages/OidcCallback';
import { ContactSection } from './Pages/ContactSection';
import { ContactWayfinder } from './Pages/ContactWayfinder';
import { OtherTopics } from './Pages/OtherTopics';
import React, { useEffect } from 'react';
import { LoadingSpinner } from './Components/LoadingSpinner';
import { FaultReport } from './Pages/FaultReport';
import { notifyShellNavigatedTo } from './boot/shellIntegration';
import { FaultReportAnonymous } from './Pages/FaultReportAnonymous';
import { DisturbanceReport } from './Pages/DisturbanceReport';
import { KeyContact } from './Pages/KeyContact';
import { useAuthenticationContext } from './common/authentication/AuthenticationContext';
import { MovingContact } from './Pages/MovingContact';
import { RentAndContract } from './Pages/RentAndContract';
import { SaunaContact } from './Pages/Sauna';
import Parking from './Pages/Parking';
import { CommonFacilitiesContact } from './Pages/CommonFacilities';
import { BenefitsContact } from './Pages/Benefits';
import ServiceRequests from './Pages/ServiceRequests';
import ServiceRequest from './Pages/ServiceRequest';
import { appRoutes, appRoutesAnonymous } from './routes';
import { ContractContextProvider } from './Components/contractValidity/ContractContextProvider';
import { UserContactInformationProvider } from './Components/providers/UserContactInformationProvider';

export function Router(props: { stylesLoaded: boolean }) {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/openid-connect/silent" element={<OidcCallback silent />} />
				{props.stylesLoaded ? (
					<Route path="*" element={<ContentRouter />} />
				) : (
					<Route path="*" element={<LoadingSpinner variant="fullPage" />} />
				)}
			</Routes>
		</BrowserRouter>
	);
}

function ContentRouter() {
	const location = useLocation();
	useEffect(() => {
		if (window?.reportScrollPositionToParent) {
			// Notify scroll position on route change
			window.reportScrollPositionToParent();
		}
	}, [location]);
	useEffect(() => {
		notifyShellNavigatedTo(`${location.pathname}`);
	}, [location.pathname]);

	return (
		<Routes>
			<Route path="/" element={<ContactSection />}>
				<Route index={true} element={<ContactWayfinder />} />
				<Route
					path={appRoutes.otherTopics + '/*'}
					element={
						<RequireAuth>
							<OtherTopics />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.faultReport}
					element={
						<RequireAuth>
							<FaultReport />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.disturbance}
					element={
						<RequireAuth>
							<DisturbanceReport />
						</RequireAuth>
					}
				/>
				<Route path={appRoutesAnonymous.faultReport} element={<FaultReportAnonymous />} />
				<Route
					path={appRoutes.keyContact}
					element={
						<RequireAuth>
							<KeyContact />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.movingContact}
					element={
						<RequireAuth>
							<MovingContact />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.rentAndContract + '/*'}
					element={
						<RequireAuth>
							<RentAndContract />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.sauna + '/*'}
					element={
						<RequireAuth>
							<SaunaContact />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.parking}
					element={
						<RequireAuth>
							<Parking />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.commonFacilities + '/*'}
					element={
						<RequireAuth>
							<CommonFacilitiesContact />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.benefits + '/*'}
					element={
						<RequireAuth>
							<BenefitsContact />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.myServiceRequests}
					element={
						<RequireAuth>
							<ServiceRequests />
						</RequireAuth>
					}
				/>
				<Route
					path={appRoutes.myServiceRequests + '/:id'}
					element={
						<RequireAuth>
							<ServiceRequest />
						</RequireAuth>
					}
				/>
			</Route>
		</Routes>
	);
}

function RequireAuth({ children }: { children: React.ReactNode }) {
	const { userInfo } = useAuthenticationContext();
	return userInfo ? (
		<UserContactInformationProvider>
			<ContractContextProvider>{children}</ContractContextProvider>
		</UserContactInformationProvider>
	) : (
		<LoadingSpinner />
	);
}
