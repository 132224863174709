import { ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { useSettings } from '../../boot/Settings';

export function ContractNoLongerActive() {
	const settings = useSettings();
	return (
		<ContactFormSection
			headingResourceKey="ContactView_ContractExpired_Heading_Main"
			ingressTextKey="ContactView_ContractExpired_MainContent"
		>
			<a className="button" target="_parent" href={`${settings?.shellBaseUrl}/etusivu`}>
				<ResourceTextInline resourceKey="ContactView_ContractExpired_ButtonText" />
			</a>
		</ContactFormSection>
	);
}
