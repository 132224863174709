import { useState } from 'react';
import { ResourceTextInline } from '../common/localization/ResourceText';
import { SpacingContainer } from '@kojamo/lumo-dls';
import './ContactInformation.scss';
import { useContactInformation } from './providers/UserContactInformationProvider';
import { localizedLinks } from '../common/localization/localizedLinks';
import { useAppLanguage } from '../common/localization/useAppLanguage';

function ContactField(props: { value: string | undefined; labelResourceKey: string }) {
	const { value, labelResourceKey } = props;

	return (
		<div className="l-form-group">
			<h4 className={`form-label ${value ? '' : 'is-warning'}`}>
				<ResourceTextInline resourceKey={labelResourceKey} />
			</h4>
			<span className={`l-font-body is-l ${value ? '' : 'is-warning'}`}> {value ? value : '–'}</span>
		</div>
	);
}

function WarningIcon(props: { className?: string }) {
	return (
		<svg
			className={props.className}
			width="28"
			height="29"
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 14.5C0 6.76801 6.26801 0.5 14 0.5C17.713 0.5 21.274 1.975 23.8995 4.6005C26.525 7.22601 28 10.787 28 14.5C28 22.232 21.732 28.5 14 28.5C6.26801 28.5 0 22.232 0 14.5ZM27 14.5C27 7.3203 21.1797 1.5 14 1.5C6.8203 1.5 1 7.3203 1 14.5C1 21.6797 6.8203 27.5 14 27.5C21.1797 27.5 27 21.6797 27 14.5Z"
				fill="#BF4040"
			/>
			<path
				d="M14.125 7.5C14.4388 7.5 14.6986 7.71656 14.7432 7.9988L14.75 8.08528V16.9147C14.75 17.238 14.4702 17.5 14.125 17.5C13.8112 17.5 13.5514 17.2834 13.5068 17.0012L13.5 16.9147V8.08528C13.5 7.76204 13.7798 7.5 14.125 7.5Z"
				fill="#BF4040"
			/>
			<path
				d="M14.85 20.25C14.85 20.6642 14.5142 21 14.1 21C13.6858 21 13.35 20.6642 13.35 20.25C13.35 19.8358 13.6858 19.5 14.1 19.5C14.5142 19.5 14.85 19.8358 14.85 20.25Z"
				fill="#BF4040"
			/>
		</svg>
	);
}

function CloseIcon(props: { onClick: () => void; className?: string }) {
	return (
		<svg
			className={props.className}
			width="28"
			height="29"
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={props.onClick}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 14.5C0 6.76801 6.26801 0.5 14 0.5C17.713 0.5 21.274 1.975 23.8995 4.6005C26.525 7.22601 28 10.787 28 14.5C28 22.232 21.732 28.5 14 28.5C6.26801 28.5 0 22.232 0 14.5ZM27 14.5C27 7.3203 21.1797 1.5 14 1.5C6.8203 1.5 1 7.3203 1 14.5C1 21.6797 6.8203 27.5 14 27.5C21.1797 27.5 27 21.6797 27 14.5Z"
				fill="#BF4040"
			/>
			<path
				d="M14.77 14.5L19.43 10.09C19.6754 9.84408 19.6754 9.44592 19.43 9.2C19.3136 9.08275 19.1552 9.01681 18.99 9.01681C18.8248 9.01681 18.6664 9.08275 18.55 9.2L14 13.5L9.43 9.18C9.3136 9.06275 9.15522 8.99681 8.99 8.99681C8.82478 8.99681 8.6664 9.06275 8.55 9.18C8.30461 9.42592 8.30461 9.82408 8.55 10.07L13.23 14.5L8.57 18.91C8.32461 19.1559 8.32461 19.5541 8.57 19.8C8.68626 19.9243 8.84984 19.9934 9.02 19.99C9.17933 19.988 9.33235 19.9275 9.45 19.82L14 15.5L18.57 19.82C18.6877 19.9275 18.8407 19.988 19 19.99C19.1702 19.9934 19.3337 19.9243 19.45 19.8C19.6954 19.5541 19.6954 19.1559 19.45 18.91L14.77 14.5Z"
				fill="#BF4040"
			/>
		</svg>
	);
}

function ContactInvalidBanner() {
	const [isVisible, setIsVisible] = useState<boolean>(true);

	function handleClose() {
		setIsVisible(false);
	}

	return (
		<>
			{isVisible ? (
				<SpacingContainer align="center" className="validation-banner l-margin-6-b" direction="row">
					<WarningIcon className="l-margin-5-l l-margin-3-r" />
					<ResourceTextInline
						resourceKey="ContactForms_ContactInformation_InvalidContactBanner"
						className="l-font-body has-caret-suffix is-warning l-margin-0-t l-padding-4-t l-padding-4-b"
					/>
					<CloseIcon className="anchor-right l-margin-3-r" onClick={handleClose} />
				</SpacingContainer>
			) : null}
		</>
	);
}

function EditPersonalInfoLink(props: { className?: string }) {
	const { language } = useAppLanguage();
	const { settings } = useContactInformation();

	return (
		<a
			className={`l-link has-caret-suffix ${props.className}`}
			href={`${settings?.shellBaseUrl}${localizedLinks.ownInformation[language]}`}
			target="_parent"
		>
			<ResourceTextInline resourceKey="ContactForms_ContactInformation_Link" />
		</a>
	);
}

export function ContactInformation() {
	const { context, isValid } = useContactInformation();

	return (
		<div className="contact-information-container">
			<SpacingContainer
				align="center"
				className="contact-information-header l-margin-6-b"
				direction="row"
				columnGap={6}
			>
				<h3 id="contact-information-header" className="l-headline is-s">
					<ResourceTextInline resourceKey="ContactForms_ContactInformation_Header" />
				</h3>
				<EditPersonalInfoLink className="is-hidden-in-mobile" />
			</SpacingContainer>
			{isValid ? null : <ContactInvalidBanner />}
			<SpacingContainer
				className="contact-information-fields is-column-in-desktop l-margin-6-b"
				direction="row"
				justify="start"
			>
				<ContactField value={context?.name} labelResourceKey="ContactForms_ContactInformation_NameLabel" />
				<ContactField
					value={context?.contractAddress.street}
					labelResourceKey="ContactForms_ContactInformation_StreetAddressLabel"
				/>
				<ContactField value={context?.email} labelResourceKey="ContactForms_ContactInformation_EmailLabel" />
				<ContactField
					value={context?.phoneNumber}
					labelResourceKey="ContactForms_ContactInformation_PhoneLabel"
				/>
				<EditPersonalInfoLink className="is-hidden-in-desktop" />
			</SpacingContainer>
		</div>
	);
}
