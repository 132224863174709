import { Option } from '../Components/forms/Option';
import { PetOptions } from '../api/serviceRequestApiClient';
import { appRoutes } from '../routes';
import { UILanguage } from '../Reducer/userReducer';

export enum FaultAreaType {
	RentalObject = 'RentalObject',
	CommonAndOutdoorArea = 'CommonAndOutdoorArea',
}
export const allFaultAreaTypes = Object.values(FaultAreaType);
export function isFaultAreaType(input: any): input is FaultAreaType {
	return allFaultAreaTypes.includes(input);
}
export function getFaultAreaTypeResourceKey(type: FaultAreaType) {
	switch (type) {
		case FaultAreaType.RentalObject:
			return 'ContactForms_FaultReport_StepFaultArea_Type_OwnApartment';
		case FaultAreaType.CommonAndOutdoorArea:
			return 'ContactForms_FaultReport_StepFaultArea_Type_CommonAndOutdoorArea';
		default:
			throw Error(`getFaultAreaTypeResourceKey: invalid type "${type}"`);
	}
}

export enum CommonAndOutdoorAreaFaultType {
	CommonFacilitiesCleaning = 'CommonFacilitiesCleaning',
	YardMaintenance = 'YardMaintenance',
	CommonAreaServiceNeed = 'CommonAreaServiceNeed',
	WasteDisposal = 'WasteDisposal',
}
export const allCommonAndOutdoorAreaFaultTypes = Object.values(CommonAndOutdoorAreaFaultType);
export function getCommonAndOutdoorAreaFaultTypeResourceKey(type: CommonAndOutdoorAreaFaultType) {
	switch (type) {
		case CommonAndOutdoorAreaFaultType.CommonFacilitiesCleaning:
			return 'ContactForms_FaultReport_StepCommonAndOutdoorAreaFault_Type_Cleaning';
		case CommonAndOutdoorAreaFaultType.YardMaintenance:
			return 'ContactForms_FaultReport_StepCommonAndOutdoorAreaFault_Type_YardMaintenance';
		case CommonAndOutdoorAreaFaultType.CommonAreaServiceNeed:
			return 'ContactForms_FaultReport_StepCommonAndOutdoorAreaFault_Type_ServiceNeed';
		case CommonAndOutdoorAreaFaultType.WasteDisposal:
			return 'ContactForms_FaultReport_StepCommonAndOutdoorAreaFault_Type_WasteManagement';
		default:
			throw Error('getCommonAndOutdoorAreaFaultTypeResourceKey: invalid type');
	}
}

export enum OwnApartmentFaultType {
	ApartmentNameChange = 'ApartmentNameChange',
	FaultAndServiceWork = 'FaultAndServiceWork',
}
export function getOwnApartmentFaultTypeResourceKey(type: OwnApartmentFaultType) {
	switch (type) {
		case OwnApartmentFaultType.ApartmentNameChange:
			return 'ContactForms_FaultReport_StepOwnApartmentFault_Type_NameChange';
		case OwnApartmentFaultType.FaultAndServiceWork:
			return 'ContactForms_FaultReport_StepOwnApartmentFault_Type_FaultAndServiceWork';
		default:
			throw Error('getCommonAndOutdoorAreaFaultTypeResourceKey: invalid type');
	}
}

export enum OwnApartmentFaultAndServiceWorkType {
	ApartmentBrokenDevice = 'ApartmentBrokenDevice',
	ApartmentTemperatureAndIndoorAir = 'ApartmentTemperatureAndIndoorAir',
	ApartmentFaultOrDeficiency = 'ApartmentFaultOrDeficiency',
}
export const allOwnApartmentFaultAndServiceWorkTypes = Object.values(OwnApartmentFaultAndServiceWorkType);
export function getOwnApartmentFaultAndServiceWorkTypeResourceKey(type: OwnApartmentFaultAndServiceWorkType) {
	switch (type) {
		case OwnApartmentFaultAndServiceWorkType.ApartmentFaultOrDeficiency:
			return 'ContactForms_FaultReport_StepOwnApartmentFaultAndServiceWork_Type_FaultsAndDeficiencies';
		case OwnApartmentFaultAndServiceWorkType.ApartmentBrokenDevice:
			return 'ContactForms_FaultReport_StepOwnApartmentFaultAndServiceWork_Type_FaultyDevice';
		case OwnApartmentFaultAndServiceWorkType.ApartmentTemperatureAndIndoorAir:
			return 'ContactForms_FaultReport_StepOwnApartmentFaultAndServiceWork_Type_TemperatureAndIndoorAir';
		default:
			throw Error('getCommonAndOutdoorAreaFaultTypeResourceKey: invalid type');
	}
}

export function isTargetScope(value: string): value is ServiceRequestTargetScope {
	return (allValidTargetScopes as string[]).includes(value);
}

export const allValidTargetScopes = [
	OwnApartmentFaultType.ApartmentNameChange,
	CommonAndOutdoorAreaFaultType.CommonAreaServiceNeed,
	CommonAndOutdoorAreaFaultType.CommonFacilitiesCleaning,
	CommonAndOutdoorAreaFaultType.WasteDisposal,
	CommonAndOutdoorAreaFaultType.YardMaintenance,
	OwnApartmentFaultAndServiceWorkType.ApartmentBrokenDevice,
	OwnApartmentFaultAndServiceWorkType.ApartmentFaultOrDeficiency,
	OwnApartmentFaultAndServiceWorkType.ApartmentTemperatureAndIndoorAir,
];

export type ServiceRequestTargetScope =
	| CommonAndOutdoorAreaFaultType
	| OwnApartmentFaultType.ApartmentNameChange
	| OwnApartmentFaultAndServiceWorkType;

export function isOwnApartmentFaultType(
	fault: ServiceRequestTargetScope,
): fault is OwnApartmentFaultAndServiceWorkType {
	return [
		OwnApartmentFaultAndServiceWorkType.ApartmentBrokenDevice,
		OwnApartmentFaultAndServiceWorkType.ApartmentFaultOrDeficiency,
		OwnApartmentFaultAndServiceWorkType.ApartmentTemperatureAndIndoorAir,
	].includes(fault as any);
}

export function getFaultToReportResourceKey(type: ServiceRequestTargetScope) {
	if (isCommonAndOutdoorAreaFaultType(type)) {
		return getCommonAndOutdoorAreaFaultTypeResourceKey(type);
	} else if (type === OwnApartmentFaultType.ApartmentNameChange) {
		return getOwnApartmentFaultTypeResourceKey(type);
	} else if (isOwnApartmentFaultAndServiceWorkType(type)) {
		return getOwnApartmentFaultAndServiceWorkTypeResourceKey(type);
	} else {
		return 'ContactForms_FaultReport_ServiceRequest_Type_Unknown';
	}
}

export function isCommonAndOutdoorAreaFaultType(
	fault: ServiceRequestTargetScope,
): fault is CommonAndOutdoorAreaFaultType {
	return allCommonAndOutdoorAreaFaultTypes.includes(fault as CommonAndOutdoorAreaFaultType);
}

export function isOwnApartmentFaultAndServiceWorkType(
	fault: ServiceRequestTargetScope,
): fault is OwnApartmentFaultAndServiceWorkType {
	return allOwnApartmentFaultAndServiceWorkTypes.includes(fault as OwnApartmentFaultAndServiceWorkType);
}

export function getFaultToReportDescriptionResourceKey(type: ServiceRequestTargetScope) {
	switch (type) {
		case CommonAndOutdoorAreaFaultType.CommonFacilitiesCleaning:
			return 'ContactForms_FaultReport_ServiceRequest_Description_Cleaning';
		case CommonAndOutdoorAreaFaultType.YardMaintenance:
			return 'ContactForms_FaultReport_ServiceRequest_Description_YardMaintenance';
		case CommonAndOutdoorAreaFaultType.CommonAreaServiceNeed:
			return 'ContactForms_FaultReport_ServiceRequest_Description_ServiceNeed';
		case CommonAndOutdoorAreaFaultType.WasteDisposal:
			return 'ContactForms_FaultReport_ServiceRequest_Description_WasteManagement';
		case OwnApartmentFaultType.ApartmentNameChange:
			return 'ContactForms_FaultReport_ServiceRequest_Description_NameChange';
		case OwnApartmentFaultAndServiceWorkType.ApartmentFaultOrDeficiency:
			return 'ContactForms_FaultReport_ServiceRequest_Description_FaultsAndDeficiencies';
		case OwnApartmentFaultAndServiceWorkType.ApartmentBrokenDevice:
			return 'ContactForms_FaultReport_ServiceRequest_Description_FaultyDevice';
		case OwnApartmentFaultAndServiceWorkType.ApartmentTemperatureAndIndoorAir:
			return 'ContactForms_FaultReport_ServiceRequest_Description_TemperatureAndIndoorAir';
		default:
			throw Error('getFaultToReportDescriptionResourceKey: invalid type');
	}
}

export type VisibleSection =
	| 'FaultArea'
	| FaultAreaType.CommonAndOutdoorArea
	| FaultAreaType.RentalObject
	| OwnApartmentFaultType.FaultAndServiceWork
	| 'Confirmation';

export function getVisibleSections(section?: VisibleSection): VisibleSection[] {
	switch (section) {
		case FaultAreaType.CommonAndOutdoorArea:
			return [FaultAreaType.CommonAndOutdoorArea];
		case FaultAreaType.RentalObject:
			return [FaultAreaType.RentalObject];
		case OwnApartmentFaultType.FaultAndServiceWork:
			return [FaultAreaType.RentalObject, OwnApartmentFaultType.FaultAndServiceWork];
		default:
			return [];
	}
}

export interface FaultReportFormData {
	fault?: ServiceRequestTargetScope;
	subject: string;
	message: string;
	files: File[] | undefined;
	masterKey?: boolean;
	pets?: PetOptions;
}

export function getPetOptions(): Option[] {
	return [
		{
			value: PetOptions.NoPets,
			labelResourceKey: 'ContactForms_Pets_NoPets',
		},
		{
			value: PetOptions.PetsWithAccessOk,
			labelResourceKey: 'ContactForms_Pets_PetsWithAccessOk',
		},
		{
			value: PetOptions.PetsWithNoAccess,
			labelResourceKey: 'ContactForms_Pets_PetsWithNoAccess',
		},
	];
}

export function getFaultReportUrl(
	contractId: string,
	prefillValue: ServiceRequestTargetScope | FaultAreaType | undefined,
	language: UILanguage,
) {
	return `/${appRoutes.faultReport}?contractId=${contractId}${
		prefillValue ? `&targetScope=${prefillValue}` : ''
	}&language=${language}`;
}
