import { ResourceText, ResourceTextInline } from '../common/localization/ResourceText';
import { ReactNode } from 'react';
import { useContactInformation } from './providers/UserContactInformationProvider';
import { ContactInformation } from './ContactInformation';
import './ContactFormSection.scss';

interface ContactFormSectionProps {
	headingResourceKey: string;
	preIngressContent?: ReactNode;
	ingressTextKey?: string;
	ingressTextNode?: ReactNode;
	showContactInformation?: boolean;
	children?: ReactNode;
}

export function ContactFormSection(props: ContactFormSectionProps) {
	const { isValid } = useContactInformation();
	const formIsDisabled = props.showContactInformation && !isValid;

	return (
		<>
			<h1 className="l-headline is-display is-xl l-margin-6-b l-color-blue">
				<ResourceTextInline resourceKey={props.headingResourceKey} />
			</h1>
			{props.preIngressContent}
			{props.ingressTextNode}
			{props.ingressTextKey && (
				<ResourceText
					resourceKey={props.ingressTextKey}
					className="l-font-body is-xl l-color-blue l-margin-5-b"
				/>
			)}
			{props.showContactInformation ? <ContactInformation /> : null}
			<fieldset
				className={`contact-form-section ${formIsDisabled ? 'disable-content' : ''}`}
				disabled={formIsDisabled}
			>
				{props.children}
			</fieldset>
		</>
	);
}
