import React, { createContext, useContext, useEffect, useState } from 'react';
import { SettingsAM, SettingsApiClient } from '../api/settingsApiClient';

const SettingsContext = createContext<SettingsAM | undefined>(undefined);

export function SettingsProvider(props: { children: React.ReactNode }) {
	const [settings, setSettings] = useState<SettingsAM | undefined>(undefined);

	useEffect(() => {
		async function fetchData() {
			const apiClient = new SettingsApiClient();
			const response = await apiClient.fetchAppSettings();
			setSettings(response);
		}

		fetchData();
	}, [setSettings]);

	return <SettingsContext.Provider value={settings}>{props.children}</SettingsContext.Provider>;
}

export function useSettings() {
	return useContext(SettingsContext);
}
