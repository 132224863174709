import { ResourceText } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../ContactFormSection';
import { useScrollToTop } from '../../common/useScrollToTop';
import { SuccessMessageBox } from './SuccessMessageBox';
import { OwnReportsLink } from './OwnReportsLink';

export function GenericServiceRequestConfirmation({
	isAuthenticated,
	headingResourceKey,
	stepTitleResourceKey,
	stepContentResourceKey,
}: {
	isAuthenticated: boolean;
	headingResourceKey: string;
	stepTitleResourceKey: string;
	stepContentResourceKey: string;
}) {
	useScrollToTop(true);

	return (
		<ContactFormSection headingResourceKey={headingResourceKey}>
			<SuccessMessageBox titleResourceKey={stepTitleResourceKey}>
				<ResourceText resourceKey={stepContentResourceKey} className="l-font-body is-m" />
				{isAuthenticated && <OwnReportsLink />}
			</SuccessMessageBox>
		</ContactFormSection>
	);
}
