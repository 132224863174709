import { ServiceRequestAIM, ContactRequestType } from '../../api/serviceRequestApiClient';
import { FaultReportFormData, isOwnApartmentFaultType } from '../../common/faultReportForm';

export function faultReportFormDataToAPI(
	{ fault, subject, message, masterKey, pets }: FaultReportFormData,
	contractId: string,
): ServiceRequestAIM {
	if (!fault) {
		throw Error('faultReportFormDataToAPI: no fault type specified');
	}
	const serviceRequest = {
		targetScope: fault,
		title: subject,
		description: message,
		contractId,
		contactRequestType: ContactRequestType.ServiceRequest,
	};
	return isOwnApartmentFaultType(fault)
		? { ...serviceRequest, masterKeyAccessAllowed: masterKey, petState: pets }
		: serviceRequest;
}
