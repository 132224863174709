import { ButtonHTMLAttributes } from 'react';

export function SelectButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
	const { children, className, ...rest } = props;
	return (
		<button className={`button-select l-font-body is-contrast is-l ${className}`} {...rest} type="button">
			{children}
		</button>
	);
}
