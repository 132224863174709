// Adapted from useScript.ts

import { useState, useEffect } from 'react';

type Status = 'loading' | 'idle' | 'ready' | 'error';

export function useLink(href: string | undefined, rel: string) {
	const [status, setStatus] = useState<Status>(href ? 'loading' : 'idle');

	function createElement(src: string, rel_: string) {
		const link = document.createElement('link');
		link.rel = rel_;
		link.href = src;
		link.setAttribute('data-status', 'loading');
		document.head.appendChild(link);
		// Store status in an attribute of element itself
		// This can be read by other instances of this hook
		const setAttributeFromEvent = (event: Event) => {
			link!.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
		};
		link.addEventListener('load', setAttributeFromEvent);
		link.addEventListener('error', setAttributeFromEvent);
		return link;
	}

	useEffect(
		() => {
			if (!href) {
				setStatus('idle');
				return;
			}
			// Fetch existing element by src
			// It may have been added by another instance of this hook
			let link = document.querySelector<HTMLLinkElement>(`link[href="${href}"]`);
			if (!link) {
				link = createElement(href, rel);
			} else {
				// Grab existing script status from attribute and set to state.
				const loadStatus = link!.getAttribute('data-status') as Status;
				setStatus(loadStatus);
			}
			// event handler to update status in state
			// Note: Even if the script already exists we still need to add
			// event handlers to update the state for *this* hook instance.
			const setStateFromEvent = (event: Event) => {
				setStatus(event.type === 'load' ? 'ready' : 'error');
			};
			link.addEventListener('load', setStateFromEvent);
			link.addEventListener('error', setStateFromEvent);
			return () => {
				if (link) {
					link.removeEventListener('load', setStateFromEvent);
					link.removeEventListener('error', setStateFromEvent);
				}
			};
		},
		[href, rel], // Only re-run effect if source changes
	);
	return status;
}
