import { ResourceTextInline } from '../../common/localization/ResourceText';
import { useSettings } from '../../boot/Settings';
import { localizedLinks } from '../../common/localization/localizedLinks';
import { useAppLanguage } from '../../common/localization/useAppLanguage';

export function OwnReportsLink() {
	const { language } = useAppLanguage();
	const settings = useSettings();
	const ownContactRequestsHref = `${settings?.shellBaseUrl}${localizedLinks.ownServiceRequests[language]}`;
	return (
		<a className="l-font-body is-m is-contrast l-display-flex" href={ownContactRequestsHref} target="_parent">
			<ResourceTextInline resourceKey="ContactForms_OwnReportsLink_ProgressLink" />
		</a>
	);
}
