import { ResourceText } from '../../common/localization/ResourceText';
import { useResource } from '@kojamo/react-utils';
import { LoadingSpinner } from '../LoadingSpinner';
import { SubmitStatus } from '../submitStatus';
import {
	addTelemetryEventGA4,
	ContactFormTelemetryType,
	getGA4ActionForContactFormTelemetryType,
} from '../../common/telemetryGA4';

type UnionKeys<T> = T extends T ? keyof T : never;
type StrictUnionHelper<T, TAll> = T extends any ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>> : never;
type StrictUnion<T> = StrictUnionHelper<T, T>;

type Props = StrictUnion<{ submitStatus: SubmitStatus } | { isSubmitting: boolean; error: Error | undefined }> & {
	formTypeForTelemetry: ContactFormTelemetryType;
};

export function SubmitButtonAndProgress(props: Props) {
	const { getResourceText } = useResource();
	const isSubmitting = props.isSubmitting || props.submitStatus === 'submitting';
	const hasError = props.error || props.submitStatus === 'error';
	return (
		<div className="l-margin-5-t">
			{isSubmitting && <LoadingSpinner />}
			<input
				type="submit"
				value={getResourceText('ContactForms_Button_Submit')}
				className="l-button"
				disabled={isSubmitting}
				onClick={() => {
					addTelemetryEventGA4({
						action: getGA4ActionForContactFormTelemetryType(props.formTypeForTelemetry),
					});
				}}
			/>
			{hasError && <ResourceText resourceKey="ContactForms_SubmitError" className="l-font-error" />}
		</div>
	);
}
