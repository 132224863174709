import { ApiClientBase } from './apiClientBase';

export interface UserContactInformation {
	name: string;
	streetAddress: string;
	email: string;
	phoneNumber: string;
	contractAddress: AddressAM;
}

interface AddressAM {
	street: string;
	zipCode: string;
	postOffice: string;
}

export class UserContactInfoApiClient extends ApiClientBase {
	public async fetchUserContactInfo(): Promise<UserContactInformation> {
		return await this.fetchJson<UserContactInformation>('/api/users/me/contact-info');
	}
}
