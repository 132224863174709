import { MarkdownContent } from '../MarkdownContent';
import { useResource } from '@kojamo/react-utils';
import { ResourceTextTransform, ResourceTextType } from './types';

export interface IResourceTextProps {
	// the resource key which is used to lookup the resource in question
	resourceKey: string;

	// the default text (if any). When specified, in case a resource text is not found, this will be displayed
	defaultText?: string;

	// possible parameters for the text. These are used to substitute parameters inside the text
	parameters?: string[];

	transform?: ResourceTextTransform;

	textType?: ResourceTextType;

	tagName?: keyof JSX.IntrinsicElements;

	optional?: boolean;

	/**
	 * Link open mode (target): self (default), _top top _blank
	 */
	linkOpenMode?: 'self' | 'top' | 'blank';

	allowHtml?: boolean;

	/** Class name for the root element */
	className?: string;
}

export function ResourceText(props: IResourceTextProps) {
	const { getResourceText } = useResource();
	const text = getResourceText(props.resourceKey, {
		parameters: props.parameters,
		transform: props.transform,
		textType: props.textType,
		optional: props.optional,
	});

	if (props.optional && !text) {
		return null;
	}

	switch (props.textType) {
		case 'Plain':
			return <>{text}</>;
		case 'Markdown':
		case 'MarkdownInline':
		default:
			return (
				<MarkdownContent
					text={text}
					tagName={props.tagName}
					inline={props.textType === 'MarkdownInline'}
					linkOpenMode={props.linkOpenMode}
					allowhtml={props.allowHtml}
					className={(props.className ? props.className + ' ' : '') + 'l-inherit-child-text-styles'}
				/>
			);
	}
}

export function ResourceTextInline(props: Omit<IResourceTextProps, 'textType'>) {
	return <ResourceText {...props} textType="MarkdownInline" />;
}
