import createSagaMiddleware from 'redux-saga';
import { AnyAction, configureStore, Store } from '@reduxjs/toolkit';
import { userReducer } from '../Reducer/userReducer';
import rootSaga from './rootSaga';
import { AppRootState } from './appRootState';

export function setUpStore(): Store<AppRootState, AnyAction> {
	const sagaMiddleware = createSagaMiddleware();

	const store = configureStore({
		reducer: {
			user: userReducer,
		},
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
		devTools: process.env.NODE_ENV === 'development', // enable Redux DevTools in development environment
	});

	sagaMiddleware.run(rootSaga);

	return store;
}
