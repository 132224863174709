import React from 'react';
import { useForm } from 'react-hook-form';
import { ContactApiClient, RentAndContractContactAIM } from '../../api/contactApiClient';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { SubmitStatus } from '../../Components/submitStatus';
import { useAntiForgeryTokenContext } from '../../boot/AntiForgery';

export function useRentAndContractForm() {
	const [submitStatus, setSubmitStatus] = React.useState<SubmitStatus>('initialized');
	const formMethods = useForm<RentAndContractFormData>();
	const authState = useAuthenticationContext();
	const { token: antiForgeryToken } = useAntiForgeryTokenContext();

	const onSubmit = formMethods.handleSubmit(async (data: RentAndContractFormData) => {
		setSubmitStatus('submitting');
		const apiClient = new ContactApiClient(authState, antiForgeryToken);

		const req: RentAndContractContactAIM = {
			...data,
			topic: 'Generic',
		};

		apiClient
			.sendRentAndContractContact(req)
			.then(() => {
				setSubmitStatus('success');
			})
			.catch(() => setSubmitStatus('error'));
	});

	return { formMethods, onSubmit, submitStatus };
}

export interface RentAndContractFormData {
	subject: string;
	message: string;
	files: File[] | undefined;
}
