import { getType } from 'typesafe-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { UILanguage } from '../Reducer/userReducer';
import { appStateActions } from '../Actions/appStateActions';
import { getLocalStorageValue, setLocalStorageValue } from '../common/localStorage';

const languageLocalStorageKey = 'language';
function* setLanguageSaga(): Generator {
	const search = window.location.search;
	const queryParams = new URLSearchParams(search);
	const lang = (queryParams.get('language') || getLocalStorageValue(languageLocalStorageKey)) as UILanguage;

	if (lang) {
		setLocalStorageValue(languageLocalStorageKey, lang);
	}

	// Validate the lang query parameter.
	if (!['fi', 'en'].includes(lang)) {
		yield put(appStateActions.setLanguage.failure(new Error(`Invalid language: ${lang}`)));
	} else {
		yield put(appStateActions.setLanguage.success(lang));
	}
}
function* appStateSaga() {
	yield takeLatest(getType(appStateActions.setLanguage.request), setLanguageSaga);
}

export default appStateSaga;
