import { AnyObject } from './anyObject';

export enum GA4EventAction {
	service_request = 'service_request',
	common_facilities = 'common_facilities',
	other_topics = 'other_topics',
	rent_and_contract = 'rent_and_contract',
	disturbance_report = 'disturbance_report',
	sauna = 'sauna',
	key_contact = 'key_contact',
	parking = 'parking',
	moving_contact = 'moving_contact',
	benefits = 'benefits',
}

export enum ContactFormTelemetryType {
	Benefits = 'Benefits',
	CommonFacilities = 'CommonFacilities',
	DisturbanceReport = 'DisturbanceReport',
	ServiceRequest = 'ServiceRequest',
	KeyContact = 'KeyContact',
	MovingContact = 'MovingContact',
	OtherTopics = 'OtherTopics',
	Parking = 'Parking',
	RentAndContract = 'RentAndContract',
	Sauna = 'Sauna',
}

export function getGA4ActionForContactFormTelemetryType(telemetryType: ContactFormTelemetryType) {
	switch (telemetryType) {
		case ContactFormTelemetryType.ServiceRequest:
			return GA4EventAction.service_request;
		case ContactFormTelemetryType.CommonFacilities:
			return GA4EventAction.common_facilities;
		case ContactFormTelemetryType.OtherTopics:
			return GA4EventAction.other_topics;
		case ContactFormTelemetryType.RentAndContract:
			return GA4EventAction.rent_and_contract;
		case ContactFormTelemetryType.DisturbanceReport:
			return GA4EventAction.disturbance_report;
		case ContactFormTelemetryType.Sauna:
			return GA4EventAction.sauna;
		case ContactFormTelemetryType.KeyContact:
			return GA4EventAction.key_contact;
		case ContactFormTelemetryType.Parking:
			return GA4EventAction.parking;
		case ContactFormTelemetryType.MovingContact:
			return GA4EventAction.moving_contact;
		case ContactFormTelemetryType.Benefits:
			return GA4EventAction.benefits;
		default:
			return GA4EventAction.other_topics;
	}
}

interface GA4Event extends AnyObject {
	action: GA4EventAction;
}

export function addTelemetryEventGA4(event: GA4Event) {
	const eventObject = {
		event: 'contact',
		category: 'contact_form',
		...event,
		label: `${window.location.pathname}${window.location.search}`,
		ga4_event: true,
	};

	const dataLayer = (window as any).dataLayer;
	if (dataLayer) {
		dataLayer.push(eventObject);
	}
}
