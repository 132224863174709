import { Outlet } from 'react-router';
import { ResourceText } from '../common/localization/ResourceText';
import { SideBar } from '../Components/SideBar';
import { useScrollToTop } from '../common/useScrollToTop';
import { useResource } from '@kojamo/react-utils';
export function ContactSection() {
	useScrollToTop();
	const { getResourceText } = useResource();

	return (
		<div className="contact-section ml-layout-with-sidebar">
			<div className="ml-grid">
				<Outlet />
				<SideBar />
			</div>
			<div className="ml-chatbot shadow">
				<h3>{getResourceText('Chat_Title')}</h3>
				<ResourceText resourceKey="Chat_Help" />
			</div>
		</div>
	);
}
