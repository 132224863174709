import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ResourceText, ResourceTextInline } from '../../common/localization/ResourceText';
import { FormGroup, FormGroupProps } from './FormGroup';
import { IconClose } from './IconClose';

export function FileInputField({
	fileTypes,
	footNoteResourceKey,
	...formGroupProps
}: Pick<FormGroupProps, 'name' | 'labelResourceKey' | 'className'> & {
	fileTypes: string[];
	footNoteResourceKey?: string;
}) {
	const [showFileTypeError, setShowFileTypeError] = useState<boolean>(false);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const { setValue } = useFormContext();
	const fileInputRef = React.useRef<HTMLInputElement | null>(null);

	const isValidFileType = (file: File) => {
		return fileTypes.indexOf(file.type) >= 0;
	};

	const changeFiles = (files: File[]) => {
		setSelectedFiles(files);
		setValue(formGroupProps.name, files);
	};

	const onRemove = (file: File) => {
		changeFiles(selectedFiles.filter((f) => f !== file));
	};

	return (
		<FormGroup {...formGroupProps} className={`ml-file-input ${formGroupProps.className || ''}`}>
			{selectedFiles.length ? (
				<div className="selected-files l-padding-1-t l-padding-3-b">
					{selectedFiles.map((selectedFile) => (
						<div
							className="selected-file l-padding-1-y l-font-body is-s is-emphasis"
							key={selectedFile.name}
						>
							<span>{selectedFile.name}</span>
							<div
								className="remove-icon l-padding-5-l l-display-flex"
								onClick={() => onRemove(selectedFile)}
							>
								<IconClose color={'#cf3f6c'} />
								<ResourceTextInline
									resourceKey="FileInputPicker_Remove"
									className="l-padding-3-l l-color-pink"
								/>
							</div>
						</div>
					))}
				</div>
			) : null}

			<label className="l-button" htmlFor={formGroupProps.name + '_picker'}>
				<ResourceTextInline resourceKey={formGroupProps.labelResourceKey} />
			</label>
			<input
				type="file"
				id={formGroupProps.name + '_picker'}
				multiple
				ref={(e) => {
					fileInputRef.current = e;
				}}
				onChange={(e) => {
					if (!e.target.files || e.target.files.length === 0) {
						setShowFileTypeError(false);
					} else {
						let isValid = true;
						const validFiles = [];
						for (let i = 0; i < e.target.files.length; i++) {
							if (!isValidFileType(e.target.files[i])) {
								isValid = false;
								break;
							}
							validFiles.push(e.target.files[i]);
						}
						setShowFileTypeError(!isValid);
						if (isValid) {
							const newFiles = selectedFiles.concat(validFiles);
							changeFiles(newFiles);
						}
					}
				}}
			/>

			{showFileTypeError && (
				<ResourceText resourceKey="ContactView_FileInput_InvalidType" className="l-font-error l-margin-2-t" />
			)}
			{footNoteResourceKey && (
				<ResourceText resourceKey={footNoteResourceKey} className="l-font-footnote l-margin-2-t" />
			)}
		</FormGroup>
	);
}
