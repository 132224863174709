import { ResourceImage } from '../common/localization/ResourceImage';
import { ResourceTextInline } from '../common/localization/ResourceText';
import { Button } from '@kojamo/lumo-dls';

import './contactBanner.scss';

export function ContactBanner(props: {
	textResourceKey: string;
	buttonTextResourceKey: string;
	imageResourceKey: string;
	onButtonClick: () => void;
}) {
	return (
		<div className="ml-contact-banner">
			<div className="banner-content">
				<span className="banner-text l-font-body is-l">
					<ResourceTextInline resourceKey={props.textResourceKey} />
				</span>
				<Button className="banner-button" onClick={props.onButtonClick}>
					<ResourceTextInline resourceKey={props.buttonTextResourceKey} />
				</Button>
				<ResourceImage className="banner-image" resourceKey={props.imageResourceKey} />
			</div>
		</div>
	);
}
