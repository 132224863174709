import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

export function useScrollToTop(always?: true) {
	const { pathname } = useLocation();
	const navigationType = useNavigationType();

	useEffect(() => {
		if (always || navigationType !== 'POP') {
			window.scrollTo(0, 0);
		}
	}, [pathname, navigationType, always]);
}
