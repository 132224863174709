import { ApiClientBase } from './apiClientBase';

export interface ContractValidityAM {
	startDate: Date;
	endDate?: Date;
	isTerminated: boolean;
}

export class ContractApiClient extends ApiClientBase {
	async getContractValidity(id: string) {
		return await this.fetchJson<ContractValidityAM>(`api/contracts/${id}/validity`);
	}
}
