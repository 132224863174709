import { useEffect, useState } from 'react';
import { ServiceRequestAM, StatusType, useServiceRequestApi } from '../api/serviceRequestApiClient';

export function useServiceRequests() {
	const apiClient = useServiceRequestApi();
	const [serviceRequests, setServiceRequests] = useState<ServiceRequestAM[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function fetchData() {
			if (apiClient) {
				const result = await apiClient.getServiceRequests();
				setServiceRequests(result);
				setLoading(false);
			}
		}

		fetchData();
	}, [apiClient]);

	function filterOpenServiceRequests(requests: ServiceRequestAM[]) {
		return requests.filter((r) => r.status === StatusType.New || r.status === StatusType.Received);
	}

	return {
		openServiceRequests: filterOpenServiceRequests(serviceRequests),
		allServiceRequests: serviceRequests,
		loading,
	};
}
