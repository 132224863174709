import { ResourceText } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../ContactFormSection';
import { SuccessMessageBox } from './SuccessMessageBox';

export function CustomerServiceMessageConfirmation({
	headingResourceKey,
	stepTitleResourceKey,
	stepContentResourceKey,
}: {
	headingResourceKey: string;
	stepTitleResourceKey: string;
	stepContentResourceKey: string;
}) {
	return (
		<ContactFormSection headingResourceKey={headingResourceKey}>
			<SuccessMessageBox titleResourceKey={stepTitleResourceKey}>
				<ResourceText resourceKey={stepContentResourceKey} />
			</SuccessMessageBox>
		</ContactFormSection>
	);
}
