import React from 'react';
import { ResourceTextInline } from '../../common/localization/ResourceText';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

export interface FormGroupProps {
	name: string;
	labelResourceKey: string;
	children: React.ReactNode;
	className?: string;
	required?: string;
}

export function FormGroup(props: FormGroupProps) {
	const {
		formState: { errors },
	} = useFormContext();
	return (
		<div className={`l-form-group ${props.className || ''}`}>
			<label className="form-label">
				<ResourceTextInline resourceKey={props.labelResourceKey} />
				{props.required ? ' *' : ''}
			</label>
			{props.children}
			<ErrorMessage
				errors={errors}
				name={props.name}
				render={({ message }) => <div className="l-font-error l-margin-3-t">{message}</div>}
			/>
		</div>
	);
}
