import { ApiClientBase } from './apiClientBase';

export interface SettingsAM {
	ssoAuthority: any;
	styleSheetUrl: string;
	shellBaseUrl: string;
	cdnRootUrl: string;
}

export class SettingsApiClient extends ApiClientBase {
	public async fetchAppSettings(): Promise<SettingsAM> {
		return await this.fetchJson<SettingsAM>('/api/settings');
	}

	public async fetchAntiForgeryToken(): Promise<string> {
		return await this.fetchJson<string>(
			this.isAuthenticated ? '/api/settings/anti-forgery' : '/api/settings/anti-forgery/anonymous',
		);
	}
}
