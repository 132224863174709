import React from 'react';
import addMonths from 'date-fns/addMonths';
import { ResourceTextInline } from '../../common/localization/ResourceText';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { SelectButton } from '../../Components/SelectButton';
import { TextAreaField } from '../../Components/forms/TextAreaField';
import { TextFormField } from '../../Components/forms/TextFormField';
import { FormProvider, useFormContext } from 'react-hook-form';
import { Topic } from './OtherTopicsFormData';
import { FormStep } from '../../Components/forms/FormStep';
import { OtherContactTopic } from '../../api/contactApiClient';
import { Route, Routes, useNavigate } from 'react-router';
import { CustomerServiceMessageConfirmation } from '../../Components/confirmationPage/CustomerServiceMessageConfirmation';
import { FileInputField } from '../../Components/forms/FileInputField';
import { ApartmentChangerBanner } from '../../Components/ApartmentChangerBanner';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import { SpacingContainer } from '@kojamo/lumo-dls';
import { useOtherTopicsForm } from './useOtherTopicsForm';
import { SubmitStatus } from '../../Components/submitStatus';
import { SubmitButtonAndProgress } from '../../Components/forms/SubmitButtonAndProgress';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';
import { useContractContext } from '../../Components/contractValidity/ContractContextProvider';
import { CoTenantPrivacyNoticeCustomerService } from '../../Components/CoTenantPrivacyNotices';
import { useResource } from '@kojamo/react-utils';

import { ContactFormTelemetryType } from '../../common/telemetryGA4';

export function OtherTopics() {
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_Contact_OtherTopics" />
			</ContactBreadCrumbs>
			<OtherTopicsContent />
		</>
	);
}

export function OtherTopicsContent() {
	return (
		<Routes>
			<Route path="/" element={<OtherTopicsForm />} />
			<Route
				path="complete"
				element={
					<CustomerServiceMessageConfirmation
						headingResourceKey="ContactForms_OtherTopics_Confirmation_Heading"
						stepTitleResourceKey="ContactForms_OtherTopics_Confirmation_Step_Heading"
						stepContentResourceKey="ContactForms_OtherTopics_Confirmation_Step_Content"
					/>
				}
			/>
		</Routes>
	);
}

function OtherTopicsForm() {
	const [selectedTopic, setSelectedTopic] = React.useState<OtherContactTopic>();
	const navigate = useNavigate();
	const { formMethods, onSubmit, submitStatus } = useOtherTopicsForm();

	if (submitStatus === 'success') {
		navigate('/other-topics/complete');
	}

	return (
		<RequireValidContract>
			<ContactFormSection
				headingResourceKey={'ContactForms_OtherTopics_Heading'}
				ingressTextKey="ContactForms_OtherTopics_Ingress"
				showContactInformation
			>
				<FormProvider {...formMethods}>
					<form onSubmit={onSubmit}>
						<OtherTopicsStep1
							onContinue={(t) => setSelectedTopic(t)}
							isOpen={!selectedTopic}
							selectedTopic={selectedTopic}
							onOpenRequested={() => setSelectedTopic(undefined)}
						/>
						{/* If topic was already selected (once), keep rendering form so that field inputs are not lost when changing topic */}
						{selectedTopic && (
							<OtherTopicsStep2
								isOpen={!!selectedTopic}
								onOpenRequested={() => {}}
								selectedTopic={selectedTopic}
								submitStatus={submitStatus}
							/>
						)}
					</form>
				</FormProvider>
			</ContactFormSection>
		</RequireValidContract>
	);
}

function OtherTopicsStep1(props: {
	isOpen: boolean;
	onOpenRequested: () => void;
	selectedTopic: Topic | undefined;
	onContinue: (selection: Topic) => void;
}) {
	const { setValue } = useFormContext();
	const { contractState: contractValidity } = useContractContext();

	const onTopicSelected = (t: Topic) => {
		setValue('topic', t);
		props.onContinue(t);
	};

	const shouldShowRentalOption = contractValidity.hasStarted && !contractValidity.hasEnded;

	return (
		<FormStep
			number={1}
			heading={
				<>
					<ResourceTextInline resourceKey="ContactForms_OtherTopics_Step1_Heading" />
					{!props.isOpen && props.selectedTopic && (
						<>
							:{' '}
							<ResourceTextInline resourceKey={'ContactForms_OtherTopics_Step1_' + props.selectedTopic} />
						</>
					)}
				</>
			}
			isOpen={props.isOpen}
			onOpenRequested={props.onOpenRequested}
		>
			<div className="button-choices">
				<SelectButton onClick={() => onTopicSelected('Feedback')}>
					<ResourceTextInline resourceKey="ContactForms_OtherTopics_Step1_Feedback" />
				</SelectButton>
				{shouldShowRentalOption && (
					<SelectButton onClick={() => onTopicSelected('Rental')}>
						<ResourceTextInline resourceKey="ContactForms_OtherTopics_Step1_Rental" />
					</SelectButton>
				)}
				<SelectButton onClick={() => onTopicSelected('Other')}>
					<ResourceTextInline resourceKey="ContactForms_OtherTopics_Step1_Other" />
				</SelectButton>
			</div>
		</FormStep>
	);
}

function OtherTopicsStep2(props: {
	isOpen: boolean;
	onOpenRequested: () => void;
	selectedTopic: Topic;
	submitStatus: SubmitStatus;
}) {
	const { contractState: contractValidity } = useContractContext();
	const { getResourceText } = useResource();
	return (
		<FormStep
			number={2}
			heading={<ResourceTextInline resourceKey={'ContactForms_OtherTopics_Heading_' + props.selectedTopic} />}
			isOpen={props.isOpen}
			onOpenRequested={props.onOpenRequested}
		>
			{props.selectedTopic === 'Rental' && contractValidity.startDate < addMonths(new Date(), -3) && (
				<ApartmentChangerBanner />
			)}
			<SpacingContainer direction="column" rowGap={6}>
				<TextFormField
					name="subject"
					labelResourceKey="ContactForms_Subject_Label"
					placeholderResourceKey="ContactForms_Subject_Placeholder"
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>
				<TextAreaField
					name="message"
					labelResourceKey="ContactForms_Message_Label"
					placeholderResourceKey="ContactForms_Message_Placeholder"
					footnoteResourceKey="ContactForms_OtherTopics_Message_Footnote"
					required={getResourceText('ContactForms_RequiredField_Error')}
				/>
				<FileInputField
					name="files"
					labelResourceKey="ContactForms_Attachment_Label"
					footNoteResourceKey="ContactForms_Attachment_Footnote"
					fileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
				/>
				<CoTenantPrivacyNoticeCustomerService />
			</SpacingContainer>
			<SubmitButtonAndProgress
				submitStatus={props.submitStatus}
				formTypeForTelemetry={ContactFormTelemetryType.OtherTopics}
			/>
		</FormStep>
	);
}
