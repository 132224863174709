import { AuthenticationState } from '../common/authentication/AuthenticationContext';
import { ApiClientBase } from './apiClientBase';

export class SaunaApiClient extends ApiClientBase {
	// params change to mandatory
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(context: AuthenticationState) {
		super(context);
	}

	async getSaunaDetails() {
		// Contract ID is added automatically by base class
		return await this.fetchJson<SaunaDetailsAM>(`api/sauna`);
	}
}

export interface SaunaDetailsAM {
	specialBooking: SpecialBookingAM | undefined;
	hasCommonSauna: boolean;
	saunaReservationProviderType: SaunaReservationProviderType;
}

export interface SpecialBookingAM {
	name: string;
	url: string;
}

export const enum SaunaReservationProviderType {
	OnlineSaunaReservation = 'OnlineSaunaReservation',
	OnetimeSaunaReservation_Electrolux = 'OnetimeSaunaReservation_Electrolux',
	SharedFacilityReservations = 'SharedFacilityReservations',
}
