import { ResourceTextInline } from '../../common/localization/ResourceText';
import { useCallback, useEffect, useState } from 'react';
import { ContactFormSection } from '../../Components/ContactFormSection';
import { FormProvider, useForm } from 'react-hook-form';
import { faultReportFormDataToAPI } from './FaultReportFormData';
import {
	CommonAndOutdoorAreaFaultType,
	FaultAreaType,
	FaultReportFormData,
	getVisibleSections,
	isCommonAndOutdoorAreaFaultType,
	isOwnApartmentFaultAndServiceWorkType,
	isTargetScope,
	OwnApartmentFaultType,
	ServiceRequestTargetScope,
	VisibleSection,
} from '../../common/faultReportForm';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { useServiceRequestApi } from '../../api/serviceRequestApiClient';
import { ContactBreadCrumbs } from '../../Components/BreadCrumbs';
import {
	FaultArea,
	OwnApartmentFault,
	OwnApartmentFaultAndServiceWork,
	CommonAndOutdoorAreaFault,
	AdditionalInformationAndConfirmation,
	FaultReportConfirmation,
} from './FaultReport';
import { useSearchParams } from 'react-router-dom';
import { RequireValidContract } from '../../Components/contractValidity/RequireValidContract';

export function FaultReport() {
	return (
		<>
			<ContactBreadCrumbs>
				<ResourceTextInline resourceKey="Breadcrumbs_FaultReport_Title" />
			</ContactBreadCrumbs>
			<RequireValidContract requiredStartedContract={true}>
				<Wizard />
			</RequireValidContract>
		</>
	);
}

function Wizard() {
	const { formMethods, onSubmit, fault, setFault, reportId, isSubmitting, error, prefillScope } = useFaultReport();
	const prefillTargetScope = prefillScope && isTargetScope(prefillScope) ? prefillScope : undefined;
	const { openSection, setOpenSection, visibleSections } = useFaultReportSections(fault, setFault, prefillScope);

	return !reportId ? (
		<ContactFormSection
			headingResourceKey={'ContactForms_FaultReport_Heading'}
			ingressTextKey="ContactForms_FaultReport_Ingress"
			showContactInformation
		>
			<FormProvider {...formMethods}>
				<form onSubmit={onSubmit}>
					<FaultArea
						stepNumber={1}
						isOpen={openSection === 'FaultArea'}
						onOpenRequested={() => setOpenSection('FaultArea')}
						faultArea={visibleSections.length ? (visibleSections[0] as FaultAreaType) : undefined}
						setOpenSection={setOpenSection}
					/>
					{visibleSections.includes(FaultAreaType.RentalObject) && (
						<OwnApartmentFault
							stepNumber={2}
							isOpen={openSection === FaultAreaType.RentalObject}
							onOpenRequested={() => setOpenSection(FaultAreaType.RentalObject)}
							setOpenSection={setOpenSection}
							initialValue={tryGetOwnApartmentFaultType(prefillTargetScope)}
						/>
					)}
					{visibleSections.includes(OwnApartmentFaultType.FaultAndServiceWork) &&
						fault !== OwnApartmentFaultType.ApartmentNameChange && (
							<OwnApartmentFaultAndServiceWork
								stepNumber={3}
								isOpen={openSection === OwnApartmentFaultType.FaultAndServiceWork}
								onOpenRequested={() => setOpenSection(OwnApartmentFaultType.FaultAndServiceWork)}
								setOpenSection={setOpenSection}
							/>
						)}
					{visibleSections.includes(FaultAreaType.CommonAndOutdoorArea) && (
						<CommonAndOutdoorAreaFault
							stepNumber={2}
							isOpen={openSection === FaultAreaType.CommonAndOutdoorArea}
							onOpenRequested={() => setOpenSection(FaultAreaType.CommonAndOutdoorArea)}
							setOpenSection={setOpenSection}
							initialValue={
								prefillTargetScope && isCommonAndOutdoorAreaFaultType(prefillTargetScope)
									? prefillTargetScope
									: undefined
							}
						/>
					)}
					{fault ? (
						<AdditionalInformationAndConfirmation
							stepNumber={visibleSections.length + 2}
							isOpen={openSection === 'Confirmation'}
							onOpenRequested={() => setOpenSection('Confirmation')}
							isSubmitting={isSubmitting}
							error={error}
							isAuthenticated={true}
						/>
					) : null}
				</form>
			</FormProvider>
		</ContactFormSection>
	) : (
		<FaultReportConfirmation isAuthenticated={true} />
	);
}

function useFaultReport() {
	const [urlParams, setUrlParams] = useSearchParams();
	const targetScope = urlParams.get('targetScope');
	let prefillScope: ServiceRequestTargetScope | FaultAreaType | undefined;
	if (
		targetScope &&
		(isTargetScope(targetScope) ||
			targetScope === FaultAreaType.RentalObject ||
			targetScope === FaultAreaType.CommonAndOutdoorArea)
	) {
		prefillScope = targetScope;
		urlParams.delete('targetScope');
		setUrlParams(urlParams, { replace: false });
	}

	const [reportId, setReportId] = useState<string | undefined>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<Error | undefined>();
	const authState = useAuthenticationContext();
	const apiClient = useServiceRequestApi();
	const formMethods = useForm<FaultReportFormData>({
		defaultValues: { fault: prefillScope && isTargetScope(prefillScope) ? prefillScope : undefined },
	});
	const { watch, setValue } = formMethods;
	const fault = watch('fault');
	const setFault = useCallback((fault?: ServiceRequestTargetScope) => setValue('fault', fault), [setValue]);

	const onSubmit = formMethods.handleSubmit(async (data: FaultReportFormData) => {
		if (!authState.contractId) {
			throw Error('onSubmit: expected contractId to be defined');
		}
		setIsSubmitting(true);
		try {
			const id = await apiClient?.createDraft(faultReportFormDataToAPI(data, authState.contractId));

			if (!id) {
				return;
			}

			if (data.files?.length) {
				for (let i = 0; i < data.files.length; i++) {
					const file = data.files[i];
					if (file) {
						await apiClient?.addAttachment(id, file);
					}
				}
			}
			await apiClient?.submit(id);
			setReportId(id);
		} catch (e) {
			if (e instanceof Error) {
				setError(e);
			} else {
				setError(new Error('Submitting service request failed'));
			}
		} finally {
			setIsSubmitting(false);
		}
	});

	return { formMethods, onSubmit, fault, setFault, reportId, isSubmitting, error, prefillScope };
}

function useFaultReportSections(
	fault: ServiceRequestTargetScope | undefined,
	setFault: (fault?: ServiceRequestTargetScope) => void,
	initialFault?: ServiceRequestTargetScope | FaultAreaType,
) {
	const initialVisibility = getInitialVisibleSectionsByTargetScope(initialFault);
	const [openSection, setOpenSection] = useState<VisibleSection>(initialVisibility.openSection);
	const [visibleSections, setVisibleSections] = useState<VisibleSection[]>(initialVisibility.visibleSections);

	useEffect(() => {
		if (openSection) {
			const newVisibleSections = getVisibleSections(openSection);
			if (newVisibleSections.length && !visibleSections.includes(openSection)) {
				setVisibleSections(newVisibleSections);
				setFault(undefined);
			} else if (
				openSection === OwnApartmentFaultType.FaultAndServiceWork &&
				fault === OwnApartmentFaultType.ApartmentNameChange
			) {
				setFault(undefined);
			}
		}
	}, [openSection, visibleSections, fault, setFault]);

	return { openSection, setOpenSection, visibleSections };
}

function getInitialVisibleSectionsByTargetScope(targetScope: ServiceRequestTargetScope | FaultAreaType | undefined): {
	openSection: VisibleSection;
	visibleSections: VisibleSection[];
} {
	switch (targetScope) {
		case OwnApartmentFaultType.ApartmentNameChange:
			return { openSection: 'Confirmation', visibleSections: [FaultAreaType.RentalObject] };
		case CommonAndOutdoorAreaFaultType.CommonAreaServiceNeed:
			return { openSection: 'Confirmation', visibleSections: [FaultAreaType.CommonAndOutdoorArea] };
		case FaultAreaType.CommonAndOutdoorArea:
			return { openSection: FaultAreaType.CommonAndOutdoorArea, visibleSections: [] };
		default:
			// Default to start view for others
			return { openSection: 'FaultArea', visibleSections: [] };
	}
}

function tryGetOwnApartmentFaultType(fault: ServiceRequestTargetScope | undefined) {
	if (!fault) {
		return undefined;
	}
	if (fault === OwnApartmentFaultType.ApartmentNameChange) {
		return fault;
	}
	if (isOwnApartmentFaultAndServiceWorkType(fault)) {
		return OwnApartmentFaultType.FaultAndServiceWork;
	}
	return undefined;
}
