import { ReactNode, useEffect, useState } from 'react';
import { BusinessEntityPartnersAM, BusinessPartnerAM, PartnerApiClient } from '../api/partnerApiClient';
import { useAuthenticationContext } from '../common/authentication/AuthenticationContext';
import { useResource } from '@kojamo/react-utils';
import { ResourceText } from '../common/localization/ResourceText';
import { LoadingSpinner } from './LoadingSpinner';
import { useSearchParams } from 'react-router-dom';
import { SpacingContainer } from '@kojamo/lumo-dls';

type partnerTypes = 'keyManagement' | 'maintenance';
export interface BusinessPartnerWithTypeAM extends BusinessPartnerAM {
	partnerType: partnerTypes;
}

export function SideBar() {
	const [searchParams] = useSearchParams();
	const businessEntityId = searchParams.get('businessEntityId');
	const authState = useAuthenticationContext(true);
	const [businessEntityPartners, setBusinessEntityPartners] = useState<BusinessEntityPartnersAM>();

	useEffect(() => {
		async function fetchData() {
			const invalidAuthState = !authState.userInfo || !authState.contractId;
			const invalidBusinessEntityId = !businessEntityId || !businessEntityId.length;
			if (invalidAuthState && invalidBusinessEntityId) {
				return;
			}

			const apiClient = new PartnerApiClient(authState);
			const res = await apiClient.getBusinessEntityPartners(
				businessEntityId && businessEntityId.length ? businessEntityId : undefined,
			);
			setBusinessEntityPartners(res);
		}

		fetchData();
	}, [authState, businessEntityId]);

	return (
		<div className="ml-sidebar">
			<SideBarSection headingResourceKey="ContactForms_SideBar_Maintenance_Heading">
				<ResourceText resourceKey="ContactForms_SideBar_Maintenance_Ingress" />
				{businessEntityPartners?.maintenance ? (
					<PartnerContactInfo
						partner={{ ...businessEntityPartners.maintenance, partnerType: 'maintenance' }}
					/>
				) : (
					<LoadingSpinner />
				)}
				<ResourceText resourceKey="ContactForms_SideBar_Maintenance_Footnote" className="l-font-body is-s" />
			</SideBarSection>
			<SideBarSection headingResourceKey="ContactForms_SideBar_KeyManagement_Heading">
				<ResourceText resourceKey="ContactForms_SideBar_KeyManagement_Ingress" />
				{businessEntityPartners?.keyManagement ? (
					<PartnerContactInfo
						partner={{ ...businessEntityPartners.keyManagement, partnerType: 'keyManagement' }}
					/>
				) : (
					<LoadingSpinner />
				)}
			</SideBarSection>
		</div>
	);
}

function SideBarSection(props: { headingResourceKey: string; children: ReactNode }) {
	const { getResourceText } = useResource();
	return (
		<div className="sidebar-section">
			<h2>{getResourceText(props.headingResourceKey)}</h2>
			<div className="content l-font-body">{props.children}</div>
		</div>
	);
}

function PartnerContactInfo({ partner }: { partner: BusinessPartnerWithTypeAM }) {
	return (
		<SpacingContainer className="l-padding-6-y" direction="column" rowGap={1}>
			<span className="l-font-body is-emphasis">{partner.name}</span>
			<span>
				<a href={`tel:${partner.telephone}`}>{partner.telephone}</a>
			</span>
			{partner.email && (
				<span>
					<a href={`mailto:${partner.email}`}>{partner.email}</a>
				</span>
			)}
			{partner.partnerType === 'keyManagement' && (
				<span className="l-font-body is-emphasis l-color-pink">
					{partner.address.street}, {partner.address.zipCode} {partner.address.postOffice}
				</span>
			)}
		</SpacingContainer>
	);
}
