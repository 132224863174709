import React from 'react';
import { useForm } from 'react-hook-form';
import { ParkingFormData } from './ParkingFormData';
import { ContactApiClient, ParkingContactAIM } from '../../api/contactApiClient';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { SubmitStatus } from '../../Components/submitStatus';
import { useAntiForgeryTokenContext } from '../../boot/AntiForgery';

export function useParkingForm() {
	const [submitStatus, setSubmitStatus] = React.useState<SubmitStatus>('initialized');
	const formMethods = useForm<ParkingFormData>();
	const authState = useAuthenticationContext();
	const { token: antiForgeryToken } = useAntiForgeryTokenContext();

	const onSubmit = formMethods.handleSubmit(async (data: ParkingFormData) => {
		setSubmitStatus('submitting');
		const apiClient = new ContactApiClient(authState, antiForgeryToken);

		const req: ParkingContactAIM = {
			...data,
			topic: 'ParkingSpaces',
		};

		apiClient
			.sendParkingContact(req)
			.then(() => {
				setSubmitStatus('success');
			})
			.catch(() => setSubmitStatus('error'));
	});

	return { formMethods, onSubmit, submitStatus };
}
